import React, { useState, useEffect } from 'react';
import { fieldUnitService } from '../_services';
import { DataTable, SideDialog } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState } from '../_hooks';
import { Role } from '../_helpers';

export const FieldUnits = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const [fieldUnits, setFieldUnits] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  
  useEffect(() => {
    fetchItems();

  }, []);  

  const fetchItems = async () => {
    fieldUnitService.getAll()
      .then(res => {
        setFieldUnits(res.field_units);
        setLoading(false) 
      })
      .catch(err => {
        setLoading(false) 
        setMessage(JSON.stringify(err), 'error')
      });
  }



  const addFieldUnit = async data => {
    const fieldUnits = {field_units: [data]}
    
    await fieldUnitService.create(fieldUnits)
      .then(res => {

        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editFieldUnit = async data => {
    const fieldUnits = {field_units: (Array.isArray(data) ? data : [data])}
    
    await fieldUnitService.update(fieldUnits)
      .then(async res => { 

        fetchItems()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteFieldUnit = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await fieldUnitService.destroy({ field_units: data })
      .then(async res => { 

        cancelModalDialog()
        clearSelectedItems()
        fetchItems()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin
  const SideDialogForm = editable ? action.Component : undefined
  

  return (
    <div className="FieldUnits wide-margin">

        <DataTable
          columns={[
            {id: 'field_unit_code', name: 'Code'},
            {id: 'field_unit_name', name: 'Name'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addFieldUnit', items || selectedItems, addFieldUnit) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldUnit', items || selectedItems, editFieldUnit) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldUnit', items || selectedItems, deleteFieldUnit) }
          ]}
          title="Field Units"
          data={fieldUnits}
          idColumn='field_unit_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          filterable={true}
          filterObjects={['field_unit_code', 'field_unit_name']}
        />

        <SideDialog 
          mode={action.mode}
          title={action.title}
          closeDialog={ action.cancelForm }
          >
            { SideDialogForm &&
            <SideDialogForm {...action} />
            }
        </SideDialog>

      </div>
  );
}