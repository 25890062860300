import React, { useState, useEffect } from 'react'
import { fieldUnitService } from '../_services';
import { getBatchEditValue } from '../_helpers'
import { Input } from '../_components';

export const AddFieldUnitForm = ({ mode, data, submitForm, cancelForm }) => { 
	
	const initialFormState = {
		field_unit_id: null, 
		field_unit_name: '',
		field_unit_code: ''
	}

	const initialBatchEditItems = {
	}

	const [hasError, setErrors] = useState(false);
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				field_unit_id: data[0].field_unit_id, 
				field_unit_name: data[0].field_unit_name || '',
				field_unit_code: data[0].field_unit_code || ''
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
			})
		//} else if(mode === 'add') {
			
		} else {
			resetForm()		
		}
		
	}, [mode, data]);
  
	const handleInputChange = (event, formPart) => {
	  let { name, value } = event.target

	  // If input element is a checkbox, we cannot use "value"
    if (event.target.type === "checkbox") { value = event.target.checked }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {
		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {field_unit_id: item.field_unit_id, 
								field_unit_name: item.field_unit_name,
								field_unit_code: item.field_unit_code, 
								...tmp
							}
			})

		} else {
			dataToSubmit = formData
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})        
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
	      			return {...initialFormState  }
	      			} ) 
  }
  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>
			{ !batchEdit &&
			<div className="form-block vertical">
	      <Input type="text" 
	      	name="field_unit_code" 
	      	label="Code"
	      	value={formData.field_unit_code} 
	      	onChange={handleInputChange}
	      	disabled={loading}
	      />
      </div>
			}
			{ !batchEdit &&
			<div className="form-block vertical">
				<Input type="text" 
	      	name="field_unit_name"
	      	label="Name" 
	      	value={formData.field_unit_name} 
	      	onChange={handleInputChange}
	      	disabled={loading}
	      />
		  </div>
			}
	    <div className="form-block horizontal">
				<button 
					type="submit" 
					className={"button main-button" + (loading ? ' loading' : '')} 
          disabled={loading}>
          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
        </button>
				<button 
					type="button" 
					className="button" 
					onClick={cancel}
					disabled={loading}>
					<span>CANCEL</span>
				</button>
			</div>

	    </form>
	  </div>
  )
}
