import React from 'react';
import { SkeletonLoader } from './';
import { Link } from 'react-router-dom';
import { Photo } from './';
import { useTooltip } from '../_hooks'

export const UserLink = ({userId, userPhoto, userName, userTitle, userDepartment, className, type='USER', tooltip="", loading }) => {

	const {showTooltip, hideTooltip} = useTooltip()

	tooltip = tooltip || userName

	const tooltipBody =
    <div className="user-profile slim">
	    <Photo
	      size='medium'
	      data={ userPhoto }
	      loading={ loading }
	    />
      <div className="user-profile-info">
        <div className="user-profile-name">{userName}</div>
        {userTitle &&
          <div className="user-profile-title">{userTitle}</div>
        }
        {userDepartment &&
          <div className="user-profile-title">{userDepartment}</div>
        }
      </div>
    </div>

	return (
		<div className="UserLink">
		{ !loading 
      ? <Link 
      		to={"/users/"+userId} 
      		className={"link" + (className ? " " + className : "")} 
      		onMouseEnter={ () => tooltip && showTooltip({tooltipBody:tooltipBody})} 
		  		onMouseLeave={ () => tooltip && hideTooltip() }
      	>
      		<Photo data={userPhoto} size="small" type={type} loading={loading} alt={userName}  />
      		<div className="user-photo-name">{ userName }</div>
      	</Link>
      : <React.Fragment>
      		<Photo data={userPhoto} size="small" type={type} loading={loading} alt={userName} />
      		<SkeletonLoader width="10vw" />
      	</React.Fragment>
    }
		</div>
	)
}