import React, { useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom'
import { useTooltip, useDebounce } from '../_hooks'

export const Tooltip = () => {

  const { show, tooltip, setShow } = useTooltip()

  let {tooltipBody, preTitle, title, subTitle, bodyText} = tooltip
  const offset = tooltip.offset || 15

  if (title && !(bodyText || subTitle)) {
    bodyText = title
    title = undefined
  }

  const [showTooltip, setShowTooltip] = useState(false)
  const [mousePos, _setMousePos] = useState(undefined)
  const [prevMousePos, _setPrevMousePos] = useState(undefined)
  const tmpRef = useRef()
  
  const tooltipRef = tmpRef
  const portalRef = document.getElementById("portal")

  let e = {}

  const mousePosRef = React.useRef(mousePos);
  const setMousePos = data => {
    mousePosRef.current = data;
    _setMousePos(prev => data);
  };

  const prevMousePosRef = React.useRef(prevMousePos);
  const setPrevMousePos = data => {
    prevMousePosRef.current = data;
    _setPrevMousePos(prev => data);
  };

  useEffect( () => {
    window.addEventListener('mousemove', trackMouse)
    return () => window.removeEventListener('mousemove', trackMouse)
  }, [])
 
  useEffect( () => {
    if (show) {
      const timer = setTimeout( () => {
        setShowTooltip(show)
        setPrevMousePos(mousePos)
      }, 500)
      
      return () => clearTimeout(timer);
    } else {
      setShowTooltip(false)
    }
  }, [show])

  useEffect( () => {
    positionTooltip()
  }, [showTooltip])

  const trackMouse = useDebounce((event) => { // Debounce to reduce number of calls to setState
    e = {
      pageX: event.pageX,
      pageY: event.pageY
    }  
 
    setMousePos(e)

    if (prevMousePosRef.current && (Math.abs(prevMousePosRef.current.pageX - e.pageX) >= 10 || Math.abs(prevMousePosRef.current.pageY - e.pageY) >= 10 )) {
      setShowTooltip(false)
    }

  }, 200).debounce

  const positionTooltip = () => {
    if (tooltipRef && tooltipRef.current && mousePosRef.current) {
      if (tooltipRef.current.offsetWidth + mousePosRef.current.pageX + offset + 10 < window.innerWidth) {      
        tooltipRef.current.style.removeProperty('right')
        tooltipRef.current.style.left = (mousePosRef.current.pageX + offset) + "px"
      } else {    
        tooltipRef.current.style.removeProperty('left') 
        tooltipRef.current.style.right = "10px"
      }

      if (tooltipRef.current.offsetHeight + mousePosRef.current.pageY + offset + 10 < window.innerHeight) {
        tooltipRef.current.style.top = (mousePosRef.current.pageY + offset) + "px"
      } else {
        tooltipRef.current.style.top = (mousePosRef.current.pageY - tooltipRef.current.offsetHeight - offset) + "px"
      }
    } else { // fix to prevent tooltip to render in strange places on some random occasions when some of the refs are not set
      setShowTooltip(false)
    }
  }

  return showTooltip && createPortal(
    <div
      className={"CustomTooltip" + (showTooltip ? " show" : "") + (!title && !tooltipBody ? " no-title" : "")}
      ref={tooltipRef}
    >
    { tooltipBody && tooltipBody }
    { !tooltipBody && <React.Fragment>
          { preTitle  && <div className="tooltip-pretitle">{preTitle}</div> }
          { title     && <div className="tooltip-title">{title}</div> }
          { subTitle  && <div className="tooltip-subtitle">{subTitle}</div> }
          { bodyText  && <div className="tooltip-body">{bodyText}</div> }
        </React.Fragment>
    }  
    </div> 
    , portalRef
  )
};
