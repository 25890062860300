import React from 'react';
import AriaModal from 'react-aria-modal';
import { SkeletonLoader } from './'

export const ModalDialog = ({title, body, submitLabel, cancelLabel, onSubmit, onCancel, customButtons, loading}) => {

 const getApplicationNode = () => {
    return document.getElementById('root');
  };

  return (
    <AriaModal
      titleText={title}
      //onExit={this.deactivateModal}
      verticallyCenter={false}
      //initialFocus="#demo-one-deactivate"
      focusDialog={true}
      getApplicationNode={getApplicationNode}
      underlayClass={'modal-underlay'}

    >
    <div className="ModalDialog">
      <div className="modal-container">

        <div className="modal-container-header">
          {title}
        </div>

        <div className="modal-container-body">
          {body || <React.Fragment><SkeletonLoader /><ul><SkeletonLoader width={150}/></ul></React.Fragment>}
        </div>

        <div className="modal-container-footer">
        { customButtons &&

          customButtons.map( (button, index) => {
            return (
              <button 
                tabIndex={0} 
                key={index}
                onClick={ button.onClick }
                className="footer-item button"
                disabled={loading}
                title={button.tooltip}
              >
                <span>{button.label}</span>
              </button>
            )
          })
        }
          { submitLabel &&
          <button 
            tabIndex={0} 
            onClick={ onSubmit } 
            className={"footer-item button main-button" + (loading ? ' loading' : '')}
            disabled={loading}
          >
            <span>{submitLabel}</span>
          </button>
          }
          { cancelLabel &&
          <button 
            tabIndex={0} 
            onClick={ onCancel } 
            className="footer-item button"
            disabled={loading}
          >
            <span>{cancelLabel}</span>
          </button>
          }
        </div>

      </div>
    </div>
    </AriaModal>
  );
};
