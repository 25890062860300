import { useState, useEffect } from 'react'
import { subjects } from '../_helpers'

export const useGlobalState = (subjectName) => {

  const subject = subjects[subjectName]
  const [value, setValue] = useState(subject.getValue())

  useEffect(() => {
   const sub = subject.subscribe(s => setValue(s))
   return () => sub.unsubscribe()
  });

  const newSetValue = (newValue) => {
    subject.next(newValue)
  }
  return [value, newSetValue]
}