import config from '../config';
import { authHeader, handleResponse, commonRequestOptions, customFetch } from '../_helpers';

const endpointPath='relationship'

export const relationshipService = {
    getByObjectId,
    getRelatedDatasets
};

function getByObjectId(objectName, objectId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_by_object_id/${objectName}/${objectId}`, requestOptions, signal).then(handleResponse);
}
function getRelatedDatasets(datasetId, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/get_related_datasets/${datasetId}`, requestOptions, signal).then(handleResponse);
}