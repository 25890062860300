import React from 'react';
import { SkeletonLoader } from './';

export const Photo = ({data, alt, className, type='USER', size='small', tooltip="", loading }) => {

	const avatar = type === 'USER' ? "user-photo" : "group-photo"

	return (
		<div className={
			"Photo " + 
			(!data ? " placeholder " : " ") + 
			type.toLowerCase() + " " + 
			size + 
			(className ? " " + className : "" ) 
		}>
		{!loading 
			? data 
				? 	<img 
						className="photo-img"
					 	title={tooltip}
					 	src={ "data:image/jpeg;base64," + data }
					 	alt={alt}
					/>		
				: 	<div className={"photo-img icon-" + avatar }  title={ tooltip } />
			: <SkeletonLoader className={"photo-loader " + size} />
		}
		</div>
	)
}