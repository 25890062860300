import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom'
import { Asset } from '../_helpers';
import { Icon, SearchResult, Tabs } from '../_components';
import { useQueryParams, useSearch } from '../_hooks';


export const Home = ({ setMessage }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [showSearchTip, setShowSearchTip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeObject, setActiveObject] = useState(queryParams.object);

  const refTabContent = useRef()

  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage 
  } = useSearch({
      setMessage, 
      setLoading, 
      objectName: activeObject
    })

  const refSearchInput = useRef();

  useEffect(() => {

    if (searchTerm !== "") {
      fetchSearchResults(undefined, false, 1)   
    } 

  }, [activeObject]);
  
  const handleInputChange = async event => {
    let { value } = event.target

    setSearchTerm(value)

    if (value.length > 1) {
      await fetchSearchResults(value, false, 1)     

      setShowSearchTip(false)
    } else {
      setShowSearchTip(true)
    }
    
  }

  const clearSearch = () => {  
    refSearchInput.current.focus()
    setShowSearchTip(true) 
    clearSearchResults() 
  }

  const onSubmit = event => {

    event.preventDefault()

    // Form validation
    // ... 
        
  }

  const onObjectChange = object => {
    setActiveObject(object)
    setHistory({object: object})
  }

  const handleFocus = () => {
    if (!(searchTerm && searchTerm.length > 1)) {
      setShowSearchTip(true)
    }
  }

  const handleBlur = () => {
    setShowSearchTip(false)
  }

  const loadMore = () => {
    loadNextPage()
  }


let field_descriptions = []
let fields = []
let datasets = []
let dataset_groups = []
let datasources = []
let systems = []
let business_terms = []
let users = []


if (searchResults) {
  fields = searchResults.filter(x => x.object_name === "field")
  datasets = searchResults.filter(x => x.object_name === "dataset")
  dataset_groups = searchResults.filter(x => x.object_name === "dataset_group")
  datasources = searchResults.filter(x => x.object_name === "datasource")
  systems = searchResults.filter(x => x.object_name === "system")
  business_terms = searchResults.filter(x => x.object_name === "business_term")
  field_descriptions = searchResults.filter(x => x.object_name === "field_description")
  users = searchResults.filter(x => x.object_name === "user")
}

  return (
    <div className={"Home" + ((searchResults && searchTerm.length > 1) ? "" : " dark-background")}>
      <div className="search-container">
        <div className={"search-header"}>
        
          <form onSubmit={onSubmit}>
            <div className="search-input">
              <input
                type="text"
                name="search"
                ref={refSearchInput}
                placeholder="Search Data Assets..."
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <div className={"button-clear-search" + ((searchTerm !== "") ? " show" : "")} onClick={clearSearch} title="Clear search input">
                <div className="button-clear-search-icon"></div>
              </div>
            </div>
          </form>
        
        </div>

        { searchTerm.length < 2 &&
          <div className={"search-tip" + (showSearchTip ? " show" : "")}>
            <div className="search-tip-icon">  
              <Icon name="tip" />
            </div>
            <div className="search-tip-text">  
              <p>{'Begin typing to search.'}</p>
              <p>{'Use a colon to limit your search to a certain context.'}<br/>
                 {'Example: "customer : crm" searches for any data assets matching "customer" in systems, datasets etc that match "crm".'}</p>
            </div>
          </div>
        }

        { searchResults && searchTerm.length > 1 &&
  
            <Tabs 
              onTabChange={onObjectChange} 
              showResultCount={true} 
              showNoResultsMessage={false}
              className='vw-60'
              disableTabsWithoutResults={true}
              defaultSelectedTab={{tab: activeObject}}
              ref={refTabContent}
              loading={loading}
            >
              <div label="All" tabId='all' resultCount={ (searchResultsCount && searchResultsCount.all) || 0}>
                <SearchResult 
                  data={searchResults} 
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.all} 
                  loading={loading}
                  searchTerm={searchTerm} 
                />
              </div>
              <div label="Business Terms" tabId={Asset.BusinessTerm} resultCount={ (searchResultsCount && searchResultsCount.business_term) || 0}>
                <SearchResult 
                  data={business_terms}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.business_term}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Field Descriptions" tabId={Asset.FieldDescription} resultCount={ (searchResultsCount && searchResultsCount.field_description) || 0}>
                <SearchResult 
                  data={field_descriptions}
                  loadMore={loadMore}
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.field_description}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Datasets" tabId={Asset.Dataset} resultCount={ (searchResultsCount && searchResultsCount.dataset) || 0}>
                <SearchResult 
                  data={datasets}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ (searchResultsCount && searchResultsCount.dataset)  || 0}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>

              <div label="Dataset Groups" tabId={Asset.DatasetGroup} resultCount={ (searchResultsCount && searchResultsCount.dataset_group) || 0}>
                <SearchResult 
                  data={dataset_groups}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ (searchResultsCount && searchResultsCount.dataset_group)  || 0} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Datasources" tabId={Asset.Datasource} resultCount={ (searchResultsCount && searchResultsCount.datasource) || 0}>
                <SearchResult 
                  data={datasources}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.datasource} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Systems" tabId={Asset.System} resultCount={ (searchResultsCount && searchResultsCount.system) || 0}>
                <SearchResult 
                  data={systems}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.system}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Fields" tabId={Asset.Field} resultCount={ (searchResultsCount && searchResultsCount.field) || 0}>
                <SearchResult 
                  data={fields}
                  loadMore={loadMore} 
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.field}
                  loading={loading} 
                  searchTerm={searchTerm}
                />
              </div>
              <div label="Users" tabId={Asset.User} resultCount={ (searchResultsCount && searchResultsCount.user) || 0}>
                <SearchResult 
                  data={users}
                  loadMore={loadMore}
                  containerElement={refTabContent.current}
                  resultCount={ searchResultsCount && searchResultsCount.user} 
                  loading={loading}
                  searchTerm={searchTerm}
                />
              </div>

            </Tabs>
        }

        { searchTerm.length < 2 &&
          <React.Fragment>

            <div className="large-nav-links">

              <div className="large-nav-links-title">BROWSE DATA ASSETS</div>

              <div className="large-nav-link-container">

                <Link to="/browse_datasets" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="datasets"/>
                  </div>
                  <div className="large-nav-link-title">
                    Datasets
                  </div>
                  <div className="large-nav-link-description">
                    Containers with actual data, like tables, views and spreadsheets
                  </div>
                </Link>

                <Link to="/browse_field_descriptions" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="field_descriptions"/>
                  </div>
                  <div className="large-nav-link-title">
                    Field Descriptions
                  </div>
                  <div className="large-nav-link-description">
                    Descriptions of Fields in Datasets and the link to Business Terms
                  </div>
                </Link>

                <Link to="/browse_business_terms" className="large-nav-link">
                  <div className="large-nav-link-icon">
                    <Icon name="business_terms"/>
                  </div>
                  <div className="large-nav-link-title">
                    Business Terms
                  </div>
                  <div className="large-nav-link-description">
                    Definitions of common terminology, like customer, product and revenue
                  </div>
                </Link>

              </div>
            </div>

          </React.Fragment>
        }

        </div>
      </div>
  );
}