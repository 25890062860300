import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { authMethod, formatData, Role, Asset } from '../_helpers';
import { 
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  SideDialog,  
  SkeletonLoader,
  Tabs } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState } from '../_hooks';
import { 
  businessTermService,
  datasetService,
  datasetGroupService,
  datasourceService,
  fieldDescriptionService,
  glossaryService,
  systemService,
  userService,
  userGroupService 
} from '../_services';


export const User = ({ setMessage, setModalDialog, cancelModalDialog }) => {
  
  const { queryParams, setHistory } = useQueryParams()

  const [user, setUser] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [ownedItems, setOwnedItems] = useState([]);
  const [ownedItemsCount, setOwnedItemsCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingUserGroups, setLoadingUserGroups] = useState(true);
  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object})
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
 
  let { user_id } = useParams()
  if (user_id === undefined) {
    user_id = loggedInUser.user_id
  }

  const navigate = useNavigate()

  useEffect(() => {
    
    fetchItems()
    fetchOwnedItemsCount()
    fetchUserGroups()

  }, [user_id]);

  const fetchItems = async () => {
    
    userService.getById(user_id)
      .then(res => {

        setUser(res.users[0]);
        setLoading(false)
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchOwnedItemsCount = async () => {
    
    userService.getOwnedItemsCount(user_id)
      .then(res => {

        let tmp = {}
        let defaultObject

        const objectSortOrder = ["field_description", "business_term", "glossary", "dataset", "dataset_group", "datasource", "system"];
        res.owned_items_count.sort((a, b) => objectSortOrder.indexOf(a.object_name) - objectSortOrder.indexOf(b.object_name));

        res.owned_items_count.forEach(result => {
          if (result.owned_items_count > 0 && defaultObject === undefined ) {
            defaultObject = result.object_name

          }
          tmp = {...tmp, [result.object_name]: parseInt(result.owned_items_count)}
        })

        defaultObject = queryParams.object || defaultObject

        setDefaultSelectedObject({tab: defaultObject})
        fetchOwnedItems(defaultObject)
        setOwnedItemsCount(tmp)

      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchOwnedItems = async (objectName) => {
    if (objectName) { // objectName is undefined if user does not own any items
      setLoadingTable(true)
      userService.getOwnedItems(user_id, objectName)
        .then(res => {

          setOwnedItems(res.owned_items)
          setLoadingTable(false)
           
        })
        .catch(err => {setMessage(JSON.stringify(err), 'error')});
    } else {
      setLoadingTable(false) // Needed to show "no result" message
    }
  }

  const fetchUserGroups = async () => {
    
    userGroupService.getByUserId(user_id)
      .then(res => {

        setUserGroups(res.user_groups);
        setLoadingUserGroups(false)
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }


  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}
    
    await userService.update(users)
      .then(async res => { 

        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDataset = async data => {
    const items = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Dataset)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDatasetGroup = async data => {
    const items = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.DatasetGroup)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const items = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Datasource)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const items = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.System)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editBusinessTerm = async data => {
    const items = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.BusinessTerm)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const items = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.Glossary)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editFieldDescription = async data => {
    const items = {field_descriptions: (Array.isArray(data) ? data : [data])}
    
    await fieldDescriptionService.update(items)
      .then(async res => { 

        fetchOwnedItems(Asset.FieldDescription)
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteBusinessTerm = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await businessTermService.destroy({business_terms: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.BusinessTerm)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteDataset = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetService.destroy({datasets: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Dataset)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteDatasetGroup = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetGroupService.destroy({dataset_groups: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.DatasetGroup)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteDatasource = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasourceService.destroy({datasources: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Datasource)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteFieldDescription = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await fieldDescriptionService.destroy({field_descriptions: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.FieldDescription)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteGlossary = async data => {  
    setModalDialog(prev => {return {...prev, loading: true}})  
    await glossaryService.destroy({glossaries: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.Glossary)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteSystem = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await systemService.destroy({systems: data})
      .then(async res => { 
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()
        fetchOwnedItems(Asset.System)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteUser = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await userService.destroy({users: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate('/users')

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const onObjectChange = (object) => {
    setHistory({'object': object})
    fetchOwnedItems(object)
    clearSelectedItems()
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
    fetchUserGroups()
  }

  let ownedItemsTotalCount = 0

  if (ownedItemsCount) {
    
    ownedItemsTotalCount = ownedItemsCount.field_description || 0 +
                           ownedItemsCount.dataset || 0 + 
                           ownedItemsCount.dataset_group || 0 +
                           ownedItemsCount.datasource || 0 +
                           ownedItemsCount.system || 0 +
                           ownedItemsCount.business_term || 0 +
                           ownedItemsCount.glossary || 0
  }

  const data = loading ? [] : user

  const editableAdmin = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)
                            
  const SideDialogForm = editable ? action.Component : undefined

  const userGroupTypeValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="USER"
          title={data.user_fullname}
          secondaryTitle={`${(data.user_title ? data.user_title : '')}${(data.user_department ? '\n' + data.user_department : '')}`}
          subtitleText={ data && !loading &&
            data.user_username
          }
          photo={data.user_photo}
          loading={loading}
          >

            <h3>{ !loading ? "Email" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_email === null) ? "-" : <a className="link" href={"mailto:"+data.user_email}>{data.user_email}</a>
              : <SkeletonLoader width="10vw" />
            }
            </div>
            
            <h3>{ !loading ? "Role" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_role_name === null) ? "-" : data.user_role_name
              : <SkeletonLoader width="10vw" />
            }
            </div>

            <h3>{ !loading ? "User Type" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (data.user_auth_method === null) ? "-" : authMethod[data.user_auth_method]
              : <SkeletonLoader width="10vw" />
            }
            </div>

            { editable &&
              <React.Fragment>

                { data.user_auth_method !== 'local' &&
                  <React.Fragment>
                    <h3>{ !loading ? "User Source Id" : <SkeletonLoader width="5vw"/>}</h3>
                    <div className="display-linebreak">
                    { !loading 
                      ? (data.user_source_id === null) ? "-" : data.user_source_id
                      : <SkeletonLoader width="10vw" />
                    }
                    </div>   
                  </React.Fragment>    
                }

                <h3>{ !loading ? "Status" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_is_disabled) 
                    ? <span className="pill pill-red">Disabled</span> 
                    : <span className="pill">Active</span>
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "Last Login" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? (data.user_last_login_timestamp === null) ? <span className="no-result-text">Never logged in</span> : formatData(data.user_last_login_timestamp, 'datetime')
                  : <SkeletonLoader width="10vw" />
                }
                </div>

                <h3>{ !loading ? "Added" : <SkeletonLoader width="5vw"/>}</h3>
                <div className="display-linebreak">
                { !loading 
                  ? formatData(data.created_timestamp, 'datetime')
                  : <SkeletonLoader width="10vw" />
                }
                </div>    

              </React.Fragment>
            }
          </ObjectDetails>
            
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item main-button button" onClick={ () => showSideDialog('editUser', [data], editUser) }><span>EDIT</span></button>
            {loggedInUser && data.user_id !== loggedInUser.user_id &&
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteUser', [data], deleteUser) }><span>DELETE</span></button>
            }
          </div>
          }

      </MainColumn>
      
      <div className="column">
      
        <Tabs className="slim left" 
              disableTabsWithoutResults={true}
              onTabChange={ onTabChange } 
              defaultSelectedTab={defaultSelectedTab}>
          <div label="Owned Items" tabId='owned_items' resultCount={loadingTable ? undefined : ownedItemsTotalCount}>

          { ownedItemsTotalCount > 0 &&
            <Tabs className="buttons left" 
                  onTabChange={ onObjectChange } 
                  showResultCount={false} 
                  disableTabsWithoutResults={true}
                  defaultSelectedTab={defaultSelectedObject}
                  >

              <div label="Field Descriptions" tabId={Asset.FieldDescription} resultCount={ (ownedItemsCount && ownedItemsCount.field_description) || 0 }>
                <DataTable
                  columns={[
                    {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name'},
                    {id: 'field_description_name', name: 'Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description', className:"bold"},
                    {id: 'field_unit_code', name: 'Field Unit', tooltip: 'field_unit_name'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editFieldDescription', items || selectedItems, editFieldDescription) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteFieldDescription', items || selectedItems, deleteFieldDescription) }
                  ]}
                  data={ownedItems}
                  idColumn='field_description_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['field_description_name']}
                />
              </div>
              
              <div label="Business Terms" tabId={Asset.BusinessTerm} resultCount={ (ownedItemsCount && ownedItemsCount.business_term) || 0 }>
                <DataTable
                  columns={[
                    {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                    {id: 'glossary_name', name: 'Glossary', link: '/browse_business_terms/:glossary_id'},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime'}      
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editBusinessTerm', items || selectedItems, editBusinessTerm) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteBusinessTerm', items || selectedItems, deleteBusinessTerm) }
                  ]}
                  data={ownedItems}
                  idColumn='business_term_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['business_term_name']}
                />
              </div>

              <div label="Glossaries" tabId={Asset.Glossary} resultCount={ (ownedItemsCount && ownedItemsCount.glossary) || 0 }>
                <DataTable
                  columns={[
                    {id: 'glossary_name', name: 'Name', link: '/browse_business_terms/:glossary_id', tooltip: 'glossary_description', className:"bold"},
                    {id: 'modified_timestamp', name: 'Modified', type:'datetime'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editGlossary', items || selectedItems, editGlossary) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteGlossary', items || selectedItems, deleteGlossary) }
                  ]}
                  data={ownedItems}
                  idColumn='glossary_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['glossary_name']}
                />
              </div>

              <div label="Datasets" tabId={Asset.Dataset} resultCount={ (ownedItemsCount && ownedItemsCount.dataset) || 0 }>

                <DataTable
                  columns={[
                    {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                    {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                    {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id' },
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'},
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDataset', items || selectedItems, editDataset) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDataset', items || selectedItems, deleteDataset) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_name', 'dataset_source_name']}
                />
              </div>

              <div label="Dataset Groups" tabId={Asset.DatasetGroup} resultCount={ (ownedItemsCount && ownedItemsCount.dataset_group) || 0 }>
                <DataTable
                  columns={[
                    {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                    {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasetGroup', items || selectedItems, editDatasetGroup) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasetGroup', items || selectedItems, deleteDatasetGroup) }
                  ]}
                  data={ownedItems}
                  idColumn='dataset_group_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editable}
                  filterable={true}
                  filterObjects={['dataset_group_name', 'dataset_group_source_name']}
                />
              </div>

              <div label="Datasources" tabId={Asset.Datasource} resultCount={ (ownedItemsCount && ownedItemsCount.datasource) || 0 }>
                <DataTable
                  columns={[
                    {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                    {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                    {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasource', items || selectedItems, editDatasource) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasource', items || selectedItems, deleteDatasource) }
                  ]}
                  data={ownedItems}
                  idColumn='datasource_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['datasource_name', 'datasource_hostname', 'datasource_database']}
                />
              </div>

              <div label="Systems" tabId={Asset.System} resultCount={ (ownedItemsCount && ownedItemsCount.system) || 0 }>
                <DataTable
                  columns={[
                    {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"}   
                  ]}
                  buttons={[ 
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editSystem', items || selectedItems, editSystem) },
                    {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteSystem', items || selectedItems, deleteSystem) }
                  ]}
                  data={ownedItems}
                  idColumn='system_id'
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                  loading={loadingTable}
                  editable={editableAdmin}
                  filterable={true}
                  filterObjects={['system_name']}
                />
              </div>

            </Tabs>            
        }

        </div>
        <div label="User Groups" tabId='user_groups' resultCount={loadingUserGroups ? undefined : userGroups.length}>

         <DataTable
          columns={[
            {id: 'user_group_photo', name: '', type: 'group-photo'},
            {id: 'user_group_name', name: 'Name', link: '/user_groups/:user_group_id', className:"bold"},
            {id: 'user_group_default_role_name', name: 'Default Role'},  
            {id: 'user_group_type', name: 'Type', value:'user_group_type', values: userGroupTypeValues},
            ]}
          data={userGroups}
          title="User Groups"
          editable={false}
          idColumn='user_group_id'
          loading={loadingUserGroups}
          filterable={true}
          filterObjects={['user_group_name', 'user_group_default_role_name']}
        />

        </div>
      </Tabs>
        
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} />
          }
      </SideDialog>

    </div>
  ) 
}
