import React, { useState, useEffect } from 'react'
import { Input } from '../_components';

export const EditProfileForm = ({ mode, data, submitForm, cancelForm }) => { 
	
	const initialFormState = {
		user_id: null,
		user_fullname: '',
		user_email: ''
	}

	const [hasError, setErrors] = useState(false);
	const [formData, setFormData] = useState(initialFormState);
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		if (data.length === 1) {
			setFormData({ 
				user_id: data[0].user_id,
				user_fullname: data[0].user_fullname || '',
				user_email: data[0].user_email || ''
			})
		} else {
			resetForm()		
		}

	}, [data]);
  
	const handleInputChange = event => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  setFormData({ ...formData, [name]: value })
	}

	const onSubmit = event => {
		event.preventDefault()

		setLoading(true)

		// submit form
		submitForm(formData)
		.then(res => { 
			setLoading(false)
			cancel() 
		})
		.catch(err => {
			setLoading(false)
		})	    
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {
  	setFormData(prevFormData => {return {...initialFormState} }) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>

	      <div className="form-block vertical">
		      <Input type="text" 
		      	name="user_fullname" 
		      	label="Full Name"
		      	value={formData.user_fullname} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		     	/>
	     	</div>

	     	<div className="form-block vertical">
		      <Input type="email" 
		      	name="user_email" 
		      	label="Email"
		      	value={formData.user_email} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
	      </div>

	      <div className="form-block horizontal">
					<button 
						type="submit" 
						className={"button main-button" + (loading ? ' loading' : '')} 
            disabled={loading}>
            <span>{loading ? 'Saving...' : 'Save'}</span>
          </button>
					<button 
						type="button" 
						className="button" 
						onClick={cancel}
						disabled={loading}>
						<span>CANCEL</span>
					</button>
				</div>
	    </form>
	  </div>
  )
}
