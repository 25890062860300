import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Input, SearchResult } from './'
import { useSearch } from '../_hooks';

export const SearchBar = ({setMessage}) => {

  const [showSearchResultDropdown, setShowSearchResultDropdown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [itemIsClicked, setItemIsClicked] = useState(false)

  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage } = useSearch({setMessage, setLoading})

  const refSearchInput = useRef();
  const refViewAll = useRef();
  const refSearchResultDropdown = useRef();
  const refSearchResults = useRef([]);


  const handleInputChange = event => {
    let { value } = event.target

    setItemIsClicked(false)
    setSearchTerm(value)

    if (value.length > 1) {
      fetchSearchResults(value, false, 1)
      setShowSearchResultDropdown(true)
    } else {
      setShowSearchResultDropdown(false)
    }
  }

  const clearSearch = () => {  
    refSearchInput.current.focus()
    setLoading(false)
    clearSearchResults()
  }

  const handleFocus = (event) => {
    setItemIsClicked(false)
    if (searchTerm && searchTerm.length > 1) {
      setShowSearchResultDropdown(true)
    }
  }

  const handleBlur = (el) => {
    const activeElement = el.relatedTarget

    if (activeElement === null || 
        !(activeElement === refSearchInput || 
          refSearchResults.current.findIndex(el => el === activeElement) >= 0 ||
          activeElement === refViewAll.current
      )) {
      setShowSearchResultDropdown(false)
    }
  }

  const handleSelect = () => { 
    setItemIsClicked(prev => true)
  }

  const loadMore = () => {
    loadNextPage()
  }

  const hideDropdown = (el) => {
    if (itemIsClicked) {
      setShowSearchResultDropdown(false)
    }
  }

  return (
	  <div className="SearchBar slim" onMouseLeave={hideDropdown}>
	    <Input
	      type="text"
	      name="search"
        autoComplete="new-password"
	      ref={refSearchInput}
	      placeholder="Search Data Assets..."
	      value={searchTerm}
	      onChange={handleInputChange}
	      onFocus={handleFocus}
	      onBlur={handleBlur}
	    />
	    <div className={"button-clear-search" + ((searchTerm !== "") ? " show" : "")} 
	         onClick={clearSearch} 
	         title="Clear search input"
      >
	      <div className="button-clear-search-icon"></div>
	    </div>

      <div className={"search-result-dropdown" + ((showSearchResultDropdown && searchResults && searchTerm.length > 1) ? " show" : "") }>
        { searchResultsCount && searchResultsCount.all > 0 &&
          <div className="search-result-dropdown-header">
            {`${searchResultsCount.all} assets found`}
            <Link className="link" to={"/?search=" + searchTerm} ref={refViewAll}>View detailed results</Link>
          </div>
        }
        <div className="search-result-dropdown-content" ref={refSearchResultDropdown}>
          <SearchResult 
            data={searchResults} 
            ref={refSearchResults}
            loadMore={loadMore} 
            containerElement={refSearchResultDropdown.current}
            resultCount={ searchResultsCount && searchResultsCount.all} 
            loading={loading}
            searchTerm={searchTerm} 
            onClick={handleSelect}
          />
        </div>

      </div>
	  </div>
  )
}
