import React, { useState, useEffect } from 'react';
import { settingService } from '../_services';
import { useModalDialog, useQueryParams } from '../_hooks';
import { Tabs, Input, SelectButtons, formatSelectOptions, Checkbox } from '../_components'


export const Settings = ({ setMessage, setModalDialog, cancelModalDialog, abortFetch }) => {
  
  const { queryParams, setHistory } = useQueryParams()

  const [settings, setSettings] = useState({});
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { showModalDialog } = useModalDialog(setModalDialog, setMessage)

  useEffect(() => {

    fetchItems()
    
  }, []);

  const fetchItems = async () => {
    await settingService.getAll(abortFetch)
      .then(res => {

        let result = {}
        res.settings.forEach(setting => {
          result[setting.setting_code] = {...setting}
        })
        setSettings(result)
        setFormData(result)
        setLoading(false)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        setLoading(false)
      });
  }

  const editSettings = async data => {
    await settingService.update(data, abortFetch)
      .then(async res => { 

        fetchItems()
        setMessage(res)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const saveSettings = (event) => {
    cancelModalDialog()
    onSubmit(event)
  }

  const handleInputChange = (event) => {

    let { name, value } = ""

    // Handle standard form inputs
    if (event.target !== undefined) {
      name = event.target.name
      value = event.target.value

      // If input element is a checkbox, we cannot use "value"
      if (event.target.type === "checkbox") { value = event.target.checked }

    // Handle custom form inputs
    } else {
      name = event.name
      value = event.value
    }

    setFormData({ ...formData, [name]: {...formData[name], setting_value: value} })

  }

  const onSubmit = event => {
    event.preventDefault()
    
    // Form validation
    //if (!formData.user_fullname || !formData.user_username) return

    setLoading(true)

    const data = Object.entries(formData).map( ([key, value]) => {
      return { setting_id: value.setting_id, setting_value: value.setting_value }
    })

    // submit form
    editSettings({settings: data})
    .then(res => { 
      setLoading(false)
    })
    .catch(err => {
      setLoading(false)
    })       
        
  }

  const cancel = () => {
    setFormData(settings)
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }

  return (
    <div className="wide-margin">
      <div className="form">
        <form>
          
          <Tabs className="slim left"
            onTabChange={ onTabChange } 
            defaultSelectedTab={ defaultSelectedTab }
            disableTabsWithoutResults={ true }
          >
         
            <div label="Authenticaton" tabId="authentication">
            
              <div className="form-content">

                <div className="form-block vertical">
                  <Checkbox 
                    checked={formData?.LOCAL_IS_ENABLED?.setting_value  || false}
                    name={formData?.LOCAL_IS_ENABLED?.setting_code}
                    label={formData?.LOCAL_IS_ENABLED?.setting_name}
                    onChange={ handleInputChange}
                    disabled={loading}
                    title={formData?.LOCAL_IS_ENABLED?.setting_description}
                  />
                </div>

                <div className="form-block vertical">
                  <Checkbox 
                    checked={formData?.OIDC_IS_ENABLED?.setting_value || false}
                    name={formData?.OIDC_IS_ENABLED?.setting_code}
                    label={formData?.OIDC_IS_ENABLED?.setting_name}
                    onChange={ handleInputChange}
                    disabled={loading}
                    title={formData?.OIDC_IS_ENABLED?.setting_description}
                  />
                </div>
                
                {formData?.OIDC_IS_ENABLED?.setting_value && 
                <div>
                <h4>Azure OpenID Connect Parameters</h4>
                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_DIRECTORY_ID?.setting_code}
                      label={formData?.OIDC_DIRECTORY_ID?.setting_name}
                      value={formData?.OIDC_DIRECTORY_ID?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_DIRECTORY_ID?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_APPLICATION_ID?.setting_code}
                      label={formData?.OIDC_APPLICATION_ID?.setting_name}
                      value={formData?.OIDC_APPLICATION_ID?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_APPLICATION_ID?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="password" 
                      name={formData?.OIDC_CLIENT_SECRET?.setting_code}
                      label={formData?.OIDC_CLIENT_SECRET?.setting_name}
                      value={formData?.OIDC_CLIENT_SECRET?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      placeholder="< hidden >"
                      title={formData?.OIDC_CLIENT_SECRET?.setting_description}
                    />
                  </div>

                  <div className="form-block vertical">
                    <Input type="text" 
                      name={formData?.OIDC_REDIRECT_URI?.setting_code}
                      label={formData?.OIDC_REDIRECT_URI?.setting_name}
                      value={formData?.OIDC_REDIRECT_URI?.setting_value || ''} 
                      onChange={handleInputChange}
                      disabled={loading}
                      title={formData?.OIDC_REDIRECT_URI?.setting_description}
                    />
                  </div>
                </div>
                }
              </div>
            </div>

            <div label="User Provisioning" tabId="userProvisioning">
              <div className="form-content">

                  <h4>Azure AD (through Microsoft Graph API)</h4>

                { formData?.OIDC_IS_ENABLED?.setting_value ? 
                  <React.Fragment>
                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MSGRAPH_USER_BASE_FILTER?.setting_code}
                        label={formData?.MSGRAPH_USER_BASE_FILTER?.setting_name}
                        value={formData?.MSGRAPH_USER_BASE_FILTER?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        title={formData?.MSGRAPH_USER_BASE_FILTER?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <Input type="text" 
                        name={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_code}
                        label={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_name}
                        value={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        title={formData?.MSGRAPH_GROUP_BASE_FILTER?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <Checkbox 
                        checked={formData?.SYNC_NESTED_USER_GROUPS?.setting_value || false}
                        name={formData?.SYNC_NESTED_USER_GROUPS?.setting_code}
                        label={formData?.SYNC_NESTED_USER_GROUPS?.setting_name}
                        onChange={ handleInputChange}
                        disabled={loading}
                        title={formData?.SYNC_NESTED_USER_GROUPS?.setting_description}
                      />
                    </div>

                    

                    <div className="form-block vertical">
                      <h4>User Attribute Mapping</h4>
                      <Input type="text" 
                        name={formData?.USER_SOURCE_ID?.setting_code}
                        label={formData?.USER_SOURCE_ID?.setting_name}
                        value={formData?.USER_SOURCE_ID?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_SOURCE_ID?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_USERNAME?.setting_code}
                        label={formData?.USER_USERNAME?.setting_name}
                        value={formData?.USER_USERNAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_USERNAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_FULLNAME?.setting_code}
                        label={formData?.USER_FULLNAME?.setting_name}
                        value={formData?.USER_FULLNAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_FULLNAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_EMAIL?.setting_code}
                        label={formData?.USER_EMAIL?.setting_name}
                        value={formData?.USER_EMAIL?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_EMAIL?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.USER_TITLE?.setting_code}
                        label={formData?.USER_TITLE?.setting_name}
                        value={formData?.USER_TITLE?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_TITLE?.setting_description}
                      />
                    
                      <Input type="text" 
                        name={formData?.USER_DEPARTMENT?.setting_code}
                        label={formData?.USER_DEPARTMENT?.setting_name}
                        value={formData?.USER_DEPARTMENT?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.USER_DEPARTMENT?.setting_description}
                      />
                    </div>

                    <div className="form-block vertical">
                      <h4>User Group Attribute Mapping</h4>
                      <Input type="text" 
                        name={formData?.GROUP_SOURCE_ID?.setting_code}
                        label={formData?.GROUP_SOURCE_ID?.setting_name}
                        value={formData?.GROUP_SOURCE_ID?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_SOURCE_ID?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_NAME?.setting_code}
                        label={formData?.GROUP_NAME?.setting_name}
                        value={formData?.GROUP_NAME?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_NAME?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_EMAIL?.setting_code}
                        label={formData?.GROUP_EMAIL?.setting_name}
                        value={formData?.GROUP_EMAIL?.setting_value || ''} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_EMAIL?.setting_description}
                      />

                      <Input type="text" 
                        name={formData?.GROUP_DESCRIPTION?.setting_code}
                        label={formData?.GROUP_DESCRIPTION?.setting_name || ''}
                        value={formData?.GROUP_DESCRIPTION?.setting_value} 
                        onChange={handleInputChange}
                        disabled={loading}
                        labelPosition="left"
                        title={formData?.GROUP_DESCRIPTION?.setting_description}
                      />
                    </div>
                  </React.Fragment>

                  : <div className="form-block vertical">
                      To use this feature, authentication through Azure OpenID Connect must be enabled and correctly configured.
                    </div>
              }
              </div>
            
            </div>

            <div label="Logging" tabId="logging">
              <div className="form-content">

                <div className="form-block vertical">
                  <SelectButtons 
                    name={formData?.LOG_LEVEL?.setting_code}
                    label={formData?.LOG_LEVEL?.setting_name}
                    value={formData?.LOG_LEVEL?.setting_value || 0}
                    options={ formatSelectOptions({options: [{label: 'Info', value:'info'}, {label: 'Debug', value:'debug'}], optionValue: "value", optionLabel: "label"}) }
                    onChange={handleInputChange} 
                    disabled={loading}
                    title={formData?.LOG_LEVEL?.setting_description}
                  />
                </div>

              </div>
            </div>

          </Tabs>
          
          { formData && 
            <div className="form-block horizontal">
              <button 
                type="button" 
                className={"button main-button" + (loading ? ' loading' : '')} 
                disabled={loading}
                onClick={(event) => {event.preventDefault(); showModalDialog('editSettings', undefined, () => saveSettings(event));}}
                >
                <span>{loading ? 'SAVING...' : 'SAVE' }</span>
              </button>
              <button 
                type="button" 
                className="button" 
                onClick={cancel}
                disabled={loading}>
                <span>CANCEL</span>
              </button>
            </div>
          }

        </form>
        </div>

      </div>
  );
}