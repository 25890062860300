import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { glossaryService, businessTermService, referenceService } from '../_services';
import { Role, Asset } from '../_helpers';
import { 
  DataTable, 
  fetchToReferencesCountPerChild,
  SideDialog,  
  SkeletonLoader,
  MainColumn, 
  ObjectDetails, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos } from '../_hooks';

export const Glossary = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const [glossary, setGlossary] = useState([]);
  const [businessTerms, setBusinessTerms] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()
  
  const { glossary_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchBusinessTerms()
    fetchFromReferences()

  }, [glossary_id]);

  const fetchItems = async () => {
    
    glossaryService.getById(glossary_id)
      .then(res => {

        setGlossary(res.glossaries[0]);
        setLoading(false)
        fetchPhotos(res.glossaries[0], 'glossary_owner_user_id', setGlossary)
         
      })
      .catch(err => {
        setLoading(false)
        setMessage(JSON.stringify(err), 'error')
      });

  }

  const fetchBusinessTerms = async () => {
    const promise = new Promise( (resolve,reject) => {
      businessTermService.getByGlossaryId(glossary_id)
        .then(res => {
          setBusinessTerms(res.business_terms);
          setLoadingTable(false);  
          resolve(res.business_terms)         
        })
        .catch(err => {setMessage(JSON.stringify(err), 'error')});
    })

    Promise.all([promise, fetchToReferencesCountPerChild({setMessage, toObjectName: "business_term", toParentObjectName: "glossary", toParentObjectId: glossary_id})])
      .then( result => {
        updateReferenceCount(result[0], result[1])
        fetchPhotos(result[0], 'business_term_owner_user_id', setBusinessTerms)
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});

  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Glossary, glossary_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

const updateReferenceCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.to_references_count > maxValue) {
        maxValue = row.to_references_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.to_object_id === row.business_term_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        to_references_count: (statistic ? statistic.to_references_count : 0), 
        to_references_count_max: maxValue
      }
    })

    setBusinessTerms(prev => [...dataTmp])
  }

  const addBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.create(businessTerms)
      .then(res => {

        fetchBusinessTerms()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editGlossary = async data => {
    const glossaries = {glossaries: (Array.isArray(data) ? data : [data])}
    
    await glossaryService.update(glossaries)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(businessTerms)
      .then(async res => { 

        fetchBusinessTerms()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteGlossary = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})

    await glossaryService.destroy({glossaries: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate('/browse_business_terms')

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteBusinessTerm = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})

    await businessTermService.destroy({ business_terms: data })
      .then(async res => { 

        cancelModalDialog()
        clearSelectedItems()
        fetchBusinessTerms()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const data = loading ? [] : glossary

  const editableGlossary = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editableBusinessTerm = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  const SideDialogForm = editableBusinessTerm ? action.Component : undefined

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Glossary"
          title={data.glossary_name}
          loading={loading}
          >
            
            { !loading 
              ? (!data.glossary_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.glossary_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.glossary_owner_user_id}
              userName={data.glossary_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
          </ObjectDetails>

          { !loading && editableGlossary &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editGlossary', [data], editGlossary) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteGlossary', [data], deleteGlossary) }><span>DELETE</span></button>
          </div>
          }
      </MainColumn>

      <div className="column">
        <Tabs className="slim left">
          <div label="Business Terms" tabId='business_terms' resultCount={loadingTable || businessTerms.length === 0 ? undefined : businessTerms.length}>
        
            <DataTable
              columns={[
                {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                {id: 'to_references_count', name: 'References', type: 'chart', chartType: 'bar', data: {data:'to_references_count', max: 'to_references_count_max'}},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:business_term_owner_user_id', tooltip: 'business_term_owner_user_fullname'},
                {id: 'business_term_owner_user_fullname', name: 'Owner', link: '/users/:business_term_owner_user_id'},
                {id: 'modified_timestamp', name: 'Modified', type: 'datetime'}
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addBusinessTerm', items || selectedItems, addBusinessTerm) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editBusinessTerm', items || selectedItems, editBusinessTerm) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteBusinessTerm', items || selectedItems, deleteBusinessTerm) }
              ]}
              data={businessTerms}
              idColumn='business_term_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editableBusinessTerm}
              filterable={true}
              filterObjects={['business_term_name', 'business_term_owner_user_fullname']}
            />
          </div>
        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} />
          }
      </SideDialog>

    </div>
  ) 
}
