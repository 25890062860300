import config from '../config';
import { customFetch, handleResponse, jsonHeader, commonRequestOptions } from '../_helpers';

const endpointPath='auth'

export const authenticationService = {
    login,
    logout,
    startLogin,
    endLogin,
    refreshAccessToken
};

function startLogin(state, signal) {
    let requestOptions = {
        method: 'POST',
        headers: { ...jsonHeader() },
        body: JSON.stringify({ state }),
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/login/start`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}

function endLogin(url, signal) {
    let requestOptions = {
        method: 'POST',
        headers: { ...jsonHeader() },
        body: JSON.stringify({ url }),
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/login/end`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}

function refreshAccessToken(signal) {
    let requestOptions = {
        method: 'POST',
        headers: { ...jsonHeader() },
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/refresh_access_token`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}


function login(username, password, signal) {
    let requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/login`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}

function logout(signal) {
    let requestOptions = {
        method: 'POST',
        headers: { ...jsonHeader() },
        ...commonRequestOptions()
    };

    let endpoint = `${config.api.url}/${endpointPath}/logout`

    return customFetch(endpoint, requestOptions, signal).then(handleResponse)
}