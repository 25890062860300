import config from '../config';
import { authHeader, customFetch, jsonHeader, handleResponse, commonRequestOptions } from '../_helpers';

const endpointPath='field_unit'

export const fieldUnitService = {
    getAll,
    getById,
    create,
    update,
    destroy
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}