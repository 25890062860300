import React, { useState, useEffect } from 'react'
import { glossaryService, userService } from '../_services';
import { Checkbox, Select, formatSelectOptions, Input, Textarea, textareaInitialState, textareaToRaw } from '../_components';
import { getBatchEditValue } from '../_helpers'
import { useGlobalState } from '../_hooks'

export const AddBusinessTermForm = ({ mode, data, submitForm, cancelForm }) => { 
	
	const initialFormState = {
		business_term_id: null, 
		business_term_name: '',
		business_term_description: textareaInitialState({}), 
		business_term_example: textareaInitialState({}),
		glossary_id: null,
		business_term_owner_user_id: null
	}

	const initialBatchEditItems = {
		batch_glossary_id: false,
		batch_business_term_owner_user_id: false
	}

	const [hasError, setErrors] = useState(false);
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentGlossary, setCurrentGlossary] = useGlobalState('currentGlossary');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loading, setLoading] = useState(false);
	const [glossaries, setGlossaries] = useState([]);
	const [users, setUsers] = useState([]);	

	useEffect( () => {

  	// Get form data
  	fetchGlossaries();
  	fetchUsers();

  	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				business_term_id: data[0].business_term_id, 
				business_term_name: data[0].business_term_name || '',
				business_term_description: textareaInitialState({value: data[0].business_term_description}), 
				business_term_example: textareaInitialState({value: data[0].business_term_example}),
				glossary_id: data[0].glossary_id,
				business_term_owner_user_id: data[0].business_term_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				glossary_id: getBatchEditValue(data, 'glossary_id'),
				business_term_owner_user_id: getBatchEditValue(data, 'business_term_owner_user_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				glossary_id: currentGlossary.glossary_id,
				business_term_owner_user_id: loggedInUser?.user_id,
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, currentGlossary]);

	const fetchGlossaries = async () => {
	    glossaryService.getAll()
	      .then(res => { 

	      	setGlossaries(res.glossaries); 

	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchUsers = async () => {
	    userService.getAll()
	      .then(res => { 

	      	setUsers(res.users); 

	      })
	      .catch(err => {setErrors(err)});
	  }
  
	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {
		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {business_term_id: item.business_term_id, 
								business_term_name: item.business_term_name,
								glossary_id: item.glossary_id, 
								business_term_owner_user_id: item.business_term_owner_user_id, 
								...tmp
							}
			})

		} else {
			dataToSubmit = {
				...formData,
				business_term_description: textareaToRaw({value: formData.business_term_description}),
				business_term_example: textareaToRaw({value: formData.business_term_example})
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})	    	    
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState,
	      			glossary_id: (currentGlossary ? currentGlossary.glossary_id : null),
							business_term_owner_user_id: (loggedInUser ? loggedInUser.user_id : null)
						}
		}) 
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>

			{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="business_term_name" 
		      	label="Name"
		      	value={formData.business_term_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
	      </div>
			}

			<div className="form-block vertical">
				{ batchEdit 
				? <label>
						<Checkbox 
							value={batchEditItems.batch_glossary_id}
							name="batch_glossary_id"
							label="Edit Glossary"
							onChange={ e => handleInputChange(e, 'batch')}
							disabled={loading}
						/>
					</label>
				: <label>Glossary</label>
				}
				<Select 
          name="glossary_id"
          value={formData.glossary_id === 'multiple' ? undefined : formData.glossary_id}
          options={ formatSelectOptions({options: glossaries, optionValue: "glossary_id", optionLabel: "glossary_name"}) }
          onChange={handleInputChange}
          placeholder={formData.glossary_id === 'multiple' && '< Multiple >'}
          disabled={loading || (batchEdit && !batchEditItems.batch_glossary_id)}
        />
		  </div>

	    { !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
				    <Textarea
				      	name="business_term_description" 
				      	value={formData.business_term_description} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			  </div>
			}
			{ !batchEdit &&
			  <div className="form-block vertical">
					<label>Example</label>
				    <Textarea
				      	name="business_term_example" 
				      	value={formData.business_term_example} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			  </div>
			}	
		    
 			<div className="form-block vertical">
				{ batchEdit 
				? <label>
						<Checkbox 
							value={batchEditItems.batch_business_term_owner_user_id}
							name="batch_business_term_owner_user_id"
							label="Edit Owner"
							onChange={ e => handleInputChange(e, 'batch')}
							disabled={loading}
						/>
					</label>
				: <label>Owner</label>
				}
				<Select 
          name="business_term_owner_user_id"
          value={formData.business_term_owner_user_id === 'multiple' ? undefined : formData.business_term_owner_user_id}
          options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
          onChange={handleInputChange} 
          placeholder={formData.business_term_owner_user_id === 'multiple' && '< Multiple >'}
          disabled={loading || (batchEdit && !batchEditItems.batch_business_term_owner_user_id)}
        />
      </div>

      <div className="form-block horizontal">
				<button 
					type="submit" 
					className={"button main-button" + (loading ? ' loading' : '')} 
          disabled={loading}>
          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
        </button>
				<button 
					type="button" 
					className="button" 
					onClick={cancel}
					disabled={loading}>
					<span>CANCEL</span>
				</button>
			</div>

	    </form>
	  </div>
  )
}
