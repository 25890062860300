import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { formatConnectionString, generateURL, formatPath } from '../_helpers';
import { SkeletonLoader, textareaToPlainText } from './';

const _SearchResult = ({data, loadMore, resultCount, loading, searchTerm, containerElement=document, onClick}, ref) => {

  const refLoadMore = useRef()

  const [loadMoreData, setLoadMoreData] = useState(false)

  useEffect(() => {
    const element = containerElement
      if (element) {
      element.addEventListener('scroll', trackScrolling)
      return () => { 
        element.removeEventListener('scroll', trackScrolling) 
      }
    }
  }, [containerElement]);

  useEffect(() => {
    if (loadMoreData) {   
      loadMore()
    }       
  }, [loadMoreData]);

  useEffect(() => {
    setLoadMoreData(prev => {return false})      
  }, [data]);


  const isVisible = (el) => {
    return el.getBoundingClientRect().top <= window.innerHeight
  }

  const trackScrolling = () => {
    const loadingElement = refLoadMore.current;

    if (loadingElement && isVisible(loadingElement)) {
      setLoadMoreData(prev => {return true})
    }
  }

  const renderLink = (result) => {
    switch(result.object_name) {
      case 'user':
        return generateURL('/users/:user_id', result.searchable)

      case 'field_description':
        return generateURL('/browse_field_descriptions/:field_description_id', result.searchable)

      case 'field':
        return generateURL('/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', result.searchable)

      case 'dataset':
        return generateURL('/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', result.searchable)

      case 'dataset_group':
        return generateURL('/browse_datasets/:system_id/:datasource_id/:dataset_group_id', result.searchable)

      case 'datasource':
        return generateURL('/browse_datasets/:system_id/:datasource_id', result.searchable)

      case 'system':
        return generateURL('/browse_datasets/:system_id', result.searchable)

      case 'business_term':
        return generateURL('/browse_business_terms/:glossary_id/:business_term_id', result.searchable)

      default: 
        return "/"
    }
  }

  const renderTitle = (result) => {
    switch(result.object_name) {
      case 'user':
        return result.searchable['user_fullname']

      default: 
        return result.searchable[result.object_name+'_name']
    }
  }

  const renderSubtitle = (result) => {
    switch(result.object_name) {
      case 'user':
        return result.searchable.user_username

      case 'field_description':
        return result.searchable.field_role_name

      case 'field':
        return formatConnectionString(
                result.searchable.datasource_database, 
                result.searchable.datasource_hostname, 
                [result.searchable.dataset_group_source_name, result.searchable.dataset_source_name, result.searchable.field_name], 
                result.searchable.datasource_type_code, 
                result.searchable.datasource_type_category)

      case 'dataset':
        return formatConnectionString(
                result.searchable.datasource_database, 
                result.searchable.datasource_hostname, 
                [result.searchable.dataset_group_source_name, result.searchable.dataset_source_name], 
                result.searchable.datasource_type_code, 
                result.searchable.datasource_type_category)

      case 'dataset_group':
        return formatConnectionString(
                result.searchable.datasource_database, 
                result.searchable.datasource_hostname, 
                [result.searchable.dataset_group_source_name], 
                result.searchable.datasource_type_code, 
                result.searchable.datasource_type_category)

      case 'datasource':
        return formatConnectionString(
                result.searchable.datasource_database, 
                result.searchable.datasource_hostname, 
                [], 
                result.searchable.datasource_type_code, 
                result.searchable.datasource_type_category)

      case 'system':
        return result.searchable.system_type

      case 'business_term':
        return formatPath(
                [result.searchable.glossary_name, 
                result.searchable.business_term_name])

      default: 
        return null
    }
  }

  const renderIcon = (result) => {
    switch(result.object_name) {
      case 'field_description':
        return <div className={ "icon icon-" + result.searchable.field_role_name.replace(/ /g, '-').toLowerCase() } 
                    title={ result.searchable.field_role_name }></div>

      case 'field':
        return <div className={ "icon icon-" + result.searchable.datatype_category.replace(/ /g, '-').toLowerCase() } 
                    title={ result.searchable.datatype_name }></div>

      case 'dataset':
        return <div className={ "icon icon-" + result.searchable.dataset_type_name.replace(/ /g, '-').toLowerCase() } 
                    title={ result.searchable.dataset_type_name }></div>

      case 'datasource':
        return <div className={ "icon icon-" + result.searchable.datasource_type_category.replace(/ /g, '-').toLowerCase() } 
                    title={ result.searchable.datasource_type_name }></div>

      default: 
        return null
    }
  }

  const renderBody = (result) => {
    switch(result.object_name) {
      case 'user':
        return result.searchable['user_title']

      case 'field':
        return textareaToPlainText(result.searchable['field_description_description'])

      default: 
        return textareaToPlainText(result.searchable[result.object_name+'_description'])
    }
  }

  const setRef = (el, key) => {
    if (ref) {
      ref.current[key] = el
    }
  }
  
  return (
    <div className="SearchResult" >

      { !resultCount && !loading &&
        <div className="no-results">No assets found that matches "{ searchTerm }"</div>
      }

      {
        data.map( (result, key) => {

        return (
          <Link 
            key={key} 
            to={renderLink(result)} 
            tabIndex={0} 
            className="search-result-link" 
            ref={el => setRef(el, key) }
            onClick={onClick}
          >
          <div className="search-result" >
            <div className={ "search-result-type" }>{result.object_name.toUpperCase().replace('_',' ',)}</div>          
            <div className="search-result-title">{renderTitle(result)}</div>
            <div className="search-result-subtitle">   
              {renderIcon(result)}         
              <div className="connection_string">{renderSubtitle(result)}</div>          
            </div>
              <div className="search-result-body">{renderBody(result)}</div> 
         
          </div>
          </Link>
        )

      })}
      { ((loading && data.length === 0) || (data.length < resultCount)) &&
        <div className="search-result" 
             ref={refLoadMore} 
             onClick={ () => setLoadMoreData(prev => {return true}) }
             title="Click to load more assets">

          <div className="search-result-type"><SkeletonLoader width="10vw" /></div>          
          <div className="search-result-title"><SkeletonLoader width="20vw" /></div>
          <div className="search-result-subtitle">            
            <div className="connection_string"><SkeletonLoader width="15vw" /></div>          
          </div>            
        </div>
      }
    </div>

    

  );
};

export const SearchResult = forwardRef(_SearchResult);
