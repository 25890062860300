import React, { forwardRef } from 'react';
import { keyCode } from '../_helpers';
import { Icon } from './';
import { useTooltip } from '../_hooks'


const _Button = ({name, value, onClick, disabled=false, className, tooltip, buttonStyle, icon}, ref) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        !disabled && onClick();
        break;

      default:
        break;
    }
  }

	return (
		<div className={"CustomButton" + (className ? " " + className : "" ) + (disabled ? " disabled" : "" ) + (buttonStyle ? " " + buttonStyle : "" ) }
		 	tabIndex={ 0 }
		 	onKeyDown={handleKeyDown}
		 	ref={ref}
		 	onMouseEnter={ () => tooltip && showTooltip({title: tooltip})} 
      onMouseLeave={ () => tooltip && hideTooltip() }
		 	name={ name }
     	onClick={ !disabled ? onClick : undefined }
		>		
		{icon && <Icon name={icon} tooltip={tooltip} /> }
		<span>{value}</span>
		</div>
	)
}

export const Button = forwardRef(_Button);