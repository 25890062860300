import React, { useState, useEffect } from 'react'
import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom'
import { Role } from '../_helpers'
import { authenticationService, settingService } from '../_services'
import { PrivateRoute } from '../_middleware'
import { Breadcrumbs, Icon, ModalDialog, SearchBar, Photo, Tooltip, NavLink } from '../_components'
import { routesConfig } from '../routesConfig'
import { Login, LoadApp } from './'
import { ChangePassword } from './'
import packageJson from '../../package.json';
import { useGlobalState } from '../_hooks';

export const App = () => {
  
  const initialFullscreenError = {
    isError: false,
    errorTitle: "",
    errorText: ""
  }

  const [loading, setLoading] = useState(true)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')
  const [csrfToken, setCsrfToken] = useGlobalState('csrfToken')
  const [settings, setSettings] = useGlobalState('settings')
  const [showNavMenu, setShowNavMenu] = useState(true)
  const [messageText, setMessageText] = useState({})
  const [messageType, setMessageType] = useState(null)
  const [showMessageDetails, setShowMessageDetails] = useState(true)
  const [fullscreenError, setFullscreenError] = useState(initialFullscreenError)
  const [modalDialog, setModalDialog] = useState({active: false})

  const location = useLocation()
  const navigate = useNavigate()

  useEffect( () => {
    // Initialize app
    initialize()
  }, [])

  const initialize = async () => {

    try {

      Promise.all([
        // Get settings from backend
        await getSettings()
        ,
        // Get login state
        await getLoginState()
      ])
      .catch(e => { throw e })
      
      // Get Nav menu state from local storage
      setShowNavMenu(localStorage.getItem('showNavMenu') === "false" ? false : true)

      setLoading(false)

    } catch (err) {
      setLoading(false)
      setFullscreenError({
        isError: true,
        errorTitle: err.message,
        errorText: err.error
      })
    }
  }

  const getSettings = async () => {
    try {
      let settings = await settingService.getAllForFrontend()

      settings = settings.settings
      settings = {
        ...settings,
        authMethods: [
          ...(settings.OIDC_IS_ENABLED  ? ['oidc'] : []),
          ...(settings.LOCAL_IS_ENABLED ? ['local'] : [])
        ]
      }
      setSettings(settings)
    } catch(err) {
      throw err
    }
  }

  const getLoginState = async () => {
    try {
      const loginState = await authenticationService.endLogin(window.location.href, undefined)

      if (loginState.userInfo) {
        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        const state = JSON.parse(loginState.state)

        /// Restore url
        navigate(state.from.pathname, {...state.from})

      }

      setIsLoggedIn(loginState.isLoggedIn)

    } catch(err) {
      throw err
    }
  }

  const login = async ({username, password, state}) => {
    try {

      resetFullscreenError()

      const loginState = await authenticationService.login(username, password)

      if (loginState.userInfo) {
        setLoggedInUser(loginState.userInfo)
      } 

      if (loginState.csrf) {
        setCsrfToken(loginState.csrf)
      } 

      if (loginState.loginHandled) {

        setIsLoggedIn(loginState.isLoggedIn)
        setMessage(null)
        
        // Restore url
        navigate(state.from.pathname, {...state.from})

      }

    } catch (e) {
      // Errors handled in Login.js
      throw e  
    }
  }

  const logout = async () => {
    try {
      const logout = await authenticationService.logout()

      const tmpUser = loggedInUser
      setIsLoggedIn(false)
      setLoggedInUser({})

      closeMessage()
      
      if (tmpUser.user_auth_method !== 'local') {
        location.href = logout.logoutURL
      }

    } catch(e) {
      setMessage(e, 'error')
    }
  }

  const toggleNavMenu = () => {
    const state = !showNavMenu

    setShowNavMenu(state)

    // Persist state in local storage
    localStorage.setItem('showNavMenu', state.toString())
  }

  const setMessage = (msg, type = 'success') => {

    var message = ''
    // success
    if (type === 'success' && msg) {
      !msg.details && setTimeout( closeMessage, 3000);
      message = {
        message: msg.message || msg, 
        details: msg.details || undefined
      }
    
    // error
    } else { 

      try {

        try {
            msg = JSON.parse(msg)
        }
        catch(e) {
            // Do nothing
        }

        if (Object.keys(msg)[0] === 'dataValidationError') {

          let validationErrors = ''

          msg.dataValidationError.forEach( error => {
            validationErrors += ` ${error.msg}\n`
          })

          message = {
            message: 'Data Validation Error',
            details: validationErrors
          }

        } else {
          message = {
            message: msg?.message || msg.toString(),
            details: msg?.error
          }
        }

        setFullscreenError({
          isError: true,
          errorTitle: msg?.message || msg.toString(),
          errorText: msg?.error
        })

      } catch {
        message = {message: msg}
      }
    }

    
    setMessageText(message)
    setMessageType(type)   
  }

  const toggleMessageDetails = (e, state) => {
    setShowMessageDetails(state || !showMessageDetails)
  }

  const resetFullscreenError = () => {
    setFullscreenError(initialFullscreenError)
  }
  const closeMessage = () => {
    setMessageText({})
    setFullscreenError(initialFullscreenError)
    setShowMessageDetails(true)
  }

  const cancelModalDialog = () => {
    setModalDialog({active: false})
  }

  const navMenuState = ((showNavMenu) ? 'show' : '');

  const messageState = ((messageText.message) ? 'show' : '');

  return (
    <div className='App'>
      { isLoggedIn && 
        !( location.pathname === "/change_password" || location.pathname === "/login") && 
        !loading &&

        <div className={ "navbar " + navMenuState }>
          
          <div className="navbar-header">
            <div className="navbar-toggle-button" onClick={ toggleNavMenu } title={ (showNavMenu ? "Minimize" : "Expand") + " navigation menu"}><span></span></div>
          </div>
          
          <div className="navbar-content">

            <div className="navbar-group">
              <NavLink end to="/" className={ ({ isActive }) => "nav-link home" + (isActive ? " active" : "") } tooltip="Home"><Icon name="home"/><span>Home</span></NavLink>
            </div>

            <div className="navbar-group">
              <div className="navbar-group-title grey-text"><span>BROWSE</span></div>
              <NavLink to="/browse_datasets" className={ ({ isActive }) => "nav-link datasets" + (isActive ? " active" : "") } tooltip="Browse Datasets"><Icon name="datasets"/><span>Datasets</span></NavLink>
              <NavLink to="/browse_field_descriptions" className={ ({ isActive }) => "nav-link field-descriptions" + (isActive ? " active" : "") } tooltip=" Browse Field Descriptions"><Icon name="field_descriptions"/><span>Field Descriptions</span></NavLink>
              <NavLink to="/browse_business_terms" className={ ({ isActive }) => "nav-link business-terms" + (isActive ? " active" : "") } tooltip="Browse Business Terms"><Icon name="business_terms"/><span>Business Terms</span></NavLink>
            </div>
            
            { loggedInUser && loggedInUser.user_role_name === Role.admin  &&
            <div className="navbar-group">
              <div className="navbar-group-title grey-text"><span>MANAGE</span></div>
              <NavLink to="/users" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Users"><Icon name="users"/><span>Users</span></NavLink>
              <NavLink to="/user_groups" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage User Groups"><Icon name="user_groups"/><span>User Groups</span></NavLink>
              <NavLink to="/tasks" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Tasks"><Icon name="tasks"/><span>Tasks</span></NavLink>
              <NavLink to="/field_units" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Field Units"><Icon name="field_units"/><span>Field Units</span></NavLink>
              <NavLink to="/settings" className={ ({ isActive }) => "nav-link" + (isActive ? " active" : "") } tooltip="Manage Settings"><Icon name="settings"/><span>Settings</span></NavLink>
            </div>
            }

          </div>
        </div>
      }
      <div className="main-container">
        { isLoggedIn && 
          !( location.pathname === "/change_password" || location.pathname === "/login") && 
          !loading &&

        <div className="main-content-header-wrap">
          <div className={"main-content-header" + (location.pathname === "/" ? " home" : "")} >
            
            <Breadcrumbs />
            
            <div className="logo"></div>
            
            <div className="menu">

              { location.pathname !== "/" &&
                <div className="menu-item">
                  <div className="menu-button">
                    <SearchBar setMessage={ setMessage }/>
                  </div>
                </div>
              }
              <div className="menu-item">
                <div className="menu-button">
                  <div className="about-icon">i</div>
                </div>
                <div className="menu-content">
                  <div className="about-logo"></div>
                  <div className="about-version">Version {packageJson.version}</div>
                  <div className="about-copyright">©{new Date().getFullYear()} <a href="http://www.kayenta.se" rel="noopener noreferrer" target="_blank">Kayenta Consulting AB</a>.<br/>All rights reserved</div>
                </div>
              </div>

              <div className="menu-item last">
                <div className="menu-button">
                  <span className="user-name">
                    <Photo
                      size='small'
                      data={ loggedInUser.user_photo }
                      loading={ loading }
                    />
                  </span>
                </div>
                <div className="menu-content"> 
                  <div className="user-profile">
                  <Photo
                    size='medium'
                    data={ loggedInUser.user_photo }
                    loading={ loading }
                  />
                    <div className="user-profile-info">
                      <div className="user-profile-name">{loggedInUser.user_fullname}</div>
                      {loggedInUser.user_title &&
                        <div className="user-profile-title">{loggedInUser.user_title}</div>
                      }
                      <div className="user-profile-username">{loggedInUser.user_username}</div>
                    </div>
                  </div>
                  <div className={loggedInUser.user_auth_method !== 'local' ? "horizontal" : ""}>
                    <Link className="menu-link" to={ "/profile" }><Icon name="profile"/><span>Profile</span></Link>
                    { loggedInUser.user_auth_method === 'local' && 
                      <Link className="menu-link" to={ "/change_password" }><Icon name="password"/><span>Change Password</span></Link>
                    }
                    <div className="menu-link" onClick={ logout }><Icon name="signout"/><span>Sign Out</span></div>
                  </div>
                </div>
              </div>

            </div>

            <div className={"message-container " + messageState + " " + messageType }>
              <div className="message-text-container">
                <div className="message-text">{ messageText.message }</div>
                { messageText.details &&
                  <React.Fragment>
                  <div className={"message-details-toggle-button" + (showMessageDetails ? " show" : "")} onClick={toggleMessageDetails}>Details<span></span></div>
                  { showMessageDetails &&
                    <div className="message-details">{ messageText.details }</div>
                  }
                  </React.Fragment>
                }
              </div>
              <div className="close-button" onClick={ closeMessage }></div>
            </div>

            { modalDialog.active && 
              <ModalDialog
                title={modalDialog.title}
                body={modalDialog.body}
                submitLabel={modalDialog.submitLabel}
                cancelLabel={modalDialog.cancelLabel}
                onSubmit={modalDialog.onSubmit || cancelModalDialog}
                onCancel={modalDialog.onCancel || cancelModalDialog}
                customButtons={modalDialog.customButtons}
                loading={modalDialog.loading}
              />
            }

          </div>
        </div>
        }
        <div className="main-content">
          { loading 
            ? <LoadApp fullscreenError={fullscreenError} />
            : 
              <Routes>

                <Route path="/login" element={
                  <Login
                    fullscreenError={fullscreenError} 
                    setFullscreenError={setFullscreenError} 
                    resetFullscreenError={resetFullscreenError} 
                    setMessage={setMessage} 
                    login={login} />
                }/>

                <Route path="/change_password" element={
                  <ChangePassword fullscreenError={fullscreenError} setFullscreenError={setFullscreenError} resetFullscreenError={resetFullscreenError} setMessage={setMessage} />
                }/>

                
                {routesConfig.map(({ path, name, element, roles }, key) => (
                  <Route key={key} path={path} element={
                      <PrivateRoute 
                        roles={ roles }
                        element={ element }
                        setMessage={ setMessage }   
                        setModalDialog={ setModalDialog }      
                        cancelModalDialog={ cancelModalDialog }         
                      />
                    }
                  />
                ))}
                
              </Routes>
              
          }
        </div>
      </div>  
      <Tooltip />     
    </div>
  )
}