import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { systemService, datasourceService, referenceService } from '../_services';
import { Role, Asset } from '../_helpers';
import { DataTable, MainColumn, ObjectDetails, References, SideDialog, Tabs, TextareaReadOnly, textareaInitialState, fetchToReferencesCount, UserLink } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState, usePhotos } from '../_hooks';

export const System = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [system, setSystem] = useState([]);
  const [datasources, setDatasources] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const { system_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchDatasources()
    fetchFromReferences()

    fetchToReferencesCount({setMessage, toObjectName: Asset.System, toObjectId: system_id})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
      })

  }, [system_id]);

  const fetchItems = async () => {
    
    systemService.getById(system_id)
      .then(res => {

        setSystem(res.systems[0])
        setLoading(false)
        fetchPhotos(res.systems[0], 'system_owner_user_id', setSystem)
         
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        setLoading(false)
      });
  }

  const fetchDatasources = async () => {
    const promise = new Promise(  (resolve, reject) => {
      datasourceService.getBySystemId(system_id)
        .then(res => {

          setDatasources(res.datasources)
          setLoadingTable(false)
          fetchPhotos(res.datasources, 'datasource_owner_user_id', setDatasources)
          resolve(res.datasources)
           
        })
        .catch(err => {
          setMessage(JSON.stringify(err), 'error')
          setLoadingTable(false)
        })
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => { setMessage(JSON.stringify(err), 'error')}) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.System, system_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasourceService.getFieldStatistics(system_id)
        .then(res => {

          resolve(res.field_statistics)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.datasource_id === row.datasource_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })
    setDatasources([...dataTmp]); 
  }

  const addDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.create(datasources)
      .then(res => {

        fetchDatasources()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(datasources)
      .then(async res => { 

        fetchDatasources()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(systems)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteDatasource = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasourceService.destroy({ datasources: selectedItems })
      .then(async res => { 

        cancelModalDialog()
        clearSelectedItems()
        fetchDatasources()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteSystem = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await systemService.destroy({systems: data})
      .then(async res => { 

        cancelModalDialog()
        setMessage(res.message)

        // Redirect to parent page
        navigate('/browse_datasets')

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }

  const data = loading ? [] : system

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const SideDialogForm = editable ? action.Component : undefined

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="System"
          title={data.system_name}
          subtitleText={ data && !loading &&
            data.system_type 
          }
          loading={loading}
          >

            { !loading 
              ? (!data.system_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.system_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <Skeleton width="10vw" count={2} />
            }

            <h3>{ !loading ? "Owner" : <Skeleton width="5vw"/>}</h3>
            <UserLink 
              userId={data.system_owner_user_id}
              userName={data.system_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
        </ObjectDetails>
          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editSystem', [data], editSystem) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteSystem', [data], deleteSystem) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          onTabChange={ onTabChange } 
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults = {true}
        >
          <div label="Datasources" tabId="datasources" resultCount={loadingTable || datasources.length === 0 ? undefined : datasources.length}>
            
            <DataTable
              columns={[
                {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:datasource_owner_user_id', tooltip: 'datasource_owner_user_fullname'},
                {id: 'datasource_owner_user_fullname', name: 'Owner', link: '/users/:datasource_owner_user_id'}               
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDatasource', items || selectedItems, addDatasource) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasource', items || selectedItems, editDatasource) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasource', items || selectedItems, deleteDatasource) }
              ]}
              data={datasources}
              idColumn='datasource_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editable}
              filterable={true}
              filterObjects={['datasource_name', 'datasource_owner_user_fullname']}
            />
          </div>

          <div label="References" tabId="references" resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              setMessage={setMessage}
              toObjectName={Asset.System}
              toObjectId={system_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

        </Tabs>


      </div>

      <SideDialog 
          mode={action.mode}
          title={action.title}
          closeDialog={ action.cancelForm }
          >
            {SideDialogForm &&
            <SideDialogForm {...action} setModalDialog={setModalDialog} setMessage={setMessage} />
            }
        </SideDialog>

    </div>
  ) 
}
