import React, { useState, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { datasourceService, datasourceTypeService, systemService, userService } from '../_services';
import { Checkbox, Select, FormTabs, formatSelectOptions, Input, Textarea, textareaInitialState, textareaToRaw } from '../_components';
import { getBatchEditValue, handleError } from '../_helpers'
import { useModalDialog, useGlobalState } from '../_hooks';

export const AddDatasourceForm = ({ mode, data, submitForm, cancelForm, setModalDialog, setMessage }) => { 
	
	const initialFormState = {
		datasource_id: null, 
		datasource_name: '',
		datasource_hostname: '',
		datasource_port: '',
		datasource_database: '',
		datasource_description: textareaInitialState({}), 
		datasource_type_id: '', 
		datasource_account: '',
		datasource_service: '',
		datasource_username: '',
		datasource_password: '',
		datasource_is_trusted_connection: false,
		datasource_is_encrypted_connection: false,
		use_information_schema_view: false,
		information_schema_view: '',
		use_relationship_view: false,
		relationship_view: '',
		datasource_filter: '',
		relationship_map: '',
		disable_virtual_relationships: false,
		system_id: '',
		datasource_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_datasource_type_id: false, 
		batch_system_id: false,
		batch_datasource_owner_user_id: false
	}

	const [hasError, setErrors] = useState(false);
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentSystem, setCurrentSystem] = useGlobalState('currentSystem');
	const [formData, setFormData] = useState(initialFormState);
	const [loading, setLoading] = useState(false);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [datasourceTypes, setDatasourceTypes] = useState([]);
	const [datasourceType, setDatasourceType] = useState([]);
	const [systems, setSystems] = useState([]);
	const [defaultValues, setDefaultValues] = useState({});
	const [users, setUsers] = useState([]);	
	const [connectionTestResult, setConnectionTestResult] = useState({success: null, msg: ''});
	const [testing, setTesting] = useState(false);

	const [abortTestConnection, setAbortTestConnection] = useState(new AbortController());
	
	const { showModalDialog } = useModalDialog(setModalDialog, setMessage)


	useEffect( () => {
		
		// Get form data
  	fetchDatasourceTypes();
  	fetchSystems();
  	fetchUsers();
  	fetchDefaultValues();

    // Cleanup
    return () => {
      abortTestConnection.abort()
    }

	}, []);

	useEffect(() => {
		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				datasource_id: data[0].datasource_id, 
				datasource_name: data[0].datasource_name || '',
				datasource_hostname:  data[0].datasource_hostname || '',
				datasource_port:  data[0].datasource_port || '',
				datasource_database: data[0].datasource_database || '',
				datasource_description: textareaInitialState({value: data[0].datasource_description}), 
				datasource_type_id: data[0].datasource_type_id,
				datasource_account: data[0].datasource_account || '',
				datasource_service: data[0].datasource_service || '',
				datasource_username: data[0].datasource_username || '',
				datasource_password: '',
				datasource_is_trusted_connection: data[0].datasource_is_trusted_connection,
				datasource_is_encrypted_connection: data[0].datasource_is_encrypted_connection,
				use_information_schema_view: data[0].use_information_schema_view || false,
				information_schema_view: data[0].information_schema_view || '',
				use_relationship_view: data[0].use_relationship_view || false,
				relationship_view: data[0].relationship_view || '',
				datasource_filter: JSON.stringify(data[0].datasource_filter || undefined, null, 2) || '',
				relationship_map: JSON.stringify(data[0].relationship_map || undefined, null, 2) || '',
				disable_virtual_relationships: data[0].disable_virtual_relationships || false,
				system_id: data[0].system_id,
				datasource_owner_user_id: data[0].datasource_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				datasource_type_id: getBatchEditValue(data, 'datasource_type_id'),
				system_id: getBatchEditValue(data, 'system_id'),
				datasource_owner_user_id: getBatchEditValue(data, 'datasource_owner_user_id')
			})
		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				datasource_owner_user_id: loggedInUser?.user_id,
				system_id: currentSystem.system_id
			})
		} else {
			resetForm()		
		}

		if (data.length > 0) {
			setDatasourceType( datasourceTypes.find(x => x.datasource_type_id === data[0].datasource_type_id))
		}

	}, [mode, data, loggedInUser, currentSystem]);
	
	const fetchDatasourceTypes = async () => {
    datasourceTypeService.getAll()
      .then(res => { 
      	setDatasourceTypes(res.datasource_types);   
      	setDatasourceType( res.datasource_types.find(x => x.datasource_type_id === data[0].datasource_type_id) )  	
      })
      .catch(err => {setErrors(err)});
  }

	const fetchSystems = async () => {
    systemService.getAll()
      .then(res => { 

      	setSystems(res.systems); 

      })
      .catch(err => {setErrors(err)});
  }

	const fetchUsers = async () => {
    userService.getAll()
      .then(res => { 

      	setUsers(res.users); 

      })
      .catch(err => {setErrors(err)});
  }
  
	const fetchDefaultValues = async () => {
	  datasourceService.getDefaultValues()
	    .then(res => { 

	    	setDefaultValues(res.defaultValues); 

	    })
	    .catch(err => {setErrors(err)});
	}
	const handleInputChange = (event, formPart) => {
	  let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

    if (name === 'datasource_type_id') { setDatasourceType( datasourceTypes.find(x => x.datasource_type_id === value) ) }	
		
		switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }

		resetConnectionTestResult()
	}

	const onSubmit = event => {
		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {datasource_id: item.datasource_id, 
								datasource_name: item.datasource_name,
								datasource_hostname:  item.datasource_hostname,
								datasource_port:  item.datasource_port,
								datasource_database: item.datasource_database,
								datasource_type_id: item.datasource_type_id,
								datasource_account: item.datasource_account,
								datasource_service: item.datasource_service,
								datasource_username: item.datasource_username,
								datasource_password: item.datasource_password,
								datasource_is_trusted_connection: item.datasource_is_trusted_connection,
								datasource_is_encrypted_connection: item.datasource_is_encrypted_connection,
								use_information_schema_view: item.use_information_schema_view,
								information_schema_view: item.information_schema_view,
								use_relationship_view: item.use_relationship_view,
								relationship_view: item.relationship_view,
								datasource_filter: item.datasource_filter,
								relationship_map: item.relationship_map,
								disable_virtual_relationships: item.disable_virtual_relationships,
								system_id: item.system_id,
								datasource_owner_user_id: item.datasource_owner_user_id,
								...tmp
							}
			})

		} else { 
			dataToSubmit = {
				...formData,
				datasource_description: textareaToRaw({value: formData.datasource_description}),
				datasource_database: datasourceType.datasource_type_code === 'oracle' && formData.datasource_database === '' ? formData.datasource_service : formData.datasource_database
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				resetForm()
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})	    		    
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {
  	resetConnectionTestResult()
  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState,
							system_id: ( currentSystem ? currentSystem.system_id : null) 
						}
		}) 
  }

  const resetConnectionTestResult = () => {
  	setConnectionTestResult({success: null, msg: ''})
  }

  const testConnection = async () => {
    resetConnectionTestResult()
    setTesting(true)

    await datasourceService.testConnection({datasources: [{...formData, 
    														datasource_type_code: datasourceType.datasource_type_code,
    														datasource_type_name: datasourceType.datasource_type_name
    													}]}, abortTestConnection)
      .then(async res => { 
        setConnectionTestResult({success: true, msg: 'Connection OK'})
      })
      .catch(err => {
      	handleError({err}, () => {
      		setConnectionTestResult({success: false, msg: `${err.message}:\n ${err.error}` })
      	})
      });
      setTesting(false)
  }

  const cancelTestConnection = () => {
  	abortTestConnection.abort()
  	setAbortTestConnection(new AbortController())
  }

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>
				{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="datasource_name" 
		      	label="Name"
		      	value={formData.datasource_name} 
		      	onChange={handleInputChange}
		      	autoComplete='new-password'
		      	disabled={loading}
		      />
	      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
					<Textarea
		      	name="datasource_description" 
		      	value={formData.datasource_description} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		    </div>
		  	}

		  	<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_datasource_type_id}
									name="batch_datasource_type_id"
									label="Edit Datasource Type"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Datasource Type</label>
					}
					<Select 
            name="datasource_type_id"
            value={formData.datasource_type_id === 'multiple' ? undefined : formData.datasource_type_id}
            options={ formatSelectOptions({options: datasourceTypes, optionValue: "datasource_type_id", optionLabel: "datasource_type_name", optionIcon: "datasource_type_category"}) }
            onChange={handleInputChange}
            placeholder={formData.datasource_type_id === 'multiple' && '< Multiple >'}
            disabled={loading || (batchEdit && !batchEditItems.batch_datasource_type_id)}
          />
		    </div>


		    <FormTabs>
					<div label="General">

						<div className="form-block vertical">
							{ batchEdit 
								? <label>
										<Checkbox 
											value={batchEditItems.batch_system_id}
											name="batch_system_id"
											label="Edit System"
											onChange={ e => handleInputChange(e, 'batch')}
											disabled={loading}
										/>
									</label>
								: <label>System</label>
							}
							<Select 
		            name="system_id"
		            value={formData.system_id === 'multiple' ? undefined : formData.system_id}
		            options={ formatSelectOptions({options: systems, optionValue: "system_id", optionLabel: "system_name", tooltip: "system_description"}) }
		            onChange={handleInputChange} 
		            placeholder={formData.system_id === 'multiple' && '< Multiple >'}
		            disabled={loading || (batchEdit && !batchEditItems.batch_system_id)}
		          />
				    </div>

				    <div className="form-block vertical">
							{ batchEdit 
								? <label>
										<Checkbox 
											value={batchEditItems.batch_datasource_owner_user_id}
											name="batch_datasource_owner_user_id"
											label="Edit Owner"
											onChange={ e => handleInputChange(e, 'batch')}
											disabled={loading}
										/>
									</label>
								: <label>Owner</label>
							}
							<Select 
		            name="datasource_owner_user_id"
		            value={formData.datasource_owner_user_id === 'multiple' ? undefined : formData.datasource_owner_user_id}
		            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
		            onChange={handleInputChange} 
		            placeholder={formData.datasource_owner_user_id === 'multiple' && '< Multiple >'}
		            disabled={loading || (batchEdit && !batchEditItems.batch_datasource_owner_user_id)}
		          />
			      </div>

			    </div>

			    { !batchEdit && 
			    	datasourceType && 
			    	(datasourceType.datasource_type_code === 'mssql' || 
			    	 datasourceType.datasource_type_code === 'oracle' ||
			    	 datasourceType.datasource_type_code === 'postgres' || 
					 datasourceType.datasource_type_code === 'ibmdb2' || 
			    	 datasourceType.datasource_type_code === 'snowflake') &&

					<div label="Connection">
						
						<React.Fragment>

						{ datasourceType && !batchEdit && 
							(datasourceType.datasource_type_code === 'mssql' ||
							 datasourceType.datasource_type_code === 'oracle' ||
							 datasourceType.datasource_type_code === 'ibmdb2' || 
							 datasourceType.datasource_type_code === 'postgres')  &&
							<React.Fragment>
								<div className="form-block vertical">
						      <Input type="text" 
						      	name="datasource_hostname" 
						      	label="Hostname"
						      	value={formData.datasource_hostname} 
						      	onChange={handleInputChange}
						      	autoComplete='new-password'
						      	disabled={loading}
						      />
					      </div>
							
								<div className="form-block vertical">
						      <Input type="text" 
						      	name="datasource_port" 
						      	label="Port"
						      	value={formData.datasource_port} 
						      	onChange={handleInputChange}
						      	autoComplete='new-password'
						      	disabled={loading}
						      	placeholder={defaultValues[datasourceType.datasource_type_code]?.port}
						      />
					      </div>

					    </React.Fragment>
						}

						{ datasourceType && (datasourceType.datasource_type_category === 'folder') && !batchEdit &&
						<div className="form-block vertical">
						      <Input type="text" 
						      	name="datasource_hostname" 
						      	label="Hostname (Path)"
						      	value={formData.datasource_hostname} 
						      	onChange={handleInputChange}
						      	autoComplete='new-password'
						      	disabled={loading}
						      />
					      </div>
						}
						{ datasourceType && (datasourceType.datasource_type_category === 'tableau_server') && !batchEdit &&
						<div className="form-block vertical">
						      <Input type="text" 
						      	name="datasource_hostname" 
						      	label="Hostname (Path)"
						      	value={formData.datasource_hostname} 
						      	onChange={handleInputChange}
						      	autoComplete='new-password'
						      	disabled={loading}
						      />
					      </div>
						}
						{ (datasourceType.datasource_type_code === 'snowflake') &&
							<div className="form-block vertical">
					      <Input type="text" 
					      	name="datasource_account" 
					      	label="Account"
					      	value={formData.datasource_account} 
					      	onChange={handleInputChange}
					      	autoComplete='new-password'
					      	disabled={loading}
					      />
				      </div>
						}
				    { (datasourceType.datasource_type_code === 'snowflake' || datasourceType.datasource_type_code === 'oracle') &&
				      <div className="form-block vertical">
					      <Input type="text" 
					      	name="datasource_service" 
					      	label={datasourceType.datasource_type_code === 'snowflake' ? 'Warehouse' : 'Service Name'}
					      	value={formData.datasource_service} 
					      	onChange={handleInputChange}
					      	autoComplete='new-password'
					      	disabled={loading}
					      />
				      </div>
						}
						{ datasourceType && !batchEdit && datasourceType.datasource_type_category === 'database'  &&
				      <div className="form-block vertical">
					      <Input type="text" 
					      	name="datasource_database"
					      	label="Database"
					      	value={formData.datasource_database} 
					      	onChange={handleInputChange}
					      	autoComplete='new-password'
					      	disabled={loading}
					      	placeholder={datasourceType.datasource_type_code === 'oracle' ? formData.datasource_service : ""}
					      />
				      </div>
			      }

						{ (datasourceType.datasource_type_code === 'mssql') &&
							<div className="form-block vertical">
						    <Checkbox 
					      	name='datasource_is_trusted_connection' 
					      	checked={ formData.datasource_is_trusted_connection } 
					      	onChange={handleInputChange}
					      	label='Trusted Connection'
					      	disabled={loading}
					      	title={'Flag to set if a trusted connection ("Windows authentication") should be used. Only relevant for MSSQL datasources. This feature uses the NTLM protocol, Kerberos is not supported. When using trusted connection, Domain, Username and Password must be specified. Specify Domain as part of the username like "DOMAIN\\\\username"'}
					      />
					    </div>
						}


						<div className="form-block vertical">
				      <Input type="text" 
				      	name="datasource_username"
				      	label={datasourceType.datasource_type_code === 'ibmdb2' ? 'UID' : 'Username'}
				      	value={formData.datasource_username} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      	autoComplete="new-password"
				      	placeholder={formData.datasource_is_trusted_connection ? "DOMAIN\\\\username" : ""}
				      />
			      </div>
						
						
						<div className="form-block vertical">
				      <Input type="password" 
				      	name="datasource_password" 
				      	label={datasourceType.datasource_type_code === 'ibmdb2' ? 'PWD' : 'Password'}
				      	value={formData.datasource_password} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      	autoComplete="new-password"
				      	placeholder={mode !== "add" ? "< hidden >" : ""}
				      />
			      </div>

			      { (datasourceType.datasource_type_code === 'postgres' || datasourceType.datasource_type_code === 'mssql') &&
							<div className="form-block vertical">
						    <Checkbox 
					      	name='datasource_is_encrypted_connection' 
					      	checked={ formData.datasource_is_encrypted_connection } 
					      	onChange={handleInputChange}
					      	label='Use SSL/encryption'
					      	disabled={loading}
					      />
					    </div>
						}

			      <div className={"form-block" + ( connectionTestResult.success === null || connectionTestResult.success ? " horizontal" : " vertical")}>
							<button type="button" 
								className={ "button secondary-button" + (testing ? " loading" : "") } 
								onClick={ testConnection }
								disabled={ testing || loading }>
							<span>TEST CONNECTION</span></button>		
							
							<div className={"connection-test-result" + (connectionTestResult.success === null || connectionTestResult.success ? "" : " failed") }>
							
								{ testing 
									? <React.Fragment><div>Connecting...</div><div className="connection-test-cancel" onClick={cancelTestConnection}>Cancel</div></React.Fragment> 
									: <React.Fragment><div className={"connection-test-result-icon" + (connectionTestResult.success ? " success" : "") }></div>{connectionTestResult.msg}</React.Fragment> 
								}
							</div>
							
						</div>

			      </React.Fragment>
					
					</div>
					}
				
					{ !batchEdit && 
			    	datasourceType && 
			    	(datasourceType.datasource_type_code === 'mssql' || 
			    	 datasourceType.datasource_type_code === 'oracle' ||
			    	 datasourceType.datasource_type_code === 'postgres' || 
					 	 datasourceType.datasource_type_code === 'ibmdb2' ||
			    	 datasourceType.datasource_type_code === 'snowflake') &&

							<div label="Sync Settings">

					    <div className="form-block horizontal">
								<Checkbox 
					      	name='use_information_schema_view' 
					      	checked={formData.use_information_schema_view } 
					      	onChange={handleInputChange}
					      	label='Use Custom Information Schema View/Table'
					      	disabled={loading}
					      />
					    </div>

					    { formData.use_information_schema_view &&
						    <div className="form-block vertical">
									<Input type="text" 
							      	name="information_schema_view" 
							      	label="Custom Information Schema View/Table Name"
							      	value={formData.information_schema_view} 
							      	onChange={handleInputChange}
							      	disabled={loading}
							      	placeholder="Ex: schema.v_information_schema"
							      />
						    </div>
						  }

					     <div className="form-block horizontal">
									<Checkbox 
						      	name='use_relationship_view' 
						      	checked={formData.use_relationship_view } 
						      	onChange={handleInputChange}
						      	label='Use Custom Relationship View/Table'
						      	disabled={loading}
						      />
						    </div>

						    { formData.use_relationship_view &&
							    <div className="form-block vertical">
										<Input type="text" 
								      	name="relationship_view" 
								      	label="Custom Relationship View/Table Name"
								      	value={formData.relationship_view} 
								      	onChange={handleInputChange}
								      	disabled={loading}
								      	placeholder="Ex: schema.v_foreign_keys"
								      />
							    </div>
							  }

							<div className="form-block vertical">
								<label>Datasource Filters</label>
								<TextareaAutosize
					      	name="datasource_filter" 
					      	value={formData.datasource_filter} 
					      	onChange={handleInputChange}
					      	disabled={loading}
					      	className="code"
					      />
					    </div>

					    <div className="form-block horizontal">
						  	<button type="button" 
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('showFilterQuery', formData) }>
									<span>SHOW DATASOURCE IMPORT QUERY</span>
								</button>
							</div>


							<div className="form-block vertical">
								<label>Relationship Map Rules</label>
								<TextareaAutosize
					      	name="relationship_map" 
					      	value={formData.relationship_map} 
					      	onChange={handleInputChange}
					      	disabled={loading}
					      	className="code"
					      />
					    </div>

					    <div className="form-block horizontal">
						  	<button type="button" 
										className={ "button secondary-button" } 
										onClick={ () => showModalDialog('showRelationshipQuery', formData) }>
									<span>SHOW RELATIONSHIP UPSERT QUERY</span>
								</button>
							</div>

							<div className="form-block horizontal">
								<Checkbox 
					      	name='disable_virtual_relationships' 
					      	checked={formData.disable_virtual_relationships } 
					      	onChange={handleInputChange}
					      	label='Disable Virtual Relationships'
					      	disabled={loading}
					      />
					    </div>

						</div>
					}

				</FormTabs>

	      <div className="form-block horizontal">
					<button 
						type="submit" 
						className={"button main-button" + (loading ? ' loading' : '')} 
	          disabled={loading}>
	          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
	        </button>
					<button 
						type="button" 
						className="button" 
						onClick={cancel}
						disabled={loading}>
						<span>CANCEL</span>
					</button>
				</div>

	    </form>
	  </div>
  )
}
