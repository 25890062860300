import { useState, useEffect } from 'react';
import { useQueryParams } from './';
import { searchService } from '../_services';

export const useSearch = ({objectName=undefined, setLoading=undefined, setMessage, pagination=true}) => {//, searchTerm, setSearchTerm, setSearchResults, setSearchResultsCount}) => {

  const { queryParams, setHistory } = useQueryParams()

  const [searchTerm, setSearchTerm] = useState(queryParams.search)
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsCount, setSearchResultsCount] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    
    if (searchTerm) {
      fetchSearchResults(searchTerm)
    }

  }, [])

  useEffect(() => {
    
    setHistory({search: searchTerm})
    setPage(1)

  }, [searchTerm])

  const fetchSearchResults = async (search = undefined, append = false, n = undefined) => {

    try {

      const res = await fetchSearchResultsData(search, append, n)

      n && setPage(prev => n)

      if (append) {
        setSearchResults(prev => {
          return [...prev, ...res.searchResults]
        })
      } else {
        setSearchResults(prev => res.searchResults)
      }

      setSearchResultsCount(prev => res.searchResultsCount)

    } catch (err) {
      setMessage(JSON.stringify(err), 'error');
    }
  }

  const fetchSearchResultsData = (search = undefined, append = false, n = undefined) => { //, append = false, nForced=undefined) => {
    return new Promise( async (resolve, reject) => {
    setLoading && setLoading(true)

    let tmpSearch = searchTerm
    if (search !== undefined) {
      tmpSearch = search
    }

    const string = tmpSearch.replace(/[^\w\s:]/gi, '')

    const object = (objectName === 'all') ? undefined : objectName

    const pageNumber = pagination ? ((n !== undefined) ? n : page) : undefined

    await searchService.getAll(string, object, pageNumber, append)
      .then(res => {
        if (res) {
          let tmp = {}
          let allCount = 0
          res.search_results_count.forEach(result => {
            tmp = {...tmp, [result.object_name]: parseInt(result.count)}
            allCount = allCount + parseInt(result.count)
          })

          tmp = {...tmp, all: allCount}        
          
          resolve({searchResults: res.search_results, searchResultsCount: tmp})

          setLoading && setLoading(prev => false)
        }
      })
      .catch(err => {
        setLoading && setLoading(prev => false)
        reject(err)
      })
    })
  }

  const clearSearchResults = () => {  
    setSearchTerm("")
    setSearchResults([])  
    setSearchResultsCount({}) 
    setPage(1)
  }

  const loadNextPage = () => {
    const tmpPage = page + 1 
    fetchSearchResults(undefined, true, tmpPage)
  }

  return { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults,
    fetchSearchResultsData, 
    clearSearchResults, 
    loadNextPage };
};