import React, { createContext, useState, useMemo } from "react";

export const TooltipContext = createContext();

export const ToolTipProvider = ({ children }) => {
  const [tooltip, setTooltip] = useState({})
  const [show, setShow] = useState(false)

  const value = useMemo( () => (
    { tooltip, setTooltip, show, setShow }
  ), [tooltip, show, setTooltip, setShow])

  return (
    <TooltipContext.Provider
      value={value}
      //value={{ tooltip, setTooltip, show, setShow }}
    >
      {children}
    </TooltipContext.Provider>
  );
}