import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasourceService, datasetGroupService, referenceService } from '../_services';
import { formatConnectionString, Role, Asset } from '../_helpers';
import { 
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  SideDialog, 
  SkeletonLoader, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos } from '../_hooks';

export const Datasource = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const [datasource, setDatasource] = useState([]);
  const [datasetGroups, setDatasetGroups] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const { system_id, datasource_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchDatasetGroups()
    fetchFromReferences()

  }, [datasource_id]);

  const fetchItems = async () => {
    
    datasourceService.getById(datasource_id)
      .then(res => {

        setDatasource(res.datasources[0])
        setLoading(false)
        fetchPhotos(res.datasources[0], 'datasource_owner_user_id', setDatasource)
        
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        setLoading(false)
      });
  }

  const fetchDatasetGroups = async () => {
    const promise = new Promise(  (resolve, reject) => {
      datasetGroupService.getByDatasourceId(datasource_id)
        .then(res => {

          setDatasetGroups(res.dataset_groups)
          setLoadingTable(false)
          fetchPhotos(res.dataset_groups, 'dataset_group_owner_user_id', setDatasetGroups)
          resolve(res.dataset_groups)
           
        })
        .catch(err => {
          setMessage(JSON.stringify(err), 'error')
          setLoadingTable(false)
        });
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => { setMessage(JSON.stringify(err), 'error')}) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Datasource, datasource_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasetGroupService.getFieldStatistics(datasource_id)
        .then(res => {

          resolve(res.field_statistics)

        })
        .catch(err => {
          return null
        });
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.dataset_group_id === row.dataset_group_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })
    setDatasetGroups([...dataTmp]); 
  }

  const addDatasetGroup = async data => {
    const dataset_groups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.create(dataset_groups)
      .then(res => {

        fetchDatasetGroups()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDatasource = async data => {
    const datasources = {datasources: (Array.isArray(data) ? data : [data])}
    
    await datasourceService.update(datasources)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editDatasetGroup = async data => {
    const dataset_groups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(dataset_groups)
      .then(async res => { 

        fetchDatasetGroups()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteDatasource = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasourceService.destroy({ datasources: data })
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}`)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const deleteDatasetGroup = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetGroupService.destroy({ dataset_groups: data })
      .then(async res => { 

        cancelModalDialog()
        clearSelectedItems()
        fetchDatasetGroups()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });

  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const data = loading ? [] : datasource

  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const SideDialogForm = editable ? action.Component : undefined

  return (
      <div className="columns narrow-margin">
        <MainColumn>
          <ObjectDetails
            type="Datasource"
            title={data.datasource_name}
            subtitleIcon={<Icon name={data.datasource_type_category} tooltip={ data.datasource_type_name } />}
            subtitleText={data && !loading && 
              formatConnectionString(
                      data.datasource_database, 
                      data.datasource_hostname, 
                      [], 
                      data.datasource_type_code, 
                      data.datasource_type_category)
              }
            loading={loading}
            >

              { !loading 
                ? (!data.datasource_description) 
                  ? <span className="no-result-text">No description available</span> 
                  : <TextareaReadOnly
                      value={ textareaInitialState({value: data.datasource_description, readOnly: true, descriptions: fromReferences }) } 
                    />
                : <SkeletonLoader width="10vw" count={2} />
              }

              <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
              <UserLink 
                userId={data.datasource_owner_user_id}
                userName={data.datasource_owner_user_fullname}
                userPhoto={data.user_photo}
                userTitle={data.user_title}
                userDepartment={data.user_department}
                loading={loading}
              />
            </ObjectDetails>

            { !loading && editable &&
              <div className="main-toolbar">
                <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDatasource', [data], editDatasource) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDatasource', [data], deleteDatasource) }><span>DELETE</span></button>
              </div>
            }

        </MainColumn>

      <div className="column">
        <Tabs className="slim left">
          <div label="Dataset Groups" tabId="dataset_groups" resultCount={loadingTable || datasetGroups.length === 0 ? undefined : datasetGroups.length}>

            <DataTable
              columns={[
                {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:dataset_group_owner_user_id', tooltip: 'dataset_group_owner_user_fullname'},
                {id: 'dataset_group_owner_user_fullname', name: 'Owner', link: '/users/:dataset_group_owner_user_id'}
              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDatasetGroup', items || selectedItems, addDatasetGroup) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDatasetGroup', items || selectedItems, editDatasetGroup) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDatasetGroup', items || selectedItems, deleteDatasetGroup) }
              ]}
              data={datasetGroups}
              idColumn='dataset_group_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editable}
              filterable={true}
              filterObjects={['dataset_group_name', 'dataset_group_owner_user_fullname']}
            />
          </div>
        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        >
          { SideDialogForm &&
          <SideDialogForm {...action} setModalDialog={setModalDialog} setMessage={setMessage}  />
          }
      </SideDialog>

    </div>
  ) 
}
