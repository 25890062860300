import React, { useState, useEffect } from 'react';
import { userGroupService } from '../_services';
import { DataTable, SideDialog } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState } from '../_hooks';
import { Role, authMethod } from '../_helpers';

export const UserGroups = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const [userGroups, setUserGroups] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems, setMessage)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    const promise = new Promise( (resolve,reject) => {
      userGroupService.getAll()
        .then(res => {
          setUserGroups([...res.user_groups]); 
          setLoading(false);
          resolve(res.user_groups)
        })
        .catch(err => {
          setLoading(false) 
          setMessage(JSON.stringify(err), 'error')
        })
    })

    Promise.all([promise, fetchMemberCount()])
      .then( result => {
        updateMemberCount(result[0], result[1])
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')})
  }

  const fetchMemberCount = async () => {
    return new Promise(  (resolve, reject) => {
      
      userGroupService.getMemberCount()
        .then(res => {
          resolve(res.member_count)

        })
        .catch(err => {
          console.log(err)
          return null
        });
    })
  }

  const updateMemberCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.member_count > maxValue) {
        maxValue = row.member_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.user_group_id === row.user_group_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        member_count: (statistic ? statistic.member_count : 0), 
        member_count_max: maxValue
      }
    })
    setUserGroups([...dataTmp])
  }

  const addUserGroup = async data => {
    const user_groups = {user_groups: (Array.isArray(data) ? data : [data])}
    
    await userGroupService.create(user_groups)
      .then(res => {

        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editUserGroup = async data => {
    const user_groups = {user_groups: (Array.isArray(data) ? data : [data])}
    
    await userGroupService.update(user_groups)
      .then(async res => { 

        fetchItems()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteUserGroup = async data => {    
    
    setModalDialog(prev => {return {...prev, loading: true}})    
    await userGroupService.destroy({ user_groups: data })
      .then(async res => { 
        cancelModalDialog()
        clearSelectedItems()
        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const SideDialogForm = editable ? action.Component : undefined

  const userGroupTypeValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 

  return (
    <div className="Users wide-margin">

        <DataTable
          columns={[
            {id: 'user_group_photo', name: '', type: 'group-photo', tooltip: 'user_group_name'},
            {id: 'user_group_name', name: 'Name', link: '/user_groups/:user_group_id', className:"bold"},
            {id: 'member_count', name: 'Members', type: 'chart', chartType: 'bar', data: {data:'member_count', max: 'member_count_max'}},         
            {id: 'user_group_default_role_name', name: 'Default Role'},  
            {id: 'user_group_type', name: 'Type', value:'user_group_type', values: userGroupTypeValues},
            ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addUserGroup', items || selectedItems, addUserGroup) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editUserGroup', items || selectedItems, editUserGroup) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteUserGroup', items || selectedItems, deleteUserGroup) }
          ]}
          data={userGroups}
          title="User Groups"
          idColumn='user_group_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['user_group_name', 'user_group_default_role_name', 'user_group_type']}
          filterObjectsValueMap={{user_group_type: userGroupTypeValues}}
        />

        <SideDialog 
          mode={action.mode}
          title={action.title}
          closeDialog={ action.cancelForm }
          > 
            { SideDialogForm &&
            <SideDialogForm {...action} />
            }
        </SideDialog>

      </div>
  );
}
