import React, { useState, useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize';
import { userRoleService, userGroupService } from '../_services';
import { Checkbox, formatSelectOptions, Input, SelectButtons  } from '../_components';
import { authMethod, getBatchEditValue, formatData } from '../_helpers'
import { useGlobalState, useDebounce } from '../_hooks'

export const AddUserGroupForm = ({ mode, data, submitForm, cancelForm, setMessage }) => { 

	const [settings] = useGlobalState('settings');

	const initialFormState = {
		user_group_type: settings.authMethods[0],
		user_group_id: null, 
		user_group_source_id: null,
		user_group_name: '',
		user_group_email: undefined,
		user_group_description: '',
		user_group_default_role_id: ''
	}

	const initialBatchEditItems = {
		batch_user_group_default_role_id: false
	}

	const [hasError, setErrors] = useState(false);
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loading, setLoading] = useState(false);
	const [loadingSearch, setLoadingSearch] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [searchTerm, setSearchTerm] = useState('');
	const [items, setItems] = useState([])
	const [selectedItems, setSelectedItems] = useState([])
	const [accessToken, setAccessToken] = useState()

	useEffect( () => {
		// Get User Roles
    	fetchUserRoles();

  	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				user_group_type: data[0].user_group_type,
				user_group_id: data[0].user_group_id, 
				user_group_name: data[0].user_group_name || '',
				user_group_email: data[0].user_group_email || undefined,
				user_group_description: data[0].user_group_description || '',
				user_group_default_role_id: data[0].user_group_default_role_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				user_group_default_role_id: getBatchEditValue(data, 'user_group_default_role_id')
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data]);

	const fetchSearchResults = useDebounce(async (search) => {
  	try {
  		//setLoadingSearch(true)
  		const userGroups = await userGroupService.getFromAD(search, accessToken)
  		
  		setAccessToken(userGroups?.access_token)

  		if (userGroups?.user_groups.length > 0) {
  			setItems(prev => {
					return userGroups.user_groups.map(x => {
						if(selectedItems.find(y => y.user_group_source_id === x.user_group_source_id)) {
							return {...x, selected: true}
						}
						return x
					})
				})
				setLoadingSearch(false)
			} else if(userGroups === undefined) {
				// Do nothing
				// userGroups is undefined if request was aborted
  		} else {
  			setItems(prev => {return []})
  			setLoadingSearch(false)
  		}
			
  	} catch(e) {
  		if (e.name !== "AbortError") {
				setLoadingSearch(false)
  		}
  		setMessage(e, "error")
  	}
  }, 200).debounce

	const fetchUserRoles = async () => {
    userRoleService.getAll()
      .then(res => { 

      	setUserRoles(res.user_roles); 

      })
      .catch(err => {setErrors(err)});
  }

  
	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(name) {
	  	case 'search_user_group':
	  		setSearchTerm(value)

	  		if (value.length > 1) {
	  			setLoadingSearch(true)
					fetchSearchResults(value)
				} else {
					setItems(prev => {return []})					
				}
	  		break
	  	default:
	  		break
	  }


	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	}

	const onSubmit = event => {

		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {user_group_type: item.user_group_type,
								user_group_id: item.user_group_id, 
								user_group_source_id: item.user_group_source_id,
								user_group_name: item.user_group_name,
								user_group_email: item.user_group_email,
								user_group_description: item.user_group_description,
								user_group_default_role_id: item.user_group_default_role_id,
								...tmp
							}
			})

		} else if (formData.user_group_type === 'oidc' && mode === 'add') {
			dataToSubmit = selectedItems.map( item => {
				return {user_group_type: item.user_group_type,
								user_group_id: item.user_group_id, 
								user_group_source_id: item.user_group_source_id,
								user_group_name: item.user_group_name,
								user_group_email: item.user_group_email,
								user_group_description: item.user_group_description,
								user_group_default_role_id: formData.user_group_default_role_id
							}
			})
		} else {
			dataToSubmit = formData
		}

		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})			    
		    
	}

	const selectItem = (item) => {
		if (!selectedItems.find(x => x.user_group_source_id === item.user_group_source_id)) {
			setSelectedItems(prev => {return [...prev, item]})
			
			setItems(prev => {
				return prev.map(x => {
					if(x.user_group_source_id === item.user_group_source_id) {
						return {...x, selected: true}
					}
					return x
				})
			})
		}
	}

	const removeItem = (item) => {
		setSelectedItems(prev => prev.filter(x => x.user_group_source_id !== item.user_group_source_id))
			
		setItems(prev => {
			return prev.map(x => {
				if(x.user_group_source_id === item.user_group_source_id) {
					return {...x, selected: false}
				}
				return x
			})
		})
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {

  	setBatchEdit(false)
  	setItems([])
  	setSelectedItems([])
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState 
						}
		}) 
  }

  const userTypes = settings.authMethods.map(x => {
    const method = Object.entries(authMethod).find(([key, value]) => x === key)
    if (method) {
    	const [key, value] = method
      return {value:key, label: value}
    } 
  })

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>
				{ !batchEdit && userTypes.length > 1 &&
					<div className="form-block vertical">
						<label>User Group Type</label>
						<SelectButtons 
			        name="user_group_type"
			        value={formData.user_group_type}
			        options={ formatSelectOptions({options: userTypes, optionValue: "value", optionLabel: "label"  }) }
			        onChange={handleInputChange} 
			        disabled={loading || mode !== 'add'}
			      />

		      </div>
		    }
		    { formData.user_group_type === "oidc" && mode === 'add' && 
		    	<React.Fragment>
			    	<div className="form-block vertical side-dialog-search-input">
				      <Input type="text" 
				      	name="search_user_group" 
				      	label="Search User Group"
				      	value={searchTerm} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      	isNullable={true}
				     	/>
				     	{ loadingSearch && 
				     		<span className="side-dialog-search-loading-indicator">Searching...</span>
				     	}
				     	{ !loadingSearch && searchTerm.length > 1 && 
				     		<span className="side-dialog-search-result-count">{items.length} user groups found</span>
				     	}
			     	</div>

			     	{ items.length > 0 && 
			     	<div className="form-block vertical">
				      {
				      	items.map((item, index) => {
				      		return (
				      			<div className={"side-dialog-search-result" + (item?.selected ? " selected" : "")} key={index} onClick={() => !item?.selected && selectItem(item)} title={`${item.user_group_name}\n${item.user_group_description ? item.user_group_description : ""}`}>
				      				<div className="side-dialog-search-result-photo">
				      					{ formatData((item.user_group_photo ? item.user_group_photo : null), 'group-photo') }
				      				</div>	
					      			<div className="side-dialog-search-result-body">
					      				<div className="side-dialog-search-result-title">{item.user_group_name}</div>
					      			</div>
					      			{item?.selected &&
					      				<div className="side-dialog-search-result-selected">selected</div>
					      			}
					      		</div>
					      	)
				      	})
				      }
			     	</div>
			     	}
			     	{ selectedItems.length > 0 && 
				     	<div className="form-block vertical">
				     	<label>{selectedItems.length } User Groups to add</label>
					      {
					      	selectedItems.map((item, index) => {
					      		return (
					      			<div className="side-dialog-search-result" key={index} onClick={() => removeItem(item)} title={`${item.user_group_name}\n${item.user_group_description ? item.user_group_description : ""}`}>
					      				<div className="side-dialog-search-result-photo">
					      					{ formatData((item.user_group_photo ? item.user_group_photo : null), 'group-photo') }
					      				</div>		
						      			<div className="side-dialog-search-result-body">
						      				<div className="side-dialog-search-result-title">{item.user_group_name}</div>
						      			</div>
						      		</div>
						      	)
					      	})
					      }
				     	</div>
						}
			    </React.Fragment>
		  	}

		  	
				{ !batchEdit && (formData.user_group_type !== "oidc" || mode === 'edit') &&
		      <div className="form-block vertical">
			      <Input type="text" 
			      	name="user_group_name" 
			      	label="Name"
			      	value={formData.user_group_name} 
			      	onChange={handleInputChange}
			      	autoComplete='new-password'
			      	disabled={loading || formData.user_group_type === 'oidc'}
			     	/>
		     	</div>
		    }

			  { formData.user_group_type === "local" &&
		  		<React.Fragment>
		  		{ !batchEdit &&
						<div className="form-block vertical">
							<label>Description</label>
							<TextareaAutosize
				      	name="user_group_description" 
				      	value={formData.user_group_description} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
					}
					{ !batchEdit &&
			     	<div className="form-block vertical">
				      <Input type="email" 
				      	name="user_group_email" 
				      	label="Email"
				      	value={formData.user_group_email} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
					}
					</React.Fragment>
				}		

					<div className="form-block vertical">
						{ batchEdit 
							? <label>
									<Checkbox 
										value={batchEditItems.batch_user_group_default_role_id}
										name="batch_user_group_default_role_id"
										label="Edit Default User Role"
										onChange={ e => handleInputChange(e, 'batch')}
										disabled={loading}
									/>
								</label>
							: <label>Default User Role</label>
						}
						<SelectButtons 
		          name="user_group_default_role_id"
		          value={formData.user_group_default_role_id}
		          options={ formatSelectOptions({options: userRoles, optionValue: "user_role_id", optionLabel: "user_role_name"  }) }
		          onChange={handleInputChange} 
		          disabled={loading || (batchEdit && !batchEditItems.batch_user_group_default_role_id)}
		        />
		      </div>

	      <div className="form-block horizontal">
					<button 
						type="submit" 
						className={"button main-button" + (loading ? ' loading' : '')} 
	          disabled={loading}>
	          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
	        </button>
					<button 
						type="button" 
						className="button" 
						onClick={cancel}
						disabled={loading}>
						<span>CANCEL</span>
					</button>
				</div>
	    </form>
	  </div>
  )
}
