import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasetService, fieldService, referenceService, relationshipService } from '../_services';
import { copyToClipboard, FieldSensitivity, formatConnectionString, handleError, Role, Asset } from '../_helpers';
import { 
  Button,
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  References, 
  RelationshipGraph,
  SideDialog,  
  SkeletonLoader, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  fetchToReferencesCount,
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState, usePhotos } from '../_hooks';

export const Dataset = ({ setMessage, setModalDialog, cancelModalDialog, abortFetch }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [dataset, setDataset] = useState([]);
  const [fields, setFields] = useState([]);
  const [relationships, setRelationships] = useState(null);
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingRelationships, setLoadingRelationships] = useState(true);

  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const { system_id, datasource_id, dataset_group_id, dataset_id } = useParams()

  const navigate = useNavigate()

  const refTechnicalDefinition = useRef()

  useEffect(() => {

    // Necessary if one navigates from one dataset to another
    setDefaultSelectedTab({tab: queryParams.tab})

    fetchItems()
    fetchFields()
    fetchFromReferences()  

    fetchToReferencesCount({setMessage, toObjectName: Asset.Dataset, toObjectId: dataset_id, abortFetch})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        handleError({err, setMessage})
      })

  }, [dataset_id]);

  const fetchItems = async () => {
    setLoading(true)
    datasetService.getById(dataset_id, abortFetch)
      .then(res => {

        setDataset(res.datasets[0]);
        setLoading(false)
        fetchPhotos(res.datasets[0], 'dataset_owner_user_id', setDataset)
      })
      .catch(err => {
        handleError({err, setMessage})
      })
  }

  const fetchFields = async () => {
    setLoadingTable(true)
    const promises = [
      fieldService.getByDatasetId(dataset_id, abortFetch)
        .catch(err => {
          throw err
        })
      ,
      relationshipService.getByObjectId(Asset.Dataset, dataset_id, abortFetch)
        .catch(err => {
          throw err
        })
      ,
      relationshipService.getRelatedDatasets(dataset_id, abortFetch)
        .catch(err => {
          throw err
        })
    ]

    Promise.all(promises)
      .then( result => {

          const fields = result[0].fields.map(x => {return {...x, pii: (x.is_pii ? 'PII' : null), pii_tooltip: (x.is_pii ? 'Classified as Personally Identifiable Information (PII)' : null) }})
          setFields(fields);
          setRelationships({nodes: result[2].datasets, edges: result[1].relationships})
          updateFieldRelationships(fields, result[1].relationships)
          setLoadingTable(false)
          setLoadingRelationships(false)
          fetchPhotos(fields, 'field_description_owner_user_id', setFields)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setLoadingTable(false)
          setLoadingRelationships(false)
        })
      })
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.Dataset, dataset_id, abortFetch)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {
        handleError({err, setMessage})
      })
  }

  const updateFieldRelationships = (fields, data) => {
    const newFields = fields.map(field => {
      let keyType = null
      if (field.is_primary_key || data.find(x => x.to_field_id === field.field_id && x.relationship_type !== 'virtual')) {
        keyType = 'Primary Key'
      } else if (data.find(x => x.from_field_id === field.field_id || x.to_field_id === field.field_id)) {
        keyType = 'Foreign Key'
      }
      return {...field, key_type:keyType}
    })

    newFields.sort((a, b) => {
        if (a.key_type === 'Primary Key' && b.key_type === 'Foreign Key') {
          return -1;
        }
        if (b.key_type === 'Primary Key' && a.key_type === 'Foreign Key') {
          return 1;
        }
        if (a.key_type === null && b.key_type === null) {
          return 0;
        }
        if (a.key_type === null) {
          return 1;
        }
        if (b.key_type === null) {
          return -1;
        }
        return 0;
      })

    setFields(newFields)
  }

  const addField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.create(fields)
      .then(res => {

        fetchFields()
        setMessage(res.message)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      })
  }

  const editDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(datasets)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        setMessage(res.message)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      })
  }

  const editField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.update(fields)
      .then(async res => { 

        fetchFields()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      })
  }

  const deleteDataset = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetService.destroy({datasets: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}/${datasource_id}/${dataset_group_id}`)

      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setModalDialog(prev => {return {...prev, loading: false}})
        })
      })
  }

  const deleteField = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})     
    await fieldService.destroy({ fields: data })
      .then(async res => { 

        fetchFields()
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()

      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setModalDialog(prev => {return {...prev, loading: false}})
        })
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }

  const data = loading ? [] : dataset

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  const SideDialogForm = editable ? action.Component : undefined

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Dataset"
          title={data.dataset_name}
          subtitleIcon={<Icon name={data.dataset_type_name} tooltip={ data.dataset_type_name } />}
          subtitleText={data && !loading && 
            formatConnectionString(
              data.datasource_database, 
              data.datasource_hostname, 
              [data.dataset_group_source_name, data.dataset_source_name], 
              data.datasource_type_code, 
              data.datasource_type_category)
            }
          loading={loading}
          >

            { !loading 
              ? (!data.dataset_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.dataset_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.dataset_owner_user_id}
              userName={data.dataset_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />

        </ObjectDetails>
          
        { !loading && editable &&
        <div className="main-toolbar">
          <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDataset', [data], editDataset) }><span>EDIT</span></button>
          <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDataset', [data], deleteDataset) }><span>DELETE</span></button>
        </div>
        }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          onTabChange={ onTabChange } 
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults={ true }
        >
          <div label="Fields" tabId="fields" resultCount={loadingTable || fields.length === 0 ? undefined : fields.length}>
            <DataTable
              columns={[
                {id: 'key_type', name: '', type: 'icon', tooltip: 'key_type', className: 'no-right-margin'},
                {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name', className: 'no-right-margin'},
                {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_name'},
                {id: 'field_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', tooltip: 'field_description_description', className:"bold"},
                {id: 'field_description_name', name: 'Field Description Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description'},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:field_description_owner_user_id', tooltip: 'field_description_owner_user_fullname'},                
                {id: 'field_description_owner_user_fullname', name: 'Field Description Owner', link: '/users/:field_description_owner_user_id'},
                {id: 'pii', name: '', type: 'icon', tooltip: 'pii_tooltip', className: 'no-right-margin'},
                {id: 'field_sensitivity_level', name: 'Data Sensitivity', type: 'pill', value: 'field_sensitivity_level', values: FieldSensitivity, tooltip: 'field_sensitivity_name'}
              ]}
              buttons={[ 
                  {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addField', items || selectedItems, addField) },
                  {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editField', items || selectedItems, editField) },
                  {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteField', items || selectedItems, deleteField) }
                ]}
              data={fields}
              idColumn='field_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              editable={editable}
              loading={loadingTable}
              filterable={true}
              filterObjects={['field_name', 'key_type', 'field_role_name', 'datatype_name', 'field_description_owner_user_fullname', 'field_sensitivity_name', 'pii']}

            />
          </div>

          <div label="Relationships" tabId="relationships">

            <RelationshipGraph data={relationships} loading={loadingRelationships} setLoading={setLoadingRelationships} filterObjects={['label']} />
      
          </div>

          <div label="References" tabId="references" resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              setMessage={setMessage}
              toObjectName={Asset.Dataset}
              toObjectId={dataset_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

          <div label="Technical Definition" tabId="technical_definition" disabled={!data.dataset_technical_definition}>
            <div className="main-toolbar align-right">
              <Button type="button" className="main-toolbar-item button" onClick={ () => copyToClipboard(refTechnicalDefinition) } tooltip="Copy query to clipboard" value="COPY"/>
            </div>
            <div className="display-linebreak code" ref={refTechnicalDefinition}>{ data.dataset_technical_definition }</div>
          
          </div>
          
        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} />
          }
      </SideDialog>

    </div>
  ) 
}

