import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Checkbox } from '../../';
import { useTooltip } from '../../../_hooks';
import { formatData, formatTooltip, generateURL } from '../../../_helpers'


export const DataTableRow = ({
  item, 
  itemCount, 
  idColumn, 
  columns, 
  selected=false, 
  editable, 
  handleSelect, 
  actionEdit, 
  actionDelete, 
  actionRemove,
  actionRun,
  onMouseEnterRow, 
  onMouseLeaveRow
}) => {

  const {showTooltip, hideTooltip} = useTooltip()

  const location = useLocation()

  const runIsDisabled = (actionRun && actionRun.disabled && item[actionRun.disabled.field] === actionRun.disabled.value)

  const editItem = () => {
    actionEdit([item])
  }

  const deleteItem = () => {
    actionDelete([item])
  }

  const removeItem = () => {
    actionRemove([item])
  }

  const runItem = () => {
    !runIsDisabled && actionRun.onClick && actionRun.onClick([item])
  }

  const handleMouseEnter = (e, id) => {
    e.preventDefault()
    onMouseEnterRow(e, id)
  }
  const handleMouseLeave = (e, id) => {
    e.preventDefault()
    onMouseLeaveRow(e, id)
  }

  return useMemo( () => (
    <React.Fragment>
        { 
          <tr 
            key={item[idColumn]} 
            className={"row" + ((editable && selected) ? ' selected' : '') }
            onMouseEnter={e => onMouseEnterRow && handleMouseEnter(e, item[idColumn])}
            onMouseLeave={e => onMouseLeaveRow && handleMouseLeave(e, item[idColumn])}
          >
            { editable &&
              <td className="center checkbox">
              { itemCount > 0 && 
                <Checkbox
                  name={`selected-${item[idColumn]}`}
                  onChange={handleSelect}
                  checked={ selected }
                />
              }
              </td>
            }
            {columns.map((column, index) => {

                return (
                  <React.Fragment key={index}>
                  { column.type === 'chart' &&
                    <td className={'right' + ((column.className === undefined) ? '' : ' '+ column.className) + ((column.type === 'chart') ? ' small-text' : '') }>
                      {column.link !== undefined
                        ? <Link 
                            className="link" 
                            to={generateURL(column.link, item, )} 
                            state={{from: location.pathname}}
                            onMouseEnter={ () => showTooltip({
                              ...formatTooltip({
                                title: item[column.id],
                                type: column.chartType === 'percent-bar' ? 'percent' : 'integer', 
                                tooltip: item[column.tooltip],
                                value: item[column.value],
                                values: column.values,
                                label: column.label
                              })
                            }) } 
                            onMouseLeave={ hideTooltip }>
                          { formatData(
                              item[column.id], 
                              column.type
                            ) 
                          }
                          </Link> 
                        : <React.Fragment>{ item[column.id] == null
                                            ? <span>-</span> 
                                            : <span
                                                onMouseEnter={ () => showTooltip({
                                                  ...formatTooltip({
                                                    title: item[column.id],
                                                    type: column.chartType === 'percent-bar' ? 'percent' : 'integer', 
                                                    tooltip: item[column.tooltip],
                                                    value: item[column.value],
                                                    values: column.values,
                                                    label: column.label
                                                  })
                                                }) } 
                                                onMouseLeave={ hideTooltip }>
                                              { formatData(
                                                  item[column.id], 
                                                  (column.chartType === 'percent-bar' ? 'percent' : 'integer'), 
                                                  undefined,
                                                  undefined,
                                                  undefined, 
                                                  undefined
                                                ) 
                                              }
                                              </span>
                                          }
                          </React.Fragment>
                      }

                    </td>
                  }

                  <td className={
                      ((column.align === undefined) ? ' left' : ' ' + column.align) + 
                      ((column.className === undefined) ? '' : ' '+ column.className) + 
                      ((column.type === 'chart') ? ' chart' : '') +
                      ((column.type === 'icon') ? ' type-icon' : '') +
                      ((column.type === 'button') ? ' type-button' : '') +
                      ((column.type === 'boolean' || column.type === 'pill') ? ' type-boolean' : '') +
                      ((column.type === 'user-photo' || column.type === 'group-photo') ? ' type-photo' : '')
                    } 
                  >

                    {column.link !== undefined
                      ? <Link 
                          className="link" 
                          to={generateURL(column.link, item, )} 
                          state={{from: location.pathname}}
                          onMouseEnter={ () => showTooltip({
                            ...formatTooltip({
                              title: item[column.id],
                              type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                              tooltip: item[column.tooltip],
                              value: item[column.value],
                              values: column.values,
                              label: column.label
                            })
                          }) } 
                          onMouseLeave={ hideTooltip }>
                          { formatData(
                              item[column.id], 
                              column.type
                            ) 
                          }
                        </Link> 
                      : <React.Fragment>
                          {column.email !== undefined
                            ? <a 
                                className="link" 
                                href={"mailto:"+item[column.email]}
                                onMouseEnter={ () => showTooltip({
                                  ...formatTooltip({
                                    title: item[column.id],
                                    type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                                    tooltip: item[column.tooltip],
                                    value: item[column.value],
                                    values: column.values,
                                    label: column.label
                                  })
                                }) } 
                                onMouseLeave={ hideTooltip }>
                                { formatData(
                                    item[column.id], 
                                    column.type
                                  ) 
                                }
                              </a>
                            : <span 
                                onMouseEnter={ () => showTooltip({
                                  ...formatTooltip({
                                    title: item[column.id],
                                    type: (column.type === 'chart' ? (column.chartType === 'percent-bar' ? 'percent' : 'integer') : column.type),
                                    tooltip: item[column.tooltip],
                                    value: item[column.value],
                                    values: column.values,
                                    label: column.label
                                  })
                                }) } 
                                onMouseLeave={ hideTooltip }>
                                { formatData(
                                    item[column.id], 
                                    column.type, 
                                    item[column.value],
                                    column.values,
                                    column.chartType, 
                                    (column.data ? {data: item[column.data.data], max: item[column.data.max]} : undefined),
                                    column.label,
                                    column.onClick
                                  ) 
                                }
                              </span>
                          }
                        </React.Fragment>
                    }

                  </td>

                </React.Fragment>
                )

            })}
            { editable && 
              <td className="last-column">
              { (actionEdit || actionDelete || actionRemove || actionRun) &&
                <div className="buttons">
                  { actionRun && 
                    <div name="run" className={"icon-run" + (runIsDisabled ? " disabled" : "")} onMouseEnter={ () => showTooltip({title: (runIsDisabled ? "Already running" : "Run")})} onMouseLeave={ hideTooltip } onClick={ () => runItem() }></div>
                  }
                  { actionEdit && 
                    <div name="edit" className="icon-edit" onMouseEnter={ () => showTooltip({title: "Edit"})} onMouseLeave={ hideTooltip } onClick={ () => editItem() }></div>
                  }
                  { actionDelete && 
                    <div name="delete" className="icon-delete" onMouseEnter={ () => showTooltip({title: "Delete"})} onMouseLeave={ hideTooltip }  onClick={ () => deleteItem() }></div>
                  }
                  { actionRemove && 
                    <div name="remove" className="icon-remove" onMouseEnter={ () => showTooltip({title: "Remove"})} onMouseLeave={ hideTooltip } onClick={ () => removeItem() }></div>
                  }
                </div>
              }
              </td>
            }
          </tr>
        }
    </React.Fragment>
    ) , [item, selected, onMouseEnterRow, onMouseLeaveRow]
  );
};