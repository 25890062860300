import React, { useState, useEffect } from 'react';
import { userService } from '../_services';
import { DataTable, SideDialog } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState } from '../_hooks';
import { Role, authMethod } from '../_helpers';

export const Users = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const [users, setUsers] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems, setMessage)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)

  useEffect(() => {

    fetchItems();

  }, []);

  const fetchItems = async () => {
    const promise = new Promise( (resolve,reject) => {
      userService.getAll()
        .then(res => {
          setUsers([...res.users]); 
          setLoading(false);
          resolve(res.users)
        })
        .catch(err => {
          setLoading(false) 
          setMessage(JSON.stringify(err), 'error')
        })
    })

    Promise.all([promise, fetchOwnedItemsCount()])
      .then( result => {
        updateOwnedItemsCount(result[0], result[1])
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')})
  }

  const fetchOwnedItemsCount = async () => {
    return new Promise(  (resolve, reject) => {
      
      userService.getOwnedItemsTotalCount()
        .then(res => {
          resolve(res.owned_items_count)

        })
        .catch(err => {
          console.log(err)
          return null
        });
    })
  }

  const updateOwnedItemsCount = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.owned_items_count > maxValue) {
        maxValue = row.owned_items_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.user_id === row.user_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        owned_items_count: (statistic ? statistic.owned_items_count : 0), 
        owned_items_count_max: maxValue,
        field_description_ratio: (statistic ? statistic.field_description_ratio : null),
      }
    })
    setUsers([...dataTmp])
  }

  const addUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}
    
    await userService.create(users)
      .then(res => {

        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editUser = async data => {
    const users = {users: (Array.isArray(data) ? data : [data])}
    
    await userService.update(users)
      .then(async res => { 

        fetchItems()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteUser = async data => {    
    
    setModalDialog(prev => {return {...prev, loading: true}})    
    await userService.destroy({ users: data })
      .then(async res => { 
        cancelModalDialog()
        clearSelectedItems()
        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const SideDialogForm = editable ? action.Component : undefined

  const userAuthMethodValues = Object.entries(authMethod).reduce((a, [key, value]) => ({ ...a, [key]: {label: value}}), {}) 
  const userIsDisabledValues = {true: {label: 'Disabled', className: 'pill pill-red'}, false: {label: 'Active', className: 'pill'}}

  return (
    <div className="Users wide-margin">

        <DataTable
          columns={[
            {id: 'user_photo', name: '', type: 'user-photo', tooltip: 'user_fullname'},
            {id: 'user_fullname', name: 'Name', link: '/users/:user_id', className:"bold"},
            {id: 'user_username', name: 'Username'},  
            {id: 'user_role_name', name: 'Role'},
            {id: 'owned_items_count', name: 'Owned Items', type: 'chart', chartType: 'bar', data: {data:'owned_items_count', max: 'owned_items_count_max'}},
            {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}},
            {id: 'user_last_login_timestamp', name: 'Last Login', type: 'datetime'},
            {id: 'user_auth_method', name: 'Type', value:'user_auth_method', values: userAuthMethodValues},
            {id: 'user_is_disabled', name: 'Status', type: 'boolean', values: userIsDisabledValues}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addUser', items || selectedItems, addUser) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editUser', items || selectedItems, editUser) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteUser', items || selectedItems, deleteUser) }
          ]}
          data={users}
          title="Users"
          idColumn='user_id'
          primaryColumn='user_fullname'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['user_fullname', 'user_username','user_email', 'user_role_name', 'user_auth_method', 'user_is_disabled']}
          filterObjectsValueMap={{user_auth_method: userAuthMethodValues, user_is_disabled: userIsDisabledValues}}
        />

        <SideDialog 
          mode={action.mode}
          title={action.title}
          closeDialog={ action.cancelForm }
          > 
            { SideDialogForm &&
            <SideDialogForm {...action} />
            }
        </SideDialog>

      </div>
  );
}
