import React, { useState, useEffect } from 'react';
import { referenceService } from '../_services';
import { Asset, AssetName } from '../_helpers';
import { DataTable, Tabs } from '../_components';
import { useQueryParams } from '../_hooks';

const objectSortOrder = ["field_description", "business_term", "glossary", "dataset", "dataset_group", "datasource", "system"];

const getDefaultObject = (toReferencesCount) => {
    
  let defaultObject

  toReferencesCount && Object.entries(toReferencesCount).forEach( (object) => {
    
    const objectName = object[0]
    const objectCount = object[1]

    if (objectCount > 0 && defaultObject === undefined && objectName !== 'total' ) {
      defaultObject = objectName
    }
  })

  return defaultObject

}

export const fetchToReferencesCount = async ({setMessage, toObjectName, toObjectId, abortFetch}) => {

    return referenceService.getToReferencesCount(toObjectName, toObjectId, abortFetch)
      .then(res => {

        let tmp = {total: 0}

        res.to_references_count.sort((a, b) => objectSortOrder.indexOf(a.from_object_name) - objectSortOrder.indexOf(b.from_object_name));

        res.to_references_count.forEach(result => {
          const object_count = parseInt(result.to_references_count)
          tmp = {...tmp, [result.from_object_name]: object_count, total: tmp.total + object_count}
        })

        return tmp

      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

export const fetchToReferencesCountPerChild = async ({setMessage, toObjectName, toParentObjectName, toParentObjectId, abortFetch}) => {

    return referenceService.getToReferencesCountPerChild(toObjectName, toParentObjectName, toParentObjectId, abortFetch)
      .then(res => {

        //let tmp = {total: 0}

        //res.to_references_count.sort((a, b) => objectSortOrder.indexOf(a.from_object_name) - objectSortOrder.indexOf(b.from_object_name));

        //res.to_references_count.forEach(result => {
        //  const object_count = parseInt(result.to_references_count)
        //  tmp = {...tmp, [result.from_object_name]: object_count, total: tmp.total + object_count}
        //})

        //return tmp
        return res.to_references_count

      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }


export const References = ({ setMessage, toObjectName, toObjectId, toReferencesCount }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [toReferences, setToReferences] = useState([]);
  const [loadingReferences, setLoadingReferences] = useState(true);

  const [defaultSelectedObject, setDefaultSelectedObject] = useState({tab: queryParams.object || getDefaultObject(toReferencesCount)})

  useEffect(() => {

    if(defaultSelectedObject.tab) {
      fetchToReferences(defaultSelectedObject.tab)
    }

  }, [defaultSelectedObject]);

  useEffect(() => {

    setDefaultSelectedObject({tab: queryParams.object || getDefaultObject(toReferencesCount)})

  }, [toReferencesCount]);

  const fetchToReferences = async (objectName) => {
    if (objectName) { // objectName is undefined if dataset does not have any references
      
      referenceService.getByToId(toObjectName, toObjectId, objectName)
        .then(res => {

          setToReferences(res.references)
          setLoadingReferences(false)
           
        })
        .catch(err => {setMessage(JSON.stringify(err), 'error')});
    } else {
      setLoadingReferences(false) // Needed to show "no result" message
    }
  }

  const onObjectChange = (object) => {
    setHistory({'object': object})
    fetchToReferences(object)
  }
  
	return (
		<React.Fragment>
			{ toReferencesCount && toReferencesCount.total > 0 ?
        <Tabs className="buttons left" 
              onTabChange={ onObjectChange } 
              showResultCount={false} 
              disableTabsWithoutResults={true}
              defaultSelectedTab={defaultSelectedObject}
              >

          <div label="Field Descriptions" tabId={Asset.FieldDescription} resultCount={ (toReferencesCount && toReferencesCount.field_description) || 0 }>
            <DataTable
              columns={[
                {id: 'field_role_name', name: '', type: 'icon', tooltip: 'field_role_name'},
                {id: 'field_description_name', name: 'Name', link: '/browse_field_descriptions/:field_description_id', tooltip: 'field_description_description', className:"bold"},
                {id: 'field_unit_code', name: 'Field Unit', tooltip: 'field_unit_name'},
                {id: 'field_description_owner_user_fullname', name: 'Owner', link: '/users/:field_description_owner_user_id'},
                {id: 'modified_timestamp', name: 'Modified', type:'datetime'} 
              ]}
              data={toReferences}
              idColumn='field_description_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['field_description_name']}
            />
          </div>

          <div label="Business Terms" tabId={Asset.BusinessTerm} resultCount={ (toReferencesCount && toReferencesCount.business_term) || 0 }>
            <DataTable
              columns={[
                {id: 'business_term_name', name: 'Name', link: '/browse_business_terms/:glossary_id/:business_term_id', tooltip: 'business_term_description', className:"bold"},
                {id: 'glossary_name', name: 'Glossary', link: '/browse_business_terms/:glossary_id'},
                {id: 'business_term_owner_user_fullname', name: 'Owner', link: '/users/:business_term_owner_user_id'},
                {id: 'modified_timestamp', name: 'Modified', type:'datetime'}      
              ]}
              data={toReferences}
              idColumn='business_term_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['business_term_name']}
            />
          </div>

          <div label="Glossaries" tabId={Asset.Glossary} resultCount={ (toReferencesCount && toReferencesCount.glossary) || 0 }>
            <DataTable
              columns={[
                {id: 'glossary_name', name: 'Name', link: '/browse_business_terms/:glossary_id', tooltip: 'glossary_description', className:"bold"},
                {id: 'glossary_owner_user_fullname', name: 'Owner', link: '/users/:glossary_owner_user_id'},
                {id: 'modified_timestamp', name: 'Modified', type:'datetime'}   
              ]}
              data={toReferences}
              idColumn='glossary_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['glossary_name']}
            />
          </div>

          <div label="Datasets" tabId={Asset.Dataset} resultCount={ (toReferencesCount && toReferencesCount.dataset) || 0 }>
            <DataTable
              columns={[
                {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id' },
                {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'},
                {id: 'dataset_owner_user_fullname', name: 'Owner', link: '/users/:dataset_owner_user_id'}
              ]}
              data={toReferences}
              idColumn='dataset_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['dataset_name', 'dataset_source_name']}
            />
          </div>

          <div label="Dataset Groups" tabId={Asset.DatasetGroup} resultCount={ (toReferencesCount && toReferencesCount.dataset_group) || 0 }>
            <DataTable
              columns={[
                {id: 'dataset_group_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id', tooltip: 'dataset_group_description', className:"bold"},
                {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'},
                {id: 'dataset_group_owner_user_fullname', name: 'Owner', link: '/users/:dataset_group_owner_user_id'}     
              ]}
              data={toReferences}
              idColumn='dataset_group_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['dataset_group_name', 'dataset_group_source_name']}
            />
          </div>

          <div label="Datasources" tabId={Asset.Datasource} resultCount={ (toReferencesCount && toReferencesCount.datasource) || 0 }>
            <DataTable
              columns={[
                {id: 'datasource_type_category', name: '', type: 'icon', tooltip: 'datasource_type_name'},
                {id: 'datasource_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id', tooltip: 'datasource_description', className:"bold"},
                {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'},
                {id: 'datasource_owner_user_fullname', name: 'Owner', link: '/users/:datasource_owner_user_id'}   
              ]}
              data={toReferences}
              idColumn='datasource_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['datasource_name', 'datasource_hostname', 'datasource_database']}
            />
          </div>

          <div label="Systems" tabId={Asset.System} resultCount={ (toReferencesCount && toReferencesCount.system) || 0 }>
            <DataTable
              columns={[
                {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"},
                {id: 'system_owner_user_fullname', name: 'Owner', link: '/users/:system_owner_user_id'}   
              ]}
              data={toReferences}
              idColumn='system_id'
              loading={loadingReferences}
              editable={false}
              filterable={true}
              filterObjects={['system_name']}
            />
          </div>

        </Tabs>
      : !loadingReferences &&
          <div className="no-result-text">{ `No references to this ${AssetName[toObjectName]}` }</div>
    	}	

  	</React.Fragment> 
    )
}