import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Spinner} from '../assets/img/logo_loading.svg';

import { useGlobalState } from '../_hooks'

export const LoadApp = ({fullscreenError}) => {

  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [isLoggedIn, setIsLoggedIn] = useGlobalState('isLoggedIn')

  const navigate = useNavigate()

  useEffect(() => {
    
    // Redirect to start page if user is already logged in
    if (isLoggedIn && loggedInUser && !loggedInUser.password_change_required) { 
      navigate('/')
    }    
  }, []);

  const isError = ((fullscreenError.isError) ? "show" : "")

  return (
    <div className="Login fullscreen">
      <div className="fullscreen-content">

        <Spinner className="fullscreen-loading-logo" />

        {fullscreenError.isError
          ? <div className={"error-msg " + isError }>
              <div className="error-title">{ fullscreenError.errorTitle }</div>
              
              <div className="error-text">{ fullscreenError.errorText }</div>
            </div>

          : <div className="fullscreen-loading-text">Loading Katalogue...</div>
        }

      </div>
    </div>
  )
}