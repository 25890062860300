import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../_helpers';

const endpointPath='dataset'

export const datasetService = {
    getAll,
    getById,
    getByDatasetGroupId,
    getFieldStatistics,
    create,
    update,
    checkDestroy,
    destroy
};


function getAll(signal) {
   
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentDataset.next(res.datasets[0])
        subjects.currentDatasetGroup.next({
            dataset_group_id: res.datasets[0].dataset_group_id,
            dataset_group_name: res.datasets[0].dataset_group_name,
            datasource_hostname: res.datasets[0].datasource_hostname,
            datasource_database: res.datasets[0].datasource_database,
            datasource_type_name: res.datasets[0].datasource_type_name,
            datasource_type_code: res.datasets[0].datasource_type_code,
            datasource_type_category: res.datasets[0].datasource_type_category
        })
        subjects.currentDatasource.next({
            datasource_id: res.datasets[0].datasource_id,
            datasource_name: res.datasets[0].datasource_name,
            datasource_hostname: res.datasets[0].datasource_hostname,
            datasource_database: res.datasets[0].datasource_database,
            datasource_type_name: res.datasets[0].datasource_type_name,
            datasource_type_code: res.datasets[0].datasource_type_code,
            datasource_type_category: res.datasets[0].datasource_type_category
        })
        subjects.currentSystem.next({
            system_id: res.datasets[0].system_id,
            system_name: res.datasets[0].system_name
        })
        return res
    });
}

function getByDatasetGroupId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/dataset_group/${id}`, requestOptions, signal).then(handleResponse);
}

function getFieldStatistics(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/field_statistics/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}