import React from 'react';

export const SideDialog = ({mode, title, closeDialog, children}) => {

return (
	<div className={"SideDialog " + ((mode !== '') ? 'show' : '') }>
		<div className={"side-dialog-container"}>
      <div className="side-dialog-header">
        <h2>{ title }</h2>
        <div className="close-button" onClick={ closeDialog }></div>
      </div>
      <div className="side-dialog-content">
        { children }
      </div>
    </div>
  </div>
)}