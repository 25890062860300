import config from '../config';
import { customFetch, authHeader, jsonHeader, handleResponse, commonRequestOptions } from '../_helpers';

const endpointPath='setting'

export const settingService = {
    getAll,
    getAllForFrontend,
    update,
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getAllForFrontend(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all_for_frontend`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        },  
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}
