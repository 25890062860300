import React, { forwardRef } from 'react';
import { keyCode } from '../_helpers';
import { useTooltip } from '../_hooks'

const _Slider = ({name, onChange, min, max, value, step, disabled=false, className, orientation, tooltip}, ref) => {
	
  const {showTooltip, hideTooltip} = useTooltip()

  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case keyCode.ENTER:
      case keyCode.SPACE:
        onChange();
        break;

      default:
        break;
    }
  }

	return (
		<div className={"CustomSlider" + (className ? " " + className : "" ) + (disabled ? " disabled" : "" ) + (orientation ? " " + orientation : "" ) }
		 tabIndex={ 0 }
		 onKeyDown={handleKeyDown}
		 ref={ref}
		 onMouseEnter={ () => tooltip && showTooltip({title: tooltip}) } 
     onMouseLeave={ () => tooltip && hideTooltip() } 
		>		
			<div className="max-label">{"+"}</div>
			<div className="input-container">
			  <input
			    type="range"
			    name={ name }
			    value={value}
			    min={min} 
			    max={max} 
			    step={step}
			    onChange={ onChange }
			    disabled={ disabled }    
			    //orient={orientation}
			  />
			 </div>
		  <div className="min-label">{"-"}</div>
		</div>
	)
}

export const Slider = forwardRef(_Slider);