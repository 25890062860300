import React from 'react';

import { 
  EditorState, 
  RichUtils, 
  Modifier 
} from 'draft-js'

import { FormatMenuButton } from './'
import { Icon } from '../../'
import { 
  SUGGESTION_TRIGGER,
  getCurrentTextSelection
} from '../_helpers'

export const FormatMenu = ({onChange, editorState}) => {
  
  const onBoldClick = () => {
    //e.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
  }

  const onItalicClick = () => {
    //e.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
  }

  const onUnderlineClick = () => {
    //e.preventDefault()
    onChange(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
  }

  const onOrderedListClick = () => {
    //e.preventDefault()
    onChange(RichUtils.toggleBlockType(editorState, 'ordered-list-item'))
  }

  const onUnOrderedListClick = () => {
    //e.preventDefault()
    onChange(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
  }

  const onReferenceClick = (editorState) => {
    //e.preventDefault()
    const { text } = getCurrentTextSelection(editorState);

    const currentSelectionState = editorState.getSelection();
    const currentContent = editorState.getCurrentContent();

    let newContentState = Modifier.replaceText(
      currentContent,
      currentSelectionState,
      `${SUGGESTION_TRIGGER}${text}`,
    );

    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      `insert-text`,
    );

    onChange(newEditorState)
  }

  const inlineStyle = editorState.getCurrentInlineStyle()
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="format-menu" onClick={e => e.preventDefault()} onMouseDown={e => e.preventDefault()}>
      <div className="format-menu-content">
        <div className="format-menu-button-group">
          <FormatMenuButton 
            label={<Icon name={"editor_bold"} />}
            labelClassName="bold"
            onClick={onBoldClick}
            tooltip="Bold"
            isActive={inlineStyle.has("BOLD")}
          />
          <FormatMenuButton 
            label={<Icon name={"editor_italic"} />}
            labelClassName="italic"
            onClick={onItalicClick}
            tooltip="Italic"
            isActive={inlineStyle.has("ITALIC")}
          />
          <FormatMenuButton 
            label={<Icon name={"editor_underline"} />}
            labelClassName="underline"
            onClick={onUnderlineClick}
            tooltip="Underline"
            isActive={inlineStyle.has("UNDERLINE")}
          />
        </div>
        <div className="format-menu-button-group">
          <FormatMenuButton 
            label={<Icon name={"editor_ol"} />}
            labelClassName="ordered-list"
            onClick={onOrderedListClick}
            tooltip="Ordered List"
            isActive={blockType === 'ordered-list-item'}
          />
          <FormatMenuButton 
            label={<Icon name={"editor_ul"} />}
            labelClassName="unordered-list"
            onClick={onUnOrderedListClick}
            tooltip="Unordered List"
            isActive={blockType === 'unordered-list-item'}
          />
        </div>
        <div className="format-menu-button-group">
          <FormatMenuButton 
            label={<Icon name={"editor_reference"} />}
            labelClassName="reference"
            onClick={() => onReferenceClick(editorState)}
            tooltip={`Reference\nInsert a link as a reference to another object`}
            isActive={false}
          />
        </div>
      </div>
    </div>
  )
}
