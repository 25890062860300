import React from 'react';
import { 
  Editor 
} from 'draft-js'

import { 
  styleMap
} from './_helpers'

export const TextareaReadOnly = ({ value }) => {
  return (
     <div className="CustomTextareaReadOnly">
       <Editor 
        editorState={value} 
        customStyleMap={styleMap}
        //blockRenderMap={extendedBlockRenderMap}
        readOnly={true} /> 
     </div>
  );
}