import React from 'react';

import { PopupTable } from './'
import { 
  DataTable, Icon
} from '../../'

export const PopupTableContainer = React.memo( ({selectedItems, setSelectedItems, fields, setFields, handleMouseEnterRow, handleMouseLeaveRow, togglePopup, showPopup, refs}) => {

  const selectedEdgesCount = (selectedItems?.edges ? selectedItems.edges.length : 0)
  const selectedNodesCount = (selectedItems?.nodes ? selectedItems.nodes.length : 0)
  const selectedItemsTotalCount = selectedEdgesCount + selectedNodesCount

  if (selectedItemsTotalCount > 0) {
	return (

	    <div 
	      className={"selected-items-popup" + (!showPopup ? " hide" : "") + (selectedItemsTotalCount <= 1 ? " one-item" : "") }
	      onClick={e => !showPopup && togglePopup()}
	    >
	     
	      <div className="selected-items-popup-header">
	        <div className="selected-items-popup-title">
	          { selectedItemsTotalCount > 1 && selectedNodesCount > 0 &&
	            selectedItems.nodes.length + " selected Datasets"
	          }
	          { selectedNodesCount === 1 && selectedItemsTotalCount === 1 &&
	            <React.Fragment>
	              <Icon name={selectedItems.nodes[0].data.dataset_type_name} tooltip={selectedItems.nodes[0].data.dataset_type_name} />
	              {selectedItems.nodes[0].data.label}
	            </React.Fragment>
	          }
	          { selectedEdgesCount > 0 && selectedNodesCount > 0 &&
	             ", "
	          }
	          { selectedEdgesCount > 0 && 
	            selectedItems.edges.length + " selected Relationships"
	          }
	        </div>
	        <div 
	          className="toggle-popup" 
	          onClick={e => togglePopup()}
	          title={showPopup ? "Minimize" : "Show relationships for this dataset"}>
	        </div>
	      </div>


	      <div className="selected-items-popup-content">
	        { selectedItems.nodes &&

	          selectedItems.nodes.map( (node, i) => (
	            <PopupTable 
	              key={i}
	              selectedItems={selectedItems}
	              setSelectedItems={setSelectedItems}
	              node={node}  
	              fields={fields}
	              setFields={setFields}
	              onMouseEnterRow={handleMouseEnterRow}
	              onMouseLeaveRow={handleMouseLeaveRow}
	              ref={el => refs.current[i] = el}
	              />
	          ))
	        }
	        {  selectedItems.edges && selectedItems.edges.length > 0 &&
	          <div className="selected-items-popup-section">
	            <div className="selected-items-popup-section-header">
	              <div className="selected-items-popup-section-title">
	                Relationships
	              </div>
	            </div>

	            <DataTable
	              columns={[
	                {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
	                {id: 'dataset_name', name: 'Dataset', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', tooltip: 'dataset_description'},
	                {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_name'},
	                {id: 'field_name', name: 'Field', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', tooltip: 'field_description_description', className:"bold"},
	                {id: 'direction', name:'', type: 'icon'},
	                {id: 'related_datatype_category', name: '', type: 'icon', tooltip: 'related_datatype_name'},
	                {id: 'related_field_name', name: 'Related Field', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id/:related_field_id', tooltip: 'related_field_description_description', className:"bold"},
	                {id: 'related_dataset_type_name', name: '', type: 'icon', tooltip: 'related_dataset_type_name'},
	                {id: 'related_dataset_name', name: 'Related Dataset', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id', tooltip: 'related_dataset_description'},
	              ]}
	              data={selectedItems.edges}
	              idColumn='id'
	              editable={false}
	              loading={false}
	              filterable={false}
	              hideToolbar={true}
	              className="slim bg-white"
	              onMouseEnterRow={handleMouseEnterRow}
	              onMouseLeaveRow={handleMouseLeaveRow}
	            />
	          </div>
	        }
	      </div>
	    </div>

  )		
  } else {
  	return null
  }
})