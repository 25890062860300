import React, {useRef} from 'react';
import { 
  businessTermService,
  datasetService,
  datasetGroupService,
  datasourceService,
  fieldService,
  fieldDescriptionService,
  glossaryService,
  systemService,
  userService,
  userGroupService
} from '../_services';
import { copyToClipboard } from '../_helpers';

const checkDeleteBusinessTerm = async (items, setMessage) => {    
  return businessTermService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteDataset = async (items, setMessage) => {    
  return datasetService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteDatasetGroup = async (items, setMessage) => {    
  return datasetGroupService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteDatasource = async (items, setMessage) => {    
  return datasourceService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteField = async (items, setMessage) => {    
  return fieldService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteFieldDescription = async (items, setMessage) => {    
  return fieldDescriptionService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteGlossary = async (items, setMessage) => {    
  return glossaryService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteSystem = async (items, setMessage) => {    
  return systemService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteUser = async (items, setMessage) => {    
  return userService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const checkDeleteUserGroup = async (items, setMessage) => {    
  return userGroupService.checkDestroy(items)
    .then(async res => { 
      return res
    })
    .catch(err => {setMessage(JSON.stringify(err), 'error')});
}

const getRelationshipImportQuery = async (items, setMessage) => {    
  return datasourceService.getRelationshipImportQuery(items.datasource_id, {relationship_map: items.relationship_map})
    .then(async res => { 
      return res
    })
    .catch(err => {
      setMessage(JSON.stringify(err), 'error')
      return {query: "Error when compiling query"}
    });
}

const getDatasourceImportQuery = async (items, setMessage) => {    
  return datasourceService.getDatasourceImportQuery({datasource: items})
    .then(async res => { 
      return res
    })
    .catch(err => {
      setMessage(JSON.stringify(err), 'error')
      return {query: "Error when compiling query"}
    });
}

const objectMap = {
  business_term_count: 'Business Term',
  dataset_count: 'Dataset',
  dataset_group_count: 'Dataset Group',
  datasource_count: 'Datasource',
  field_count: 'Field',
  field_description_count: 'Field Description',
  field_unit_count: 'Field Unit',
  glossary_count: 'Glossary',
  system_count: 'System',
  task_count: 'Task',
  user_count: 'User',
  user_group_count: 'User Group',
}

const renderBody = (checkDelete) => {
  return  <div>
            <div>Are you sure you want to delete the following items?</div>
            <ul>
            { Object.keys(checkDelete.result).map( (key, index) => {
                  return checkDelete.result[key] > 0  
                          ? <li key={key}>{checkDelete.result[key] + ' ' + objectMap[key] + (checkDelete.result[key] > 1 ? 's':'')}</li> 
                          : null
              })
            }
            </ul>
          </div>
}

export const useModalDialog = (setModalDialog, setMessage, cancelSideDialog) => {

  const refContainerBody = useRef()

  const showModalDialog = async (mode, data, onSubmit, onCancel) => {
    
    cancelSideDialog && cancelSideDialog()
    
    let defaultConfig = {
          active: true,
          title: "You are about to delete some stuff",
          cancelLabel: "Cancel",
          submitLabel: "OK",
          onSubmit: () => onSubmit(data),
          onCancel: onCancel,
          loading: false
    }

    let config = {}
    let checkDelete = {}

    switch(mode) {
      case "removeFieldAssociation":
        config = {
          ...config,
          title: "You are about to remove some field associations",
        }
        break

      case "removeMember":
        config = {
          ...config,
          title: "You are about to remove some users from a user group",
        }
        break

      case "showRelationshipQuery":
        config = {
          ...config,
          title: "Relationship Upsert Query",
          cancelLabel: undefined,
          submitLabel: "Close",
          onSubmit: undefined,
          onCancel: undefined,
          customButtons: [{
            label: "Copy",
            tooltip: "Copy query to clipboard",
            onClick: () => { copyToClipboard(refContainerBody)}
          }],
        }
        break

      case "showFilterQuery":
        config = {
          ...config,
          title: "Datasource Import Query",
          cancelLabel: undefined,
          submitLabel: "Close",
          onSubmit: undefined,
          onCancel: undefined,
          customButtons: [{
            label: "Copy",
            tooltip: "Copy query to clipboard",
            onClick: () => { copyToClipboard(refContainerBody)}
          }],        
        }
        break

      case "showMessage":
        config = {
          ...config,
          title: "Job Completion Message",
          cancelLabel: undefined,
          submitLabel: "Close",
          onSubmit: undefined,
          onCancel: undefined,
          customButtons: [{
            label: "Copy",
            tooltip: "Copy query to clipboard",
            onClick: () => { copyToClipboard(refContainerBody)}
          }],
        }
        break

      case "editSettings":
        config = {
          ...config,
          title: "Saving settings will trigger a reboot",
          submitLabel: "Save",
          onSubmit: onSubmit,
        }
        break

      default:
        break
    }



    setModalDialog({...defaultConfig, ...config})

    switch(mode) {
      case "deleteBusinessTerm":
        checkDelete = await checkDeleteBusinessTerm({ business_terms: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteDataset":
        checkDelete = await checkDeleteDataset({ datasets: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteDatasetGroup":
        checkDelete = await checkDeleteDatasetGroup({ dataset_groups: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteDatasource":
        checkDelete = await checkDeleteDatasource({ datasources: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteField":
        checkDelete = await checkDeleteField({ fields: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteFieldDescription":
        checkDelete = await checkDeleteFieldDescription({ field_descriptions: data }, setMessage)
        config = {
          ...config,
          body: <div>
                  <div>Are you sure you want to to proceed with the following changes?</div>
                  <ul>
                  { checkDelete.result['field_description_count'] > 0 &&
                    <li>{checkDelete.result['field_description_count'] + ' ' + objectMap['field_description_count'] + (checkDelete.result['field_description_count'] > 1 ? 's':'')} will be deleted</li>
                  }
                  { checkDelete.result['field_count'] > 0 &&
                    <li>{checkDelete.result['field_count'] + ' ' + objectMap['field_count'] + (checkDelete.result['field_count'] > 1 ? 's will loose their descriptions':' will loose its description')}</li> 
                  }
                  </ul>
                </div>
        }
        break
      case "removeFieldAssociation":
        config = {
          ...config,
          body: <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' ' + objectMap['field_count'] + (data.length > 1 ? 's will loose their descriptions':' will loose its description')} </li>
                  }
                  </ul>
                </div>
        }
        break;
      case "deleteFieldUnit":
        config = {
          ...config,
          body: renderBody({result: {field_unit_count: data.length}})
        }
        break
      case "deleteGlossary":
        checkDelete = await checkDeleteGlossary({ glossaries: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteSystem":
        checkDelete = await checkDeleteSystem({ systems: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "deleteTask":
        config = {
          ...config,
          body: <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' Task' + (data.length > 1 ? 's':'')} and related Job logs will be deleted</li>
                  }
                  </ul>
                </div>
        }
        break
      case "deleteUser":
        checkDelete = await checkDeleteUser({ users: data }, setMessage)

        config = {
          ...config,
          body: <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { checkDelete.result.user_delete_count > 0 &&
                    <li>{checkDelete.result.user_delete_count + ' User' + (checkDelete.result.user_delete_count > 1 ? 's':'')} will be deleted</li>
                  }
                  { checkDelete.result.user_disable_count > 0 &&
                    <li>{checkDelete.result.user_disable_count + ' User' + (checkDelete.result.user_disable_count > 1 ? 's still own items and will be disabled':' still owns items and will be disabled')}</li> 
                  }
                  </ul>
                </div>
        }
        break
      case "deleteUserGroup":
        checkDelete = await checkDeleteUserGroup({ user_groups: data }, setMessage)
        config = {
          ...config,
          body: renderBody(checkDelete)
        }
        break
      case "removeMember":
        config = {
          ...config,
          body: <div>
                  <div>Are you sure you want to proceed with the following changes?</div>
                  <ul>
                  { data.length > 0 &&
                    <li>{data.length + ' Users will be removed'} </li>
                  }
                  </ul>
                </div>
        }
        break;
      case "showRelationshipQuery":
        const relationshipQuery = await getRelationshipImportQuery(data, setMessage)
        
        config = {
          ...config,
          body: <div className="modal-container-body-code code" ref={refContainerBody}>
                  {relationshipQuery.query}
                </div>
        }
        break
      case "showFilterQuery":
        const filterQuery = await getDatasourceImportQuery(data, setMessage)
        config = {
          ...config,
          body: <div className="modal-container-body-code code" ref={refContainerBody}>
                  {filterQuery.query}
                </div>
        }
        break
      case "showMessage":
        config = {
          ...config,
          body: <div className="modal-container-body-text wide" ref={refContainerBody}>
                  <p>{data}</p>
                </div>
        }
        break
      case "editSettings":
        config = {
          ...config,
          body: <div className="modal-container-body-text">
                  <p>WARNING!</p>
                  <p>Saving settings will reboot the backend API service. Katalogue will be unresponsible for all users until the reboot is finished. This normally takes a couple of seconds.</p>
                  <p>If the backend server fails to reboot due to corrupt setting values, update those directly in the public.setting table in the Katalogue repository database and reboot the backend service manually.</p>
                </div>
        }
        break
      default:
        break
    }
    
    setModalDialog({...defaultConfig, ...config})

  }

  return { showModalDialog }
};