import { useState } from 'react';
import { 
  AddBusinessTermForm,
  AddDatasetForm,
  AddDatasetGroupForm,
  AddDatasourceForm,
  AddFieldForm,
  AddFieldDescriptionForm,
  AddFieldUnitForm,
  AddGlossaryForm,
  AddSystemForm, 
  AddTaskForm,
  EditProfileForm, 
  AddUserForm,
  AddUserGroupForm
} from '../_forms';

export const useSideDialog = (setSelectedItems, setMessage) => {

  const initialAction = {
          mode: '',
          Component: null,
          title: "",
          data: [],
          setMessage: undefined,
          submitForm: null, 
          cancelForm: null
        }

  const [action, setAction] = useState(initialAction);

  const cancelSideDialog = () => {
    setAction(initialAction)
  }
  const showSideDialog = (mode, data, onSubmit) => {
    const itemCount = data.length

    const defaultConfig = {
      data: data,
      setMessage: setMessage,
      submitForm: onSubmit, 
      cancelForm: cancelSideDialog
    }

    let config = {}

    switch(mode) {

      case "addBusinessTerm":
        config = {
          mode: 'add',
          Component: AddBusinessTermForm,
          title: "Add Business Term"          
        }
        break
      case "addDataset":
        config = {
          mode: 'add',
          Component: AddDatasetForm,
          title: "Add Dataset"          
        }
        break
      case "addDatasetGroup":
        config = {
          mode: 'add',
          Component: AddDatasetGroupForm,
          title: "Add DatasetGroup"          
        }
        break
      case "addDatasource":
        config = {
          mode: 'add',
          Component: AddDatasourceForm,
          title: "Add Datasource"          
        }
        break
      case "addField":
        config = {
          mode: 'add',
          Component: AddFieldForm,
          title: "Add Field"          
        }
        break
      case "addFieldDescription":
        config = {
          mode: 'add',
          Component: AddFieldDescriptionForm,
          title: "Add Field Description"          
        }
        break
      case "addFieldUnit":
        config = {
          mode: 'add',
          Component: AddFieldUnitForm,
          title: "Add Field Unit"          
        }
        break
      case "addGlossary":
        config = {
          mode: 'add',
          Component: AddGlossaryForm,
          title: "Add Glossary"          
        }
        break
      case "addSystem":
        config = {
          mode: 'add',
          Component: AddSystemForm,
          title: "Add System"          
        }
        break
      case "addTask":
        config = {
          mode: 'add',
          Component: AddTaskForm,
          title: "Add Task"          
        }
        break
      case "addUser":
        config = {
          mode: 'add',
          Component: AddUserForm,
          title: "Add User"          
        }
        break
      case "addUserGroup":
        config = {
          mode: 'add',
          Component: AddUserGroupForm,
          title: "Add User Group"          
        }
        break


      case "editBusinessTerm":
        config = {
          mode: 'edit',
          Component: AddBusinessTermForm,
          title: itemCount > 1 ? `Edit ${itemCount} Business Terms` : "Edit Business Term"
        }
        break
      case "editDataset":
        config = {
          mode: 'edit',
          Component: AddDatasetForm,
          title: itemCount > 1 ? `Edit ${itemCount} Datasets` : "Edit Dataset"
        }
        break
      case "editDatasetGroup":
        config = {
          mode: 'edit',
          Component: AddDatasetGroupForm,
          title: itemCount > 1 ? `Edit ${itemCount} Dataset Groups` : "Edit Dataset Group"
        }
        break
      case "editDatasource":
        config = {
          mode: 'edit',
          Component: AddDatasourceForm,
          title: itemCount > 1 ? `Edit ${itemCount} Datasources` : "Edit Datasource"
        }
        break
      case "editField":
        config = {
          mode: 'edit',
          Component: AddFieldForm,
          title: itemCount > 1 ? `Edit ${itemCount} Fields` : "Edit Field"
        }
        break
      case "editFieldDescription":
        config = {
          mode: 'edit',
          Component: AddFieldDescriptionForm,
          title: itemCount > 1 ? `Edit ${itemCount} Field Descriptions` : "Edit Field Description"
        }
        break
      case "editFieldUnit":
        config = {
          mode: 'edit',
          Component: AddFieldUnitForm,
          title: itemCount > 1 ? `Edit ${itemCount} Field Units` : "Edit Field Unit"
        }
        break
      case "editGlossary":
        config = {
          mode: 'edit',
          Component: AddGlossaryForm,
          title: itemCount > 1 ? `Edit ${itemCount} Glossaries` : "Edit Glossary"
        }
        break
      
      case "editProfile":
        config = {
          mode: 'edit',
          Component: EditProfileForm,
          title: itemCount > 1 ? `Edit ${itemCount} Profiles` : "Edit Profile"
        }
        break
      case "editSystem":
        config = {
          mode: 'edit',
          Component: AddSystemForm,
          title: itemCount > 1 ? `Edit ${itemCount} Systems` : "Edit System"
        }
        break
      case "editTask":
        config = {
          mode: 'edit',
          Component: AddTaskForm,
          title: itemCount > 1 ? `Edit ${itemCount} Tasks` : "Edit Task"       
        }
        break
      case "editUser":
        config = {
          mode: 'edit',
          Component: AddUserForm,
          title: itemCount > 1 ? `Edit ${itemCount} Users` : "Edit User"       
        }
        break
      case "editUserGroup":
        config = {
          mode: 'edit',
          Component: AddUserGroupForm,
          title: itemCount > 1 ? `Edit ${itemCount} User Groups` : "Edit User Group"       
        }
        break
      default:
        break
    }
    setAction({...defaultConfig, ...config})
    setSelectedItems && setSelectedItems([])
  }

  return { action: action, cancelSideDialog, showSideDialog }
};