import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { Tab } from './Tab'
import { useQueryParams } from '../_hooks';
import { keyCode } from '../_helpers';

export const _Tabs = ({onTabChange, showResultCount, showNoResultsMessage=true, disableTabsWithoutResults, defaultSelectedTab, className, loading, children}, ref) => {

  const { setHistory } = useQueryParams()

  const [activeTab, setActiveTab] = useState(defaultSelectedTab?.tab)

  const refTabs = useRef([]);
  
  children = (Array.isArray(children) ? children : [children]) // If only one child, children will not be an array

  useEffect( () => {

    refTabs.current = new Array(children.length)

  }, []);

  useEffect( () => {
   
    setActiveTab(defaultSelectedTab?.tab || children[0].props.tabId)

  }, [defaultSelectedTab]);

  const onClickTabItem = (tab) => {
    setActiveTab(tab)

    // Call external function if provided
    if (onTabChange) { 
      
      setHistory({filter:"", sort: undefined})
      onTabChange(tab) 
    } else {
      setHistory({tab: tab, filter:"", sort: undefined})
    }
    
  }
  
  const focusNextListItem = (direction) => {
    const activeElement = document.activeElement;

    const activeElementIndex = refTabs.current.findIndex(el => el === activeElement)
    const activeTabIndex = activeElementIndex < 0 ? children.findIndex(el => el.props.tabId === activeTab) : activeElementIndex
    
    if (activeTabIndex >= 0) {
      if (direction === keyCode.RIGHT_ARROW && activeTabIndex+1 < refTabs.current.length) {
        refTabs.current[activeTabIndex+1].focus()
      } else if (direction === keyCode.LEFT_ARROW && activeTabIndex > 0) {
        refTabs.current[activeTabIndex-1].focus()
      }
    } else {
      refTabs.current[0].focus()
    }
  }

  const handleKeyDown = (e, val) => {
    switch (e.keyCode) {
      case keyCode.LEFT_ARROW:
        focusNextListItem(keyCode.LEFT_ARROW);
        break;

      case keyCode.RIGHT_ARROW:
        focusNextListItem(keyCode.RIGHT_ARROW);
        break;

      default:
        break;
    }
  }
  
  return (
    <div className={"CustomTabs" + (className ? " " + className : "") + (showResultCount ? " show-result-count" : "")} name="Tabs">
      <div className="tab-header">

        <div 
          className="tab-list"
          role="tablist"
          onKeyDown={handleKeyDown}
        >
          {children.map( (child, i) => {

            if (child === undefined || child === null) return null

            const { label, tabId, resultCount, disabled } = child.props;

            //if (resultCount === 0 && tabId !== 'all') return null

            return (
              <Tab
                ref={el => refTabs.current[i] = el}
                activeTab={activeTab}
                key={tabId}
                label={label}
                tabId={tabId}
                resultCount={resultCount}
                onClick={onClickTabItem}
                disableTab={(disableTabsWithoutResults && resultCount===0) || disabled}

              />
            );
          })}
        </div>
      </div>

      <div className="tab-content-wrapper" ref={ref} role="tabpanel">
       {children.map((child, index) => {
        
          const { label, tabId, resultCount, disabled } = child.props;

          

          if (child === undefined || child === null || tabId !== activeTab) return undefined;
          
          if (!loading && (((disableTabsWithoutResults && resultCount===0) && showNoResultsMessage) || disabled)) {
            return (<div className="tab-content-empty-text" key={index}>No assets</div>)
          }

          return (
            <div className="tab-content" key={index}>
              { showResultCount &&
              <div className="result-count">{resultCount > 0 && resultCount + " " + ((tabId !== 'all') ? label : "assets") +" found"}</div>
              }
              {child.props.children}
            </div>
            );

          })
        }
      </div>
      
    </div>
  );
};

export const Tabs = forwardRef(_Tabs);
