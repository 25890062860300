import React, { useState, useEffect, useRef } from 'react';
import { FormTab } from './FormTab';
import { keyCode } from '../_helpers';

export const FormTabs = ({children}) => {

  const [activeTab, setActiveTab] = useState('');
  const refTabs = useRef([]);

  useEffect( () => {

    setActiveTab(children[0].props.label)

    refTabs.current = new Array(children.length)

  }, []);

  const onClickTabItem = (tab) => {
    setActiveTab(tab)
  }
  
  const focusNextListItem = (direction) => {
    const activeElement = document.activeElement;

    const activeElementIndex = refTabs.current.findIndex(el => el === activeElement)
    const activeTabIndex = activeElementIndex < 0 ? children.findIndex(el => el.props.label === activeTab) : activeElementIndex
    console.log(refTabs)
    if (activeTabIndex >= 0) {
      if (direction === keyCode.RIGHT_ARROW && activeTabIndex+1 < refTabs.current.length) {
        refTabs.current[activeTabIndex+1].focus()
      } else if (direction === keyCode.LEFT_ARROW && activeTabIndex > 0) {
        refTabs.current[activeTabIndex-1].focus()
      }
    } else {
      refTabs.current[0].focus()
    }
  }

  const handleKeyDown = (e, val) => {
    switch (e.keyCode) {
      case keyCode.LEFT_ARROW:
        focusNextListItem(keyCode.LEFT_ARROW);
        break;

      case keyCode.RIGHT_ARROW:
        focusNextListItem(keyCode.RIGHT_ARROW);
        break;

      default:
        break;
    }
  }

  return (
    <div className="FormTabs">
      <div 
        className="tab-list"
        role="tablist"
        onKeyDown={handleKeyDown}
      >
        {children.map( (child, i) => {
          if (child === undefined || child === null || !child.props) return undefined;

          const { label } = child.props;
          //const newRef = createRef()
          //refTabs.current.push(newRef)
          return (
            <FormTab
              //ref={newRef}
              ref={el => refTabs.current[i] = el}
              activeTab={activeTab}
              key={label}
              label={label}
              onClick={onClickTabItem}

            />
          );
        })}
      </div>

      <div className="tab-content" role="tabpanel">
       {children.map((child) => {
            if (child === undefined || child === null || !child.props || child.props.label !== activeTab) return undefined;

            return child.props.children;
          })}
      </div>
      
    </div>
  );
};
