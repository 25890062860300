import React, { useState, useEffect } from 'react'
import { fieldService, fieldDescriptionService, fieldRoleService, fieldSensitivityService, fieldUnitService, userService } from '../_services';
import { 
	Checkbox, 
	FormTabs, 
	Select, 
	SelectButtons, 
	formatSelectOptions, 
	Icon, 
	Input, 
	Textarea, 
	textareaInitialState, 
	textareaToRaw, 
	textareaToPlainText
} from '../_components';
import { formatPath, getBatchEditValue } from '../_helpers'
import { useGlobalState, useSearch, useTooltip } from '../_hooks'

export const AddFieldDescriptionForm = ({mode, data, submitForm, cancelForm, setMessage }) => { 
	
	const initialFormState = {
		field_description_id: null, 
		field_description_name: '',
		field_description_description: textareaInitialState({}),
		field_description_definition: textareaInitialState({}),
		field_description_example: textareaInitialState({}),
		is_pii: false,
		field_role_id: '', 
		field_sensitivity_id: '',
		field_unit_id: '',
		field_description_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_is_pii: false,
		batch_field_role_id: false, 
		batch_field_sensitivity_id: false,
		batch_field_unit_id: false,
		field_description_owner_user_id: false
	}

	const [hasError, setErrors] = useState(false);
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loading, setLoading] = useState(false);
	const [fieldAssociations, setFieldAssociations] = useState([]);
	const [tmpFieldAssociations, setTmpFieldAssociations] = useState([]);
	const [fieldRoles, setFieldRoles] = useState([]);
	const [fieldSensitivities, setFieldSensitivities] = useState([]);
	const [fieldUnits, setFieldUnits] = useState([]);
	const [fieldDescriptionsWithSameName, setFieldDescriptionsWithSameName] = useState([]);
	const [users, setUsers] = useState([]);	
	const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);

  const {showTooltip, hideTooltip} = useTooltip();
  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage } = useSearch({setMessage, setLoading: setLoadingMoreOptions, objectName: 'field', pagination: true})

	useEffect( () => {

  	// Get form data
  	fetchFieldRoles();
  	fetchFieldSensitivities();
  	fetchFieldUnits();
  	fetchUsers();

  	if(data.length === 1 && mode !== 'add') {
  		fetchFieldAssociations();
  	}

	}, []);

	useEffect(() => {

		setBatchEdit(false)
	    if (data.length === 1) {
			setFormData({ 
				field_description_id: data[0].field_description_id, 
				field_description_name: data[0].field_description_name || '',
				field_description_description: textareaInitialState({value: data[0].field_description_description}),
				field_description_definition: textareaInitialState({value: data[0].field_description_definition}),
				field_description_example: textareaInitialState({value: data[0].field_description_example}), 
				is_pii: data[0].is_pii,
				field_role_id: data[0].field_role_id,
				field_sensitivity_id: data[0].field_sensitivity_id,
				field_unit_id: data[0].field_unit_id,
				field_description_owner_user_id: data[0].field_description_owner_user_id,
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				is_pii: getBatchEditValue(data, 'is_pii'),
				field_role_id: getBatchEditValue(data, 'field_role_id'),
				field_sensitivity_id: getBatchEditValue(data, 'field_sensitivity_id'),
				field_unit_id: getBatchEditValue(data, 'field_unit_id'),
				field_description_owner_user_id: getBatchEditValue(data, 'field_description_owner_user_id'),
			})
		} else if(mode === 'add') {

			const defaultFieldRole = fieldRoles?.filter(x => x.field_role_name.toLowerCase() === "dimension")

			setFormData({ 
				...formData, 
				field_description_owner_user_id: loggedInUser?.user_id,
				field_role_id: defaultFieldRole.length > 0 && defaultFieldRole[0].field_role_id
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, fieldRoles]);

	const fetchFieldRoles = async () => {
	    fieldRoleService.getAll()
	      .then(res => { 
	      	setFieldRoles(res.field_roles); 
	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchFieldSensitivities = async () => {
	    fieldSensitivityService.getAll()
	      .then(res => { 
	      	setFieldSensitivities(res.field_sensitivities);
	      	if(mode === 'add' && data.length < 1) { 
		      	setFormData(prev => {return { 
							...prev, 
							field_sensitivity_id: res.field_sensitivities.find(x => x.is_default)?.field_sensitivity_id
						}})
		      }
	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchFieldUnits = async () => {
	    fieldUnitService.getAll()
	      .then(res => { 
	      	setFieldUnits(res.field_units); 
	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchUsers = async () => {
	    userService.getAll()
	      .then(res => { 
	      	setUsers(res.users); 
	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchFieldDescriptionByName = async (name) => {
		if (name && name !== '') {
			const id = (data.length > 0 && data[0].field_description_id) || undefined

			fieldDescriptionService.getByName(name, id)
		    .then(res => { 
		    	setFieldDescriptionsWithSameName(res.field_descriptions);
		    })
		    .catch(err => {setErrors(err)});
		} else {
	  	setFieldDescriptionsWithSameName([])
	  }
	}
  
  const fetchFieldAssociations = async () => {

    fieldService.getByFieldDescriptionId(data[0].field_description_id)
      .then(res => {

      	if (res) {

      		const fields = res.fields.map( field => ({
      			field_id: field.field_id, 
            field_name: field.field_name, 
            field_description_id: data[0].field_description_id, 
            icon: field.datatype_category, 
            secondaryLabel: formatPath([field.system_name, field.datasource_name, field.dataset_group_name, field.dataset_name]),
      		}))
      		setFieldAssociations(fields);
        	setTmpFieldAssociations(fields);
      	}

         
      })
      .catch(err => {console.log(err); setMessage(JSON.stringify(err), 'error')});
  }

  const addFieldAssociation = async event => {
    let { value, label, icon, secondaryLabel } = ""

    value = event.value
    label = event.option.label
    icon = event.option.icon
    secondaryLabel = event.option.secondaryLabel

    const field = { 
    	field_id: value, 
    	field_description_id: formData.field_description_id, 
    	field_name: label,
    	icon,
    	secondaryLabel
    }

   	let newTmpFieldAssociations = []
    if (tmpFieldAssociations.findIndex(x => x.field_id === value) > -1) {
    	newTmpFieldAssociations = tmpFieldAssociations.filter(x => x.field_id !== value)
    } else {
    	newTmpFieldAssociations = [...tmpFieldAssociations, ...[field]]
    }

    setTmpFieldAssociations(newTmpFieldAssociations)
  }

	const removeFieldAssociation = (item) => {
		setTmpFieldAssociations(prev => prev.filter(x => x.field_id !== item.field_id))
	}

	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }
	  
	  switch(name) {
	  	case 'field_description_name':
		  	fetchFieldDescriptionByName(value)
	  		break
	  	case 'is_pii':
	  		// Check currently selected sensitivity class and change to lowest allowed sensitivity class if current class is not allowed
		  	if (!batchEdit && !fieldSensitivities.find(x => x.field_sensitivity_id === formData.field_sensitivity_id)?.is_allowed_for_pii) {

		  		const fieldSensitivityId = fieldSensitivities.filter(x => x.is_allowed_for_pii).reduce(function(prev, curr) {
																	    return prev.field_sensitivity_level > curr.field_sensitivity_level ? prev : curr;
																	}).field_sensitivity_id

		  		setFormData(prev => {return { 
		  			...prev, 
		  			field_sensitivity_id: fieldSensitivityId 
					}})
		  	}

	  		break
	  	default:
	  		break
	  }	

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems(prev => { return { ...prev, [name]: value }})
	  		break
	  	default:
	  		setFormData(prev => { return { ...prev, [name]: value }})
	  }
	}

  const handleSearchFieldInputChange = value => {

    setSearchTerm(value)

    if (value.length > 1) { // Only trigger search query for search terms with more than 1 character
      fetchSearchResults(value, false, 1)
    }
  }

	const onSubmit = event => {
		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit
		let field_descriptions
		let field_associations

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				
				let fieldSensitivityId = item.field_sensitivity_id
				if (!fieldSensitivities.find(x => x.field_sensitivity_id === item.field_sensitivity_id)?.is_allowed_for_pii) {

		  		fieldSensitivityId = fieldSensitivities.filter(x => x.is_allowed_for_pii).reduce(function(prev, curr) {
																	    return prev.field_sensitivity_level > curr.field_sensitivity_level ? prev : curr;
																	}).field_sensitivity_id
		  	}

				return {field_description_id: item.field_description_id, 
								field_description_name: item.field_description_name,
								is_pii: item.is_pii,
								field_role_id: item.field_role_id,
								field_sensitivity_id: fieldSensitivityId,
								field_unit_id: item.field_unit_id,
								field_description_owner_user_id: item.field_description_owner_user_id, 
								...tmp
							}
			})

			dataToSubmit = {field_descriptions: dataToSubmit}

		} else {

			if (mode === 'add') {
				field_associations = tmpFieldAssociations.map(field => ({field_id: field.field_id}))
			} else {
				field_associations = tmpFieldAssociations.map(field => ({field_id: field.field_id, field_description_id: field.field_description_id}))

				// Remove field associations
				fieldAssociations.forEach(field => {
					if (field_associations.findIndex(x => x.field_id === field.field_id) < 0) {
						field_associations.push({field_id: field.field_id, field_description_id: null})
					}
				})
			}

			field_descriptions = [{
				...formData,
				field_description_description: textareaToRaw({value: formData.field_description_description}),
				field_description_definition: textareaToRaw({value: formData.field_description_definition}),
				field_description_example: textareaToRaw({value: formData.field_description_example}),
				field_associations
			}]

			dataToSubmit = {
				field_descriptions
			}
		}

		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})		    
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState,
						field_description_owner_user_id: (loggedInUser ? loggedInUser.user_id : null),
						field_sensitivity_id: fieldSensitivities.find(x => x.is_default)?.field_sensitivity_id
					}
	}) 
  }

	const fieldDescriptionsWithSameNameTooltip = () => {
		return fieldDescriptionsWithSameName.map( (field_description, index) => (
			<React.Fragment key={index}>
				<div className="tooltip-title"><Icon name={field_description.field_role_name}/>{field_description.field_description_name}</div>
				<div className="tooltip-body inverted-margin">{textareaToPlainText(field_description.field_description_description)}</div>
			</React.Fragment>
  	))
	}

	const getSensitivityOptions = () => {
		return formData.is_pii 
		? formatSelectOptions({options: fieldSensitivities.map(x => {return {...x, is_not_allowed_for_pii: !x.is_allowed_for_pii}}), optionValue: "field_sensitivity_id", optionLabel: "field_sensitivity_name", optionDisabled: "is_not_allowed_for_pii" })
		: formatSelectOptions({options: fieldSensitivities, optionValue: "field_sensitivity_id", optionLabel: "field_sensitivity_name" })
	}


  const requestResults = searchResults.map(result => {

    const selectedByOther = ((result.searchable.field_description_id && result.searchable.field_description_id.toString() !== formData.field_description_id) ? true : false)
    return {
            value: result.searchable.field_id, 
            label: result.searchable.field_name, 
            icon: result.searchable.datatype_category, 
            secondaryLabel: formatPath([result.searchable.system_name, result.searchable.datasource_name, result.searchable.dataset_group_name, result.searchable.dataset_name]),
            selectedByOther: selectedByOther,
            tooltip: selectedByOther ? `This Field is already associated with Field Description:\n${result.searchable.field_description_name}` : ""
          }
  })

  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>
				{ !batchEdit &&
					<React.Fragment>
					<div className="form-block vertical">
			      <Input type="text" 
			      	name="field_description_name"
			      	label="Name" 
			      	value={formData.field_description_name} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			      />
			      { fieldDescriptionsWithSameName.length > 0 &&
			        <div 
			        	className="form-light-text"
			        	onMouseEnter={ () => { showTooltip({tooltipBody: fieldDescriptionsWithSameNameTooltip()}) }} 
			        	onMouseLeave={hideTooltip}
			        >
			        { fieldDescriptionsWithSameName.length } Field Description{fieldDescriptionsWithSameName.length > 1 ? 's' : ''} with the same name already exist
			        </div>
		        }
					</div>
					</React.Fragment>
				}

		<FormTabs>
			<div label="description">

				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
			    { <Textarea 
			      	name="field_description_description" 
			      	value={formData.field_description_description} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			      />}
		      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Definition</label>
			    <Textarea
			      	name="field_description_definition" 
			      	value={formData.field_description_definition} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			      />
		      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Example</label>
			    <Textarea
			      	name="field_description_example" 
			      	value={formData.field_description_example} 
			      	onChange={handleInputChange}
			      	disabled={loading}
			      />
		      </div>
				}


				<div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_field_role_id}
									name="batch_field_role_id"
									label="Edit Field Role"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Field Role</label>
					}
					<SelectButtons 
            name="field_role_id"
            value={formData.field_role_id}
            options={ formatSelectOptions({options: fieldRoles, optionValue: "field_role_id", optionLabel: "field_role_name", optionIcon: "field_role_name"  }) }
            onChange={handleInputChange}
            disabled={loading || (batchEdit && !batchEditItems.batch_field_role_id)} 
          />
		    </div>


		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_field_unit_id}
									name="batch_field_unit_id"
									label="Edit Field Unit"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Field Unit</label>
					}
					<Select 
            name="field_unit_id"
            value={formData.field_unit_id === 'multiple' ? undefined : formData.field_unit_id}
            options={ formatSelectOptions({options: fieldUnits, optionValue: "field_unit_id", optionLabel: "field_unit_code", optionSecondaryLabel: "field_unit_name" }) }
            isNullable={true}
            onChange={handleInputChange} 
            placeholder={formData.field_unit_id === 'multiple' ? '< Multiple >' : undefined}
            disabled={loading || (batchEdit && !batchEditItems.batch_field_unit_id)}
          />
		    </div>

		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_field_description_owner_user_id}
									name="batch_field_description_owner_user_id"
									label="Edit Owner"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Owner</label>
					}
					<Select 
            name="field_description_owner_user_id"
            value={formData.field_description_owner_user_id === 'multiple' ? undefined : formData.field_description_owner_user_id}
            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username" }) }
            onChange={handleInputChange} 
            placeholder={formData.field_description_owner_user_id === 'multiple' && '< Multiple >'}
            disabled={loading || (batchEdit && !batchEditItems.batch_field_description_owner_user_id)}
          />
	      </div>


		</div>
		<div label="Data Sensitivity">
		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_field_sensitivity_id}
									name="batch_field_sensitivity_id"
									label="Edit Sensitivity Class"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Sensitivity Class</label>
					}
					<SelectButtons 
            name="field_sensitivity_id"
            value={ formData.field_sensitivity_id }
            options={ getSensitivityOptions() }
            onChange={handleInputChange}
            disabled={loading || (batchEdit && !batchEditItems.batch_field_sensitivity_id)} 
          />
		    </div>	
		    
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_is_pii}
									name="batch_is_pii"
									label="Edit Personally Identifiable Information (PII)"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: null
					}
					<div className="form-block horizontal">
					<Checkbox 
		      	name='is_pii' 
		      	checked={ formData.is_pii } 
		      	onChange={handleInputChange}
		      	label='Personally Identifiable Information (PII)'
		      	disabled={loading || (batchEdit && !batchEditItems.batch_is_pii)}
		      />
		    </div>
			</div>
			{ !batchEdit &&
				<div label="Fields">
					<div className="form-block vertical">
						<Select 
	            name="field_id"
	            value={ tmpFieldAssociations.map(x => {return {value: x.field_id, label: x.field_name}}) }
	            options={ requestResults }
	            onInputChange={ handleSearchFieldInputChange }
	            loadMoreOptions={ loadNextPage }
	            loading={ loadingMoreOptions }
	            resultCount={ searchResultsCount && searchResultsCount.field }
	            searchTerm={ searchTerm }
	            clearSearchResults={ clearSearchResults }
	            placeholder="Search Fields to add..."
	            isMulti={true}
	            isClearable={true}
	            onChange={ addFieldAssociation } 
	            disabled={loading}
	          />
	        </div>

			     	<div className="form-block vertical">
			     	<label>{ tmpFieldAssociations.length } Associated Fields</label>
				      {
				      	tmpFieldAssociations.map((item, index) => {
				      		return (
				      			<div className={"side-dialog-search-result" + (loading ? " disabled" : "")} key={index} onClick={() => {!loading && removeFieldAssociation(item)}}>
					      			<div className="side-dialog-search-result-icon">
				      					<Icon name={item.icon} />
				      				</div>
					      			<div className="side-dialog-search-result-body">
					      				<div className="side-dialog-search-result-title">{item.field_name}</div>
					      				<div className="side-dialog-search-result-subtitle">{item.secondaryLabel}</div>
					      			</div>
					      		</div>
					      	)
				      	})
				      }
			     	</div>
				</div>
			}
		</FormTabs>


	      <div className="form-block horizontal">
					<button 
						type="submit" 
						className={"button main-button" + (loading ? ' loading' : '')} 
	          disabled={loading}>
	          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
	        </button>
					<button 
						type="button" 
						className="button" 
						onClick={cancel}
						disabled={loading}>
						<span>CANCEL</span>
					</button>
				</div>

	    </form>
	  </div>
  )
}
