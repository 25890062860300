import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { DataTable, Icon, textareaToPlainText } from '../../'
import { fieldService } from '../../../_services';


export const _PopupTable = ({
  selectedItems,
  setSelectedItems,
  node, 
  fields,
  setFields,
  onMouseEnterRow,
  onMouseLeaveRow,
}, ref) => {

  const [prevNodeId, setPrevNodeId] = useState(0)
  const [prevNodeEdgesCount, setPrevNodeEdgesCount] = useState(0)
  const [showAllFields, setShowAllFields] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const tableRef = useRef();
  const descriptionRef = useRef();

  useImperativeHandle(ref, () => ({
    get table() {
        return tableRef.current;
    },
    get description() {
        return descriptionRef.current;
    },
    // ... whatever else one may need
  }));

  useEffect( () => {
    //setInitialLoad(false)
  }, [])

  useEffect( () => {
    // Set toggle status correctly when selecting a node after another
    if (node.nodeId !== prevNodeId || (node.neighborEdges.length === prevNodeEdgesCount && node.nodeId === prevNodeId)) {
      setPrevNodeId(node.nodeId)
      setPrevNodeEdgesCount(node.neighborEdges.length)
      setShowAllFields(false)
    }
  }, [node])

  const fetchFields = async (datasetId) => {
    let dataset_id = parseInt(datasetId || selectedItems.nodes[0].id, 10)
    let result = fields.find(x => x.datasetId === dataset_id)
    let newFields = result && result.fields

    if (!result) {
      setLoadingTable(true)

      await fieldService.getByDatasetId(dataset_id)
        .then(res => {

          newFields = res.fields.filter(x => {
              return selectedItems.nodes.find(y => parseInt(y.nodeId) === dataset_id).neighborEdges.findIndex(y => (parseInt(y.field_id, 10) === x.field_id)) === -1
            })

          setFields([...fields, ...[{datasetId: dataset_id, fields: newFields}]]);
          
           
        })
        .catch(err => {console.log(err)});
    } 

    const nodeIndex = selectedItems.nodes.findIndex(x => parseInt(x.nodeId) === dataset_id)

    let returnNodes = selectedItems.nodes
    let returnNode = selectedItems.nodes[nodeIndex]
    returnNode = {
      ...returnNode,
      neighborEdges: [...returnNode.neighborEdges, ...newFields]
    }
    returnNodes[nodeIndex] = returnNode

    setSelectedItems({...selectedItems, nodes: returnNodes})
    setLoadingTable(false);

  }


  const toggleAllFields = (show, datasetId) => {
    
    const state = show !== undefined ? show : !showAllFields
    

    if (state) {
      fetchFields(datasetId)
    } else {
      if (selectedItems) {
        const nodeIndex = selectedItems.nodes.findIndex(x => x.nodeId === datasetId)

        let returnNodes = selectedItems.nodes
        let returnNode = selectedItems.nodes[nodeIndex]
        returnNode = {
          ...returnNode,
          neighborEdges: [...returnNode.neighborEdges.filter(x => x.related_field_id)]
        }
        returnNodes[nodeIndex] = returnNode

        setSelectedItems({...selectedItems, nodes: returnNodes})
      }
    }
    setShowAllFields(state)
  }


  return (
    <div className="PopupTable selected-items-popup-section">
      <div className="selected-items-popup-section-header">
        <div className="selected-items-popup-section-title">
          <Icon name={node.data.dataset_type_name} tooltip={node.data.dataset_type_name} />
          <Link className="link" to={"/browse_datasets/"+node.data.system_id+"/"+node.data.datasource_id+"/"+node.data.dataset_group_id+"/"+node.nodeId+"?tab=fields"} >{node.data.label}</Link>        
        </div>
        
        <div 
          className={"selected-items-popup-section-description" + (node.data.dataset_description ? "" : " empty")} 
          ref={descriptionRef}
        >
          {node.data.dataset_description &&
            textareaToPlainText(node.data.dataset_description)
          }
        </div>
        
      </div>

      <div className="selected-items-popup-section-body" >

        <DataTable
          columns={[
            {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_name'},
            {id: 'field_name', name: 'Field', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', tooltip: 'field_description_description', className:"bold"},
            {id: 'direction', name:'', type: 'icon'},
            {id: 'related_datatype_category', name: '', type: 'icon', tooltip: 'related_datatype_name'},
            {id: 'related_field_name', name: 'Related Field', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id/:related_field_id', tooltip: 'related_field_description_description'},
            {id: 'related_dataset_type_name', name: '', type: 'icon', tooltip: 'related_dataset_type_name'},
            {id: 'related_dataset_name', name: 'Related Dataset', link: '/browse_datasets/:related_system_id/:related_datasource_id/:related_dataset_group_id/:related_dataset_id?tab=fields', tooltip: 'related_dataset_description'},
          ]}
          ref={tableRef}
          data={node.neighborEdges}
          idColumn='id'
          editable={false}
          loading={false}
          loadingMoreRows={loadingTable}
          filterable={false}
          hideToolbar={true}
          className="slim bg-white"
          onMouseEnterRow={onMouseEnterRow}
          onMouseLeaveRow={onMouseLeaveRow}
        />
      </div>

      <div className="selected-items-popup-section-footer">             
        <div className="toggle-all-fields" onClick={e => toggleAllFields(undefined, node.nodeId)}>
          {showAllFields ? "Hide fields without relationships": "Show all fields in this dataset"}
        </div>
      </div>

      </div>

    )
}

export const PopupTable = React.memo(forwardRef(_PopupTable))