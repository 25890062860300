import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { datasetGroupService, datasetService, referenceService, relationshipService } from '../_services';
import { FieldSensitivity, formatConnectionString, handleError, Role, Asset } from '../_helpers';
import { 
  DataTable, 
  MainColumn, 
  ObjectDetails, 
  RelationshipGraph,
  SideDialog, 
  SkeletonLoader,  
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState, usePhotos } from '../_hooks';


export const DatasetGroup = ({ setMessage, setModalDialog, cancelModalDialog, abortFetch }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [datasetGroup, setDatasetGroup] = useState([]);
  const [datasets, setDatasets] = useState([]);
  const [relationships, setRelationships] = useState(null);
  const [fromReferences, setFromReferences] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingTable, setLoadingTable] = useState(true);
  const [loadingRelationships, setLoadingRelationships] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const { system_id, datasource_id, dataset_group_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchDatasets()
    fetchFromReferences()

  }, [dataset_group_id]);

  const fetchItems = async () => {
    
    datasetGroupService.getById(dataset_group_id, abortFetch)
      .then(res => {

        setDatasetGroup(res.dataset_groups[0])
        setLoading(false)
        fetchPhotos(res.dataset_groups[0], 'dataset_group_owner_user_id', setDatasetGroup)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setLoading(false)
        })
      });
  }

  const fetchDatasets = async () => {

    const promise = new Promise(  (resolve, reject) => {
      datasetService.getByDatasetGroupId(dataset_group_id, abortFetch)
        .then(res => {

          const datasets = res.datasets.map(x => {return {...x, pii: (x.is_pii ? 'PII' : null), pii_tooltip: (x.is_pii ? 'Contains Fields classified as Personally Identifiable Information (PII)' : null) }})

          setDatasets(datasets)
          setLoadingTable(false)
          fetchPhotos(datasets, 'dataset_owner_user_id', setDatasets)
          resolve(datasets)
        })
        .catch(err => {
          handleError({err, setMessage}, () => {
            setLoading(false)
          })
        })
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
      })
      .catch(err => {
        handleError({err, setMessage})
      }) 
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.DatasetGroup, dataset_group_id, abortFetch)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {
        handleError({err, setMessage})
      })
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      datasetService.getFieldStatistics(dataset_group_id, abortFetch)
        .then(res => {
          resolve(res.field_statistics)
        })
        .catch(err => {
          return null
        })
    })
  }

  const fetchRelationships = async (datasetsDirect) => {

    relationshipService.getByObjectId(Asset.DatasetGroup, dataset_group_id, abortFetch)
      .then(res => {
        setRelationships({nodes: datasetsDirect || datasets, edges: res.relationships})
        setLoadingRelationships(false)

      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setLoadingRelationships(false)
        })
      })
  }


  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.field_count > maxValue) {
        maxValue = row.field_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.dataset_id === row.dataset_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        field_count: (statistic ? statistic.field_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        field_count_max: maxValue
      }
    })
    setDatasets([...dataTmp]); 

    if (defaultSelectedTab.tab === 'relationships' && !relationships) {
      fetchRelationships([...dataTmp])
    }
  }

  const addDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.create(datasets)
      .then(res => {

        fetchDatasets()
        setMessage(res.message)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      });
  }

  const editDatasetGroup = async data => {
    const datasetGroups = {dataset_groups: (Array.isArray(data) ? data : [data])}
    
    await datasetGroupService.update(datasetGroups)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()
        setMessage(res.message)
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      })
  }

  const editDataset = async data => {
    const datasets = {datasets: (Array.isArray(data) ? data : [data])}
    
    await datasetService.update(datasets)
      .then(async res => { 

        fetchDatasets()
        setMessage(res.message)
        setSelectedItems([])
      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          throw err
        })
      })
  }

  const deleteDatasetGroup = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetGroupService.destroy({dataset_groups: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate(`/browse_datasets/${system_id}/${datasource_id}`)

      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setModalDialog(prev => {return {...prev, loading: false}})
        })
      })
  }

  const deleteDataset = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})
    await datasetService.destroy({ datasets: data })
      .then(async res => { 

        fetchDatasets()
        setMessage(res.message)
        cancelModalDialog()
        clearSelectedItems()

      })
      .catch(err => {
        handleError({err, setMessage}, () => {
          setModalDialog(prev => {return {...prev, loading: false}})
        })
      })
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
    !relationships && fetchRelationships()
  }

  const data = loading ? [] : datasetGroup

  const editableDatasetGroup = loggedInUser && loggedInUser.user_role_name === Role.admin
  const editableDataset = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  const SideDialogForm = editableDataset || editableDatasetGroup ? action.Component : undefined



  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Dataset Group"
          title={data.dataset_group_name}
          subtitleText={data && !loading && 
            formatConnectionString(
              data.datasource_database, 
              data.datasource_hostname, 
              [data.dataset_group_source_name], 
              data.datasource_type_code, 
              data.datasource_type_category)
            }
          loading={loading}
          >

            { !loading 
              ? (!data.dataset_group_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.dataset_group_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.dataset_group_owner_user_id}
              userName={data.dataset_group_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
          </ObjectDetails>

          { !loading && editableDatasetGroup &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editDatasetGroup', [data], editDatasetGroup) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteDatasetGroup', [data], deleteDatasetGroup) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs className="slim left"
          onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults = {true}
          >
          <div label="Datasets" tabId="datasets" resultCount={loadingTable || datasets.length === 0 ? undefined : datasets.length}>

            <DataTable
              columns={[
                {id: 'dataset_type_name', name: '', type: 'icon', tooltip: 'dataset_type_name'},
                {id: 'dataset_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id', tooltip: 'dataset_description', className:"bold"},
                {id: 'field_count', name: 'Fields', type: 'chart', chartType: 'bar', data: {data:'field_count', max: 'field_count_max'}},
                {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}},
                {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:dataset_owner_user_id', tooltip: 'dataset_owner_user_fullname'},                
                {id: 'dataset_owner_user_fullname', name: 'Owner', link: '/users/:dataset_owner_user_id'},
                {id: 'pii', name: '', type: 'icon', tooltip: 'pii_tooltip', className: 'no-right-margin'},
                {id: 'field_sensitivity_level', name: 'Data Sensitivity', type: 'pill', value: 'field_sensitivity_level', values: FieldSensitivity, tooltip: 'field_sensitivity_name'}

              ]}
              buttons={[ 
                {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addDataset', items || selectedItems, addDataset) },
                {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editDataset', items || selectedItems, editDataset) },
                {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteDataset', items || selectedItems, deleteDataset) }
              ]}
              data={datasets}
              idColumn='dataset_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingTable}
              editable={editableDataset}
              filterable={true}
              filterObjects={['dataset_name', 'dataset_owner_user_fullname', 'field_sensitivity_name', 'pii']}
            />
      
          </div>

          <div label="Relationships" tabId="relationships">

            <RelationshipGraph data={relationships} loading={loadingRelationships} setLoading={setLoadingRelationships} filterObjects={['label']} />
      
          </div>
        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} />
          }
      </SideDialog>

    </div>
  ) 
}
