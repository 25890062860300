import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fieldDescriptionService, fieldService, referenceService } from '../_services';
import { FieldSensitivity, formatPath, Role, Asset } from '../_helpers';
import { 
  DataTable, 
  Icon, 
  MainColumn, 
  ObjectDetails, 
  References,  
  Select, 
  SideDialog, 
  SkeletonLoader,  
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  fetchToReferencesCount, 
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useSearch, useGlobalState, usePhotos } from '../_hooks';

export const FieldDescription = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [fieldDescription, setFieldDescription] = useState([]);
  const [Fields, setFields] = useState([]);
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [selectedItems, setSelectedItems] = useState([]);
  const [morphButtonState, setMorphButtonState] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFieldAssociations, setLoadingFieldAssociations] = useState(true);
  const [loadingMoreOptions, setLoadingMoreOptions] = useState(false);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const { 
    searchTerm, 
    searchResults, 
    searchResultsCount, 
    setSearchTerm, 
    fetchSearchResults, 
    clearSearchResults, 
    loadNextPage } = useSearch({setMessage, setLoading: setLoadingMoreOptions, objectName: 'field', pagination: true})

  const { field_description_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {

    fetchItems()
    fetchFields()
    fetchFromReferences()

    fetchToReferencesCount({setMessage, toObjectName: Asset.FieldDescription, toObjectId: field_description_id})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
      })

  }, [field_description_id]);

  const fetchItems = async () => {
    
    fieldDescriptionService.getById(field_description_id)
      .then(res => {

        setFieldDescription(res.field_descriptions[0]);
        setLoading(false)
        fetchPhotos(res.field_descriptions[0], 'field_description_owner_user_id', setFieldDescription)

      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchFields = async () => {
    
    fieldService.getByFieldDescriptionId(field_description_id)
      .then(res => {

        res && setFields(res.fields);
        setLoadingFieldAssociations(false);
         
      })
      .catch(err => {console.log(err); setMessage(JSON.stringify(err), 'error')});
  }

  const fetchFromReferences = async () => {
    
    referenceService.getByFromId(Asset.FieldDescription, field_description_id)
      .then(res => {

        setFromReferences(res.references);
         
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const addFieldAssociation = async event => {
    let { name, value } = ""

    name = event.name
    value = event.value
    const fieldDescriptionId = (Fields.find(x => x.field_id === value)) ? null : field_description_id

    const fields = { fields: [{ field_id: value, field_description_id: fieldDescriptionId }] }

    await fieldService.updateFieldAssociation(fields)
      .then(async res => { 

        fetchSearchResults(undefined, false)
        fetchFields()

        res && setMessage(res.message)

      })
      .catch(err => {console.log(err); setMessage(JSON.stringify(err), 'error')});
  }

  const editFieldDescription = async data => {
    console.log(data)
    await fieldDescriptionService.update(data)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()

        clearSelectedItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editField = async data => {
    const fields = {fields: (Array.isArray(data) ? data : [data])}
    
    await fieldService.update(fields)
      .then(async res => { 

        fetchFields()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteFieldDescription = async (data) => {    

    setModalDialog(prev => {return {...prev, loading: true}})

    await fieldDescriptionService.destroy({field_descriptions: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate('/browse_field_descriptions')

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const removeFieldAssociation = async (data) => {    
    let items = []

    setModalDialog(prev => {return {...prev, loading: true}})

    data.forEach(item => {
      items.push({ field_id: item.field_id, field_description_id: null }) 
    })

    const fields = { fields: items }
    await fieldService.updateFieldAssociation(fields)
      .then(async res => { 

        cancelModalDialog()
        clearSelectedItems()
        fetchFields()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const handleInputChange = value => {

    setSearchTerm(value)

    if (value.length > 1) { // Only trigger search query for search terms with more than 1 character
      fetchSearchResults(value, false, 1)
    }
  }

  const loadMoreOptions = () => {
   loadNextPage()
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }
  
  const morphButtonToggle = () => {
    setMorphButtonState(!morphButtonState)
    clearSelectedItems()
  }

  const requestResults = searchResults.map(result => {

    const selectedByOther = ((result.searchable.field_description_id && result.searchable.field_description_id.toString() !== field_description_id) ? true : false)
    return {
            value: result.searchable.field_id, 
            label: result.searchable.field_name, 
            icon: result.searchable.datatype_category, 
            secondaryLabel: formatPath([result.searchable.system_name, result.searchable.datasource_name, result.searchable.dataset_group_name, result.searchable.dataset_name]),
            selectedByOther: selectedByOther,
            tooltip: selectedByOther ? `This Field is already associated with Field Description:\n${result.searchable.field_description_name}` : ""
          }
  })

  const morphButton = () => {
    return (
      <div className={ "morph-button-wrapper " + ((morphButtonState) ? "show" : "") }> 
        <div className="morph-button-content">
          <form className="form" onSubmit={e => e.preventDefault() }>
            <Select 
              name="field_id"
              value={ Fields.map(x => {return {value: x.field_id, label: x.field_name}}) }
              options={ requestResults }
              onInputChange={ handleInputChange }
              loadMoreOptions={ loadMoreOptions }
              loading={ loadingMoreOptions }
              resultCount={ searchResultsCount && searchResultsCount.field }
              searchTerm={ searchTerm }
              clearSearchResults={ clearSearchResults }
              placeholder="Search Fields to add..."
              isMulti={true}
              isClearable={true}
              onChange={ addFieldAssociation } 
              onBlur={ morphButtonToggle }
              setFocus={ morphButtonState }
            />
        
          </form>
        </div>
        <button type="button" className="morph-button-toggle main-toolbar-item button main-button" onClick={ morphButtonToggle }><span>ADD +</span></button>
      </div>
      )
  }

  const data = loading ? [] : fieldDescription

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  const SideDialogForm = editable ? action.Component : undefined

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Field Description"
          title={<span>{data.field_description_name} <span className="light-text">{ data.field_unit_code }</span></span>}
          subtitleIcon={<Icon name={data.field_role_name} tooltip={ data.field_role_name } />}
          subtitleText={data && !loading && 
            data.field_role_name + ((data.field_unit_name) ? ", "+ data.field_unit_name : "")
            }
          loading={loading}
          >

            { !loading 
              ? (!data.field_description_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.field_description_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }

            <h3>{ !loading ? "Definition" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (!data.field_description_definition) 
                ? <span className="no-result-text">No definition available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.field_description_definition, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }
            </div>

            <h3>{ !loading ? "Example" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (!data.field_description_example) 
                ? <span className="no-result-text">No example available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.field_description_example, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }</div>

            <h3>{ !loading ? "Data Sensitivity" : <SkeletonLoader width="5vw"/>}</h3>
            <div className="display-linebreak">
            { !loading 
              ? (!data.field_sensitivity_name) 
                ? <span className="no-result-text">Sensitivity class not set</span> 
                : <React.Fragment>
                    {data.is_pii && <Icon name="pii" tooltip="Classified as Personally Identifiable Information (PII)"/>}
                    <Icon name="field_sensitivity" value={data.field_sensitivity_level} values={FieldSensitivity} />
                  </React.Fragment>
              : <SkeletonLoader width="10vw" />
            }</div>

            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.field_description_owner_user_id}
              userName={data.field_description_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />
          </ObjectDetails>

          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editFieldDescription', [data], editFieldDescription) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteFieldDescription', [data], deleteFieldDescription) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          onTabChange={ onTabChange }
          defaultSelectedTab={ defaultSelectedTab }
          disableTabsWithoutResults={ true }
        >
          <div label="Associated Fields" tabId="associated_fields" resultCount={loadingFieldAssociations || Fields.length === 0 ? undefined : Fields.length}>

            <DataTable
              columns={[
                {id: 'datatype_category', name: '', type: 'icon', tooltip: 'datatype_name'},
                {id: 'field_name', name: 'Name', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id', className:"bold"},
                {id: 'dataset_name', name: 'Dataset', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id'},
                {id: 'dataset_group_name', name: 'Dataset Group', link: '/browse_datasets/:system_id/:datasource_id/:dataset_group_id' },
                {id: 'datasource_name', name: 'Datasource', link: '/browse_datasets/:system_id/:datasource_id'},
                {id: 'system_name', name: 'System', link: '/browse_datasets/:system_id'}               
              ]}
              buttons={[ 
                    {customButton: morphButton(), action: 'add', onClick: () => {} },
                    {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editField', items || selectedItems, editField) },
                    {label: "Remove", action: "remove", tooltip: "Remove association with this Field Description. The Field itself will not be deleted.", onClick: (items) => showModalDialog('removeFieldAssociation', items || selectedItems, removeFieldAssociation) }
                  ]}
              data={Fields}
              idColumn='field_id'
              selectedItems={selectedItems}
              setSelectedItems={setSelectedItems}
              loading={loadingFieldAssociations}
              editable={editable}
              filterable={true}
              filterObjects={['field_name', 'dataset_name', 'dataset_group_name', 'datasource_name', 'system_name']}
            />
          </div>

          <div label="References" tabId='references' resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              setMessage={setMessage}
              toObjectName={Asset.FieldDescription}
              toObjectId={field_description_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} setMessage={setMessage} />
          }
      </SideDialog>


    </div>
  ) 
}
