export const formatRelationships = (data) => {

  if (data) {
    const nodes = data.nodes
    const edges = data.edges
    
    let compoundNodesLvl1 = []
    let compoundNodesLvl2 = []

    let fieldCountMax = Number.NEGATIVE_INFINITY

    let formattedNodes = nodes.map(node => {

      const relationshipCount = edges.filter(x => x.from_dataset_id === node.dataset_id || x.to_dataset_id === node.dataset_id).length

      if (fieldCountMax < node.field_count ) { fieldCountMax = node.field_count }

      compoundNodesLvl1.push({
        data: {
          id:                       'dataset_group-' + node.dataset_group_id, 
          parent:                   'system-' + node.system_id,
          label:                    node.datasource_name + " - " + node.dataset_group_name, 
          type:                     'compound',
        }
      })

      compoundNodesLvl2.push({
        data: {
          id:                       'system-' + node.system_id, 
          label:                    node.system_name, 
          type:                     'compound',
        }
      })

      return {
        data: {
          id:                       node.dataset_id, 
          parent:                   'dataset_group-' + node.dataset_group_id,
          dataset_group_id:         node.dataset_group_id,
          datasource_id:            node.datasource_id,
          system_id:                node.system_id,
          label:                    node.dataset_name, 
          dataset_description:      node.dataset_description,
          dataset_type_name:        node.dataset_type_name, 
          field_count:              node.field_count,
          field_description_ratio:  node.field_description_ratio,
          relationship_count:       relationshipCount,
          type:                     'node',
          object_type:              'Dataset',
          object_name:              node.dataset_name,
          object_icon:              node.dataset_type_name,
          object_description:       node.dataset_description
        }, 
        classes: 'node'
      }    
    })
  
    compoundNodesLvl1 = [...new Map(compoundNodesLvl1.map(item => [item.data.id, item])).values()];
    compoundNodesLvl2 = [...new Map(compoundNodesLvl2.map(item => [item.data.id, item])).values()];

    formattedNodes = formattedNodes.map( node => {
      if (compoundNodesLvl1.length <= 1) {
        delete node.data.parent
      }
      return {...node, data: {...node.data, field_count_max: fieldCountMax}}
    })

    if (compoundNodesLvl1.length > 1) {
      formattedNodes = [...formattedNodes, ...compoundNodesLvl1, ...compoundNodesLvl2]
    }

    const formattedEdges = edges.map(edge => {
      return {
        data: {
          id:                         edge.from_field_id.toString() + '-' + edge.to_field_id.toString(), // edge.relationship_id, 
          source:                     edge.from_dataset_id, 
          target:                     edge.to_dataset_id,
          source_field_name:          edge.from_field_name, 
          source_dataset_name:        edge.from_dataset_name, 
          source_dataset_type_name:   edge.from_dataset_type_name,
          source_dataset_description: edge.from_dataset_description, 
          source_datatype_name:       edge.from_datatype_name,
          source_datatype_category:   edge.from_datatype_category,        
          source_field_description_description:   edge.from_field_description_description,
          source_field_id:            edge.from_field_id,
          source_dataset_group_id:    edge.from_dataset_group_id,
          source_datasource_id:       edge.from_datasource_id,
          source_system_id:           edge.from_system_id,            
          target_field_name:          edge.to_field_name,
          target_dataset_name:        edge.to_dataset_name, 
          target_dataset_type_name:   edge.to_dataset_type_name,
          target_dataset_description: edge.to_dataset_description, 
          target_datatype_name:       edge.to_datatype_name,
          target_datatype_category:   edge.to_datatype_category,  
          target_field_description_description:   edge.to_field_description_description,
          target_field_id:            edge.to_field_id,
          target_dataset_group_id:    edge.to_dataset_group_id,
          target_datasource_id:       edge.to_datasource_id,
          target_system_id:           edge.to_system_id, 
          type:                       'edge',
          object_type:                'Field',
          object_name:                edge.from_field_name,
          object_icon:                edge.from_datatype_category,
          object_description:         edge.from_field_description_description
        }
      }    
    })
    return [...formattedNodes, ...formattedEdges]
  }
}