import React from 'react';

export const FormatMenuButton = ({label, labelClassName, onClick, isActive, tooltip=""}) => {

  const handleClick = (e) => {
    e.preventDefault()
    onClick()
  }

  return (
    <div 
      className={"format-menu-button" +(isActive ? " active" : "") }
      title={tooltip}
      onClick={e => e.preventDefault()}
      onMouseDown={handleClick}
    >
    
      <span className={labelClassName}>{label}</span>
        
    </div>
  )
}
