export const Asset = {
  Field: 'field',
  Dataset: 'dataset',
  DatasetGroup: 'dataset_group',
  Datasource: 'datasource',
  System: 'system',
  FieldDescription: 'field_description',
  BusinessTerm: 'business_term',
  Glossary: 'glossary',
  User: 'user',
  FieldUnit: 'field_unit',
  Task: 'task',
  Job: 'job'
}

export const AssetName = {
  field: 'Field',
  dataset: 'Dataset',
  dataset_group: 'Dataset Group',
  datasource: 'Datasource',
  system: 'System',
  field_description: 'Field Description',
  business_term: 'Business Term',
  glossary: 'Glossary',
  user: 'User',
  field_unit: 'Field Unit',
  task: 'Task',
  job: 'Job'
}