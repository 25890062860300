import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { formatRelationships } from './_helpers'
import { Graph, PopupTableContainer, GraphLoadingSpinner } from './_components'
import { 
  Button, 
  Icon, 
  Select, 
  Slider, 
  formatSelectOptions, 
  textareaToPlainText 
} from '../'

import { useQueryParams, useTooltip } from '../../_hooks';

export const RelationshipGraph= ({data, loading, setLoading}) => {

  const minZoom = 0.4
  const maxZoom = 1.6
  const defaultZoom = 1.0

  const Dmin = 5
  const Dmax = 40 

  const nodeSizeOptions = {
    'none': {label: 'None', secondaryLabel: ' '},
    'field_count': {label: 'Field Count', secondaryLabel: 'Number of fields in the Dataset'},
    'relationship_count': {label: 'Relationship Count', secondaryLabel: 'Number of related Datasets'},
    'documentation_level': {label: 'Documentation Level', secondaryLabel: 'Percentage of fields with a Field Description'}
  }

  const { queryParams, setHistory } = useQueryParams()

  const [selectedItems, setSelectedItems] = useState({nodes: [], edges: []})


  const [fields, setFields] = useState([])
  const [hoveredItem, setHoveredItem] = useState()
  const [showPopup, setShowPopup] = useState(true)
  const [showGraphOptions, setShowGraphOptions] = useState(false)
  const [options, setOptions] = useState([])
  const [optionsNodeSize, setOptionsNodeSize] = useState([])
  const [optionNodeSize, setOptionNodeSize] = useState(queryParams.nodeSize || 'none')
  const [zoom, setZoom] = useState(defaultZoom)

  const cyRef = useRef()
  const sectionRef = useRef([])

  const {showTooltip, hideTooltip} = useTooltip();

  const {elements, minValue, maxValue} = useMemo(() => {
    
    let tmp = formatRelationships(data)

    let minValue = Number.POSITIVE_INFINITY
    let maxValue = Number.NEGATIVE_INFINITY

    if (tmp) {
      switch (optionNodeSize) {
        case 'field_count':     
          tmp = tmp.map(item => {

            const value = item.data.field_count
            const A = value / item.data.field_count_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = value + " Fields in this Dataset"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
        case 'relationship_count': 

          let relationship_count_max = 0

          tmp.forEach(item => {
            if (relationship_count_max < item.data.relationship_count) {
                relationship_count_max = item.data.relationship_count
            }
          })

          tmp = tmp.map(item => {
            const value = item.data.relationship_count
            const A = value / relationship_count_max
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = value + " related Datasets"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          }) 

          break
        case 'documentation_level': 
          tmp = tmp.map(item => {
            const value = item.data.field_description_ratio
            const A = item.data.field_description_ratio
            const Dr = 2*Math.sqrt( (1-A)*(Dmin/2)**2 + A*(Dmax/2)**2 )
            const nodeTooltip = Math.floor(value*100) + "% Documentation Level"

            if (minValue > value ) { minValue = value }
            if (maxValue < value ) { maxValue = value }
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
        default:
          tmp = tmp.map(item => {
            const Dr = 20
            const nodeTooltip = item.data.relationship_count + " related Datasets"
            return {...item, data:{...item.data, nodeSize: Dr, nodeTooltip}}
          })  
          break
      }
    }

    return {elements:tmp, minValue, maxValue}
    
  }, [data, loading, optionNodeSize])

  useEffect( () => {
    if (data) {
      const allOptions = getAllOptions()
      setOptions(allOptions.allOptions)
      setOptionsNodeSize(getNodeSizeOptions())
    }
  }, [data])

  useEffect( () => {

    // Set width of all tables and descriptions in the popup to be the same as the widest table
    if (sectionRef.current && sectionRef.current.length > 0) {
      
      sectionRef.current.forEach(ref => {
        if (ref) {
          ref.table.style.width = '' //reset width
        }
      })

      const width = Math.max(...sectionRef.current.map(x => x && x.table.clientWidth))
      sectionRef.current.forEach(ref => {
        if (ref) {
          ref.description.style.maxWidth = width + "px"
          ref.table.style.width = width + "px"
        }
      })
    }
  }, [selectedItems])

  useEffect( () => {
    // Restore selection from history
    if (queryParams.selectedItems && cyRef.current) {
      queryParams.selectedItems.nodes && queryParams.selectedItems.nodes.forEach(id => {
        cyRef.current.nodes("[id = '"+id+"']").select()
      })

      queryParams.selectedItems.edges && queryParams.selectedItems.edges.forEach(id => {
        cyRef.current.edges("[id = '"+id+"']").select()
      })  
    }

  }, [loading])

  const handleOnReady = useCallback(() => {
      setLoading(false)
  }, [])

  const togglePopup = useCallback((show) => {
    const state = show || !showPopup
    setShowPopup(state)
  }, [showPopup])

  const toggleGraphOptions = (show) => {
    const state = show || !showGraphOptions
    setShowGraphOptions(state)
  }

  const resetZoomPan = (e) => {
    e.preventDefault()
    //cyRef.current.fit()
    //cyRef.current.zoom(defaultZoom)

    cyRef.current.animate({
      center: { eles: selectedItems.nodes.length > 0 ? cyRef.current.nodes("[id='" + selectedItems.nodes[0].nodeId + "']") : cyRef.current },
      zoom: defaultZoom,
      duration: 150,
      easing: 'ease-out'
    })
  }

  const handleZoom = (e) => {
    e.preventDefault()
    let {value} = e.target
    value = parseFloat(value)
    
    if (value >= 0.9 && value <= 1.1) {
      value = defaultZoom
    }

    cyRef.current.zoom(value)
  }

  const handleInputChange = (event) => {
    const value = event.value
    const name = event.name
    const objectName = event.option.secondaryLabel
    cyRef.current.nodes().unselect()
    cyRef.current.edges().unselect()
   
    switch (name) {
      case 'graph_filter':
        switch (objectName) {
          case 'Dataset':
            cyRef.current.nodes("[id = '"+value+"']").select()
            break
          case 'Field':
            cyRef.current.edges("[source_field_name = '"+value+"'],[target_field_name = '"+value+"']").select()
            break
          default:
            break
        }
        break
      case 'node_size':
        setOptionNodeSize(value)
        setHistory({nodeSize: value})
        setNodeSizes(value)
        break
      default:
        break
    }
  }

  const handleMouseEnterRow = useCallback((e, id) => {

    selectedItems.edges && selectedItems.edges.forEach(edge => {
      edge.edge.addClass('mouseover-table-blurred-edge')
    })
    selectedItems.nodes && selectedItems.nodes.forEach(node => {
      node.neighborEdgesObj.edges().addClass('mouseover-table-blurred-edge')
    })
    cyRef.current.edges("[id = '"+id+"']").addClass('mouseover-table-highlighted-edge')
  }, [selectedItems, cyRef])

  const handleMouseLeaveRow = useCallback((e, id) => {
    cyRef.current.edges().removeClass('mouseover-table-blurred-edge')
    cyRef.current.edges("[id = '"+id+"']").removeClass('mouseover-table-highlighted-edge')
  }, [cyRef])

  const handleMouseEnterOption = (e, option) => {
    const id = option.value
    const objectType = option.secondaryLabel

    cyRef.current.edges().addClass("mouseover-blurred-edge")
    cyRef.current.nodes('.node').addClass("mouseover-blurred-node")

    switch (objectType) {
      case 'Dataset':    
        cyRef.current.nodes("[id = '"+id+"']").addClass('mouseover-highlighted-node')    
        cyRef.current.nodes("[id = '"+id+"']").neighborhood('node').addClass('mouseover-neighbor-node')
        cyRef.current.nodes("[id = '"+id+"']").neighborhood('edge').addClass('mouseover-highlighted-edge')
        
        // Center viewport on selected node
        cyRef.current.animate({
          center: { eles: cyRef.current.nodes("[id = '"+id+"']")},
          duration: 150,
          easing: 'ease-out'
        })
        break
      case 'Field':
        cyRef.current.edges("[source_field_name = '"+id+"'],[target_field_name = '"+id+"']").addClass('mouseover-highlighted-edge')
        cyRef.current.edges("[source_field_name = '"+id+"'],[target_field_name = '"+id+"']").connectedNodes().addClass('mouseover-neighbor-node')

        // Center viewport on selected edge
        cyRef.current.animate({
          center: { eles: cyRef.current.edges("[source_field_name = '"+id+"'],[target_field_name = '"+id+"']")},
          duration: 150,
          easing: 'ease-out'
        })
        break
      default:
        break
    }
    
  }

  const handleMouseLeaveOption = (e, option) => {
    cyRef.current.clearQueue() // clear animation queue
    cyRef.current.edges().removeClass('mouseover-blurred-edge')
    cyRef.current.nodes('.node').removeClass('mouseover-blurred-node')
    cyRef.current.edges().removeClass('mouseover-highlighted-edge')
    cyRef.current.nodes('.node').removeClass('mouseover-neighbor-node')
    cyRef.current.nodes('.node').removeClass('mouseover-highlighted-node')

  }

  const handleSelectItems = useCallback((items) => {
    if (items) { 
      
      let edges = []
      let nodes = []

      if (items.nodes && items.nodes.length > 0) {

        items.nodes.forEach(node => {

          const data = node.data()
          const nodeId = data.id
          const neighborEdgesObj = node.neighborhood('edge')

          const neighborEdges = neighborEdgesObj.map(el => {return el.data()}).map(item => {
            if (nodeId === item.source) {
              return {
                ...item,
                direction:                   "arrow_right",
                dataset_name:                item.source_dataset_name,
                datatype_category:           item.source_datatype_category,
                field_name:                  item.source_field_name,
                dataset_description:         item.source_dataset_description,
                datatype_name:               item.source_datatype_name,
                field_description_description:          item.source_field_description_description,
                field_id:                    item.source_field_id,
                dataset_id:                  item.source,
                dataset_group_id:            item.source_dataset_group_id,
                datasource_id:               item.source_datasource_id,
                system_id:                   item.source_system_id,   
                related_dataset_name:        item.target_dataset_name,
                related_dataset_type_name:   item.target_dataset_type_name,
                related_datatype_category:   item.target_datatype_category,
                related_field_name:          item.target_field_name,
                related_dataset_description: item.target_dataset_description,
                related_datatype_name:       item.target_datatype_name,
                related_field_description_description:   item.target_field_description_description,
                related_field_id:            item.target_field_id,
                related_dataset_id:          item.target,
                related_dataset_group_id:    item.target_dataset_group_id,
                related_datasource_id:       item.target_datasource_id,
                related_system_id:           item.target_system_id, 
              }
            } else {
              return {
                ...item,
                direction:                   "arrow_left",
                dataset_name:                item.target_dataset_name,
                datatype_category:           item.target_datatype_category,
                field_name:                  item.target_field_name,
                dataset_description:         item.target_dataset_description,
                datatype_name:               item.target_datatype_name,
                field_description_description:          item.target_field_description_description,
                field_id:                    item.target_field_id,
                dataset_id:                  item.target,
                dataset_group_id:            item.target_dataset_group_id,
                datasource_id:               item.target_datasource_id,
                system_id:                   item.target_system_id, 
                related_dataset_name:        item.source_dataset_name,
                related_dataset_type_name:   item.source_dataset_type_name,
                related_datatype_category:   item.source_datatype_category,
                related_field_name:          item.source_field_name,
                related_dataset_description: item.source_dataset_description,
                related_datatype_name:       item.source_datatype_name,
                related_field_description_description:   item.source_field_description_description,
                related_field_id:            item.source_field_id,
                related_dataset_id:          item.source,
                related_dataset_group_id:    item.source_dataset_group_id,
                related_datasource_id:       item.source_datasource_id,
                related_system_id:           item.source_system_id, 
              }
            }
          })
          nodes = [...nodes, ...[{nodeId, node, data, neighborEdges, neighborEdgesObj}]]
        })
      }

      if (items.edges && items.edges.length > 0) {

        items.edges.forEach(edge => {
          
          const item = edge.data()
          const data = {
              ...item,
              direction:                   "arrow_right",
              dataset_name:                item.source_dataset_name,
              dataset_type_name:           item.source_dataset_type_name,
              datatype_category:           item.source_datatype_category,
              field_name:                  item.source_field_name,
              dataset_description:         item.source_dataset_description,
              datatype_name:               item.source_datatype_name,
              field_description_description:          item.source_field_description_description,
              field_id:                    item.source_field_id,
              dataset_id:                  item.source,
              dataset_group_id:            item.source_dataset_group_id,
              datasource_id:               item.source_datasource_id,
              system_id:                   item.source_system_id,   
              related_dataset_name:        item.target_dataset_name,
              related_dataset_type_name:   item.target_dataset_type_name,
              related_datatype_category:   item.target_datatype_category,
              related_field_name:          item.target_field_name,
              related_dataset_description: item.target_dataset_description,
              related_datatype_name:       item.target_datatype_name,
              related_field_description_description:   item.target_field_description_description,
              related_field_id:            item.target_field_id,
              related_dataset_id:          item.target,
              related_dataset_group_id:    item.target_dataset_group_id,
              related_datasource_id:       item.target_datasource_id,
              related_system_id:           item.target_system_id, 
              edge:                        edge
            }

            edges = [...edges, ...[data]]
        })
      }

      togglePopup(true)

      setSelectedItems(prev => { 
        return {
          nodes: items.nodes && nodes, 
          edges: items.edges && edges
        }  
      })

      setHistory({selectedItems: {
          nodes: items.nodes && nodes && nodes.map(x => x.nodeId), 
          edges: items.edges && edges && nodes.map(x => x.id)
        }  
      })

    } else { // Deselect items, i.e. none is selected
      togglePopup(false)
      setSelectedItems({nodes: [], edges: []})
    }

  }, [])

  const handleHoveredItem = useCallback((item) => {
    
    setHoveredItem(item)
    
    const tooltipBodyEdge = <React.Fragment>{item && item.edges && <div className="graph-tooltip">
                    <div className="graph-tooltip-edge-source">
                      <div className="first-row">
                        <Icon name={item.edges[0].source_datatype_category} />
                        <div>{item.edges[0].source_field_name}</div>
                      </div>
                      <div className="second-row">{item.edges[0].source_dataset_name}</div>
                    </div>
                    <div className="graph-tooltip-edge-direction">
                      <Icon name="arrow-right" />
                    </div>
                    <div className="graph-tooltip-edge-target">
                      <div className="first-row">
                        <Icon name={item.edges[0].source_datatype_category} />
                        <div>{item.edges[0].target_field_name}</div>
                      </div>
                      <div className="second-row">{item.edges[0].target_dataset_name}</div>
                    </div>
                  </div>}</React.Fragment>

      const tooltipBodyNode =  <React.Fragment>{item && item.nodes && <div className="graph-tooltip">
                    <div className="graph-tooltip-node">
                      <div className="first-row">
                        <Icon name={item.nodes[0].dataset_type_name} />
                        <div>{item.nodes[0].label}</div>
                      </div>
                      <div className="tooltip-subtitle">
                        <div>{item.nodes[0].nodeTooltip}</div>
                      </div>
                      { item.nodes[0].dataset_description &&
                        <div className="tooltip-body">{textareaToPlainText(item.nodes[0].dataset_description)}</div>
                      }
                    </div>
                  </div>} </React.Fragment>

      if (item) {
        if (item.edges) {
          showTooltip({tooltipBody: tooltipBodyEdge})
        } else {
          showTooltip({tooltipBody: tooltipBodyNode})
        }
      } else if (!item) {
        hideTooltip()
      }
  }, [])

 const setNodeSizes = (nodeSize) => {
  switch (nodeSize) {
    case 'field_count': 

      break
    case 'relationship_count': 

      break
    case 'documentation_level': 

      break
    default:

      break
  }
 }

  const getNodeSizeOptions = () => {

    const tmp = Object.keys(nodeSizeOptions).map( key => { return { value: key, label: nodeSizeOptions[key].label, secondaryLabel: nodeSizeOptions[key].secondaryLabel}})

    return formatSelectOptions({options: tmp, optionValue: "value", optionLabel: "label", optionSecondaryLabel: "secondaryLabel", tooltip:"secondaryLabel"})
    
  }

  const getAllOptions = () => {
    
    const allFields = new Array()

    elements.forEach(item => {
      if (item.data.type !== 'compound') {
        allFields.push({value: item.data.source_field_name || item.data.id, optionLabel: item.data.source_field_name || item.data.object_name, optionSecondaryLabel: item.data.object_type, optionIcon: item.data.object_icon, tooltip: item.data.object_description})
        allFields.push({value: item.data.target_field_name || item.data.id, optionLabel: item.data.target_field_name || item.data.object_name, optionSecondaryLabel: item.data.object_type, optionIcon: item.data.object_icon, tooltip: item.data.object_description})
      }
    })

    const uniqueFields = [...new Map(allFields.map(item => [item['value'], item])).values()];

    const allOptions = formatSelectOptions({options: uniqueFields, optionValue: "value", optionLabel: "optionLabel", optionSecondaryLabel: "optionSecondaryLabel", optionIcon: "optionIcon", tooltip: "tooltip"})

    const allSelectedOptions = allOptions.map(x => {return {value: x.value}})

    return {allOptions, allSelectedOptions }
  }

  return (
    <div className="RelationshipGraph">
    { loading  
      ? <div className="loading-graph">
          <GraphLoadingSpinner />
          <div className="loading-graph-text">Drawing the graph...</div>
        </div>
      : <React.Fragment>
      
        <PopupTableContainer
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems} 
          fields={fields}
          setFields={setFields}
          onMouseEnterRow={handleMouseEnterRow}
          onMouseLeaveRow={handleMouseLeaveRow}
          togglePopup={togglePopup}
          showPopup={showPopup}
          refs={sectionRef}
        />

        <div className="graph-controls">
          <div className="filter-container graph-control">
            <Select 
              className="graph-filter"
              name="graph_filter"
              value=''
              options={ options }
              icon='search'
              iconAsButton={true}
              onChange={handleInputChange}
              onMouseEnterOption={handleMouseEnterOption}
              onMouseLeaveOption={handleMouseLeaveOption}
              placeholder="Search assets..."
              title="Search assets in the graph"
              isClearable={true}
            />
          </div>
          
          <div className="graph-control">
            <Slider 
              name="zoom" 
              min={minZoom} 
              max={maxZoom} 
              step="0.01" 
              value={zoom} 
              onChange={handleZoom} 
              orientation='vertical'
              tooltip="Zoom" 
            />
          </div>

          <div className="graph-control">
            <Button 
              onClick={resetZoomPan} 
              value="" 
              tooltip="Reset pan and zoom" 
              icon="fit" 
              buttonStyle="white" 
            />
          </div>

          <div className="filter-container graph-control">
            <Select 
                className="graph-filter"
                name="node_size"
                value={optionNodeSize}
                options={ optionsNodeSize }
                onChange={handleInputChange}
                icon='node-size'
                iconAsButton={true}
                expandedByDefault={false}
                placeholder="Search assets..."
                title="Select node size measure"
                isClearable={true}
              />
          </div>

        </div>

        <Graph 
          data={elements} 
          setSelectedItems={handleSelectItems} 
          setHoveredItem={handleHoveredItem} 
          onReady={handleOnReady}
          nodeSize={optionNodeSize}
          minZoom={minZoom} 
          maxZoom={maxZoom} 
          setZoom={setZoom} 
          zoom={defaultZoom} 
          ref={cyRef} 
        />

        { optionNodeSize !== 'none' &&
          <div className="graph-legend">
            <div className="graph-legend-text">
              Size shows {nodeSizeOptions[optionNodeSize].label}
            </div>
            <div className="graph-legend-icon">
              <div className="graph-legend-circle-max">
                <div className="graph-legend-value">{ optionNodeSize === 'documentation_level' ? Math.floor(maxValue*100) + "%" : maxValue}</div>
                <div className="graph-legend-max" style={{width: zoom * Dmax + "px", height: zoom * Dmax + "px"}}></div>
              </div>
              <div className="graph-legend-circle-min">
                <div className="graph-legend-value">{optionNodeSize === 'documentation_level' ? Math.floor(minValue*100) + "%" : minValue}</div>
                <div className="graph-legend-min" style={{width: zoom * Dmin + "px", height: zoom * Dmin + "px"}}></div>
              </div>
            </div>
          </div>
        }

        

      </React.Fragment>

    }
    </div>
  )
}