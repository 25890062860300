import React, { useState, useEffect } from 'react';
import { systemService } from '../_services';
import { DataTable, SideDialog } from '../_components';
import { useSideDialog, useModalDialog, useGlobalState, usePhotos } from '../_hooks';
import { Role } from '../_helpers';

export const Systems = ({ setMessage, setModalDialog, cancelModalDialog, abortFetch }) => {
  
  const [systems, setSystems] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser')
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const { action, cancelSideDialog, showSideDialog } = useSideDialog(setSelectedItems)
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()
  
  useEffect(() => {

    fetchItems()
    
  }, []);

  const fetchItems = async () => {
    const promise = new Promise(  (resolve, reject) => {
      systemService.getAll(abortFetch)
        .then(res => {
          setSystems(res.systems)
          setLoading(false)
          resolve(res.systems)
        })
        .catch(err => {
          setMessage(JSON.stringify(err), 'error')
          setLoading(false)
        });
    })

    Promise.all([promise, fetchFieldStatistics()])
      .then( result => {
        updateFieldStatistics(result[0], result[1])
        fetchPhotos(result[0], 'system_owner_user_id', setSystems)
      })
      .catch(err => { setMessage(JSON.stringify(err), 'error')})
  }

  const fetchFieldStatistics = async () => {
    return new Promise(  (resolve, reject) => {
      systemService.getFieldStatistics(abortFetch)
        .then(res => {

          resolve(res.field_statistics)


        })
        .catch(err => {
          return null
        })
    })
  }

  const updateFieldStatistics = (data, statistics) => {

    let dataTmp = data
    let statistic = {}
    let tmp = null

    let maxValue = 0

    statistics.forEach( row => {
      if (row.dataset_count > maxValue) {
        maxValue = row.dataset_count
      }
    })

    data.forEach( (row, index) => {
      statistic = statistics.find(x => x.system_id === row.system_id)
      tmp = dataTmp[index]
      dataTmp[index] = {
        ...tmp, 
        dataset_count: (statistic ? statistic.dataset_count : 0), 
        field_description_ratio: (statistic ? statistic.field_description_ratio : 0),
        dataset_count_max: maxValue
      }
    })

    setSystems([...dataTmp]);
  }

  const addSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.create(systems, abortFetch)
      .then(async res => {

        fetchItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const editSystem = async data => {
    const systems = {systems: (Array.isArray(data) ? data : [data])}
    
    await systemService.update(systems, abortFetch)
      .then(async res => { 

        fetchItems()
        setMessage(res.message)
        clearSelectedItems()
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteSystem = async data => {    
    setModalDialog(prev => {return {...prev, loading: true}})    

    await systemService.destroy({ systems: data }, abortFetch)
      .then(async res => { 
        
        fetchItems()
        cancelModalDialog()
        clearSelectedItems()
        setMessage(res.message)

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }
  
  const editable = loggedInUser && loggedInUser.user_role_name === Role.admin

  const SideDialogForm = editable ? action.Component : undefined

  return (
    <div className="Systems wide-margin">

        <DataTable
          columns={[
            {id: 'system_name', name: 'Name', link: '/browse_datasets/:system_id', tooltip: 'system_description', className:"bold"},
            {id: 'system_type', name: 'Type'},
            {id: 'dataset_count', name: 'Datasets', type: 'chart', chartType: 'bar', data: {data:'dataset_count', max: 'dataset_count_max'}},
            {id: 'field_description_ratio', name: 'Documentation Level', type: 'chart', chartType: 'percent-bar', data: {data:'field_description_ratio'}},
            {id: 'user_photo', name: '', type: 'user-photo', link: '/users/:system_owner_user_id', tooltip: 'system_owner_user_fullname'},
            {id: 'system_owner_user_fullname', name: 'Owner', link: '/users/:system_owner_user_id'}
          ]}
          buttons={[ 
            {label: "Add +", action: "add", mainButton: true, onClick: (items) => showSideDialog('addSystem', items || selectedItems, addSystem) },
            {label: "Edit", action: "edit", onClick: (items) => showSideDialog('editSystem', items || selectedItems, editSystem) },
            {label: "Delete", action: "delete", onClick: (items) => showModalDialog('deleteSystem', items || selectedItems, deleteSystem) }
          ]}
          title="Systems"
          data={systems}
          idColumn='system_id'
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          loading={loading}
          editable= {editable}
          filterable={true}
          filterObjects={['system_name', 'system_type', 'system_owner_user_fullname']}
        />

        <SideDialog 
          mode={action.mode}
          title={action.title}
          closeDialog={ action.cancelForm }
          >
            {SideDialogForm &&
              <SideDialogForm {...action} />
            }
        </SideDialog>

      </div>
  );
}