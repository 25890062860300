import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { dataset_groupTypeService, datasourceService, userService } from '../_services';
import { Checkbox, Select, formatSelectOptions, Input, Textarea, textareaInitialState, textareaToRaw } from '../_components';
import { formatConnectionString, getBatchEditValue } from '../_helpers'
import { useGlobalState } from '../_hooks'

export const AddDatasetGroupForm = ({ mode, data, submitForm, cancelForm }) => { 
	
	const initialFormState = {
		dataset_group_id: null, 
		dataset_group_name: '',
		dataset_group_source_name: '',
		dataset_group_description: textareaInitialState({}), 
		datasource_id: '',
		dataset_group_owner_user_id: ''
	}

	const initialBatchEditItems = {
		batch_datasource_id: false,
		batch_dataset_group_owner_user_id: false
	}

	const [hasError, setErrors] = useState(false);
	const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
	const [currentDatasource, setCurrentDatasource] = useGlobalState('currentDatasource');
	const [formData, setFormData] = useState(initialFormState);
	const [batchEdit, setBatchEdit] = useState(false);
	const [batchEditItems, setBatchEditItems] = useState(initialBatchEditItems)
	const [loading, setLoading] = useState(false);
	const [dataset_groupTypes, setDatasourceTypes] = useState([]);
	const [datasources, setDatasources] = useState([]);
	const [users, setUsers] = useState([]);

	const { system_id } = useParams()

	useEffect( () => {

		// Get form data
  	fetchDatasources()
  	fetchUsers()

	}, []);

	useEffect(() => {

		setBatchEdit(false)

	    if (data.length === 1) {
			setFormData({ 
				dataset_group_id: data[0].dataset_group_id, 
				dataset_group_name: data[0].dataset_group_name || '',
				dataset_group_source_name:  data[0].dataset_group_source_name || '',
				dataset_group_description: textareaInitialState({value: data[0].dataset_group_description}), 
				datasource_id: data[0].datasource_id,
				dataset_group_owner_user_id: data[0].dataset_group_owner_user_id
			})
		} else if(data.length > 1 && mode !== 'add') {
			setBatchEdit(true)

			setFormData({ 
				datasource_id: getBatchEditValue(data, 'datasource_id'),
				dataset_group_owner_user_id: getBatchEditValue(data, 'dataset_group_owner_user_id')
			})

		} else if(mode === 'add') {
			setFormData({ 
				...formData, 
				dataset_group_owner_user_id: loggedInUser?.user_id,
				datasource_id: currentDatasource.datasource_id
			})
		} else {
			resetForm()		
		}
		
	}, [mode, data, loggedInUser, currentDatasource]);

	const fetchDatasources = async () => {
	    datasourceService.getBySystemId(system_id)
	      .then(res => { 

	      	setDatasources(res.datasources); 

	      })
	      .catch(err => {setErrors(err)});
	  }

	const fetchUsers = async () => {
	    userService.getAll()
	      .then(res => { 

	      	setUsers(res.users); 

	      })
	      .catch(err => {setErrors(err)});
	  }
  
	const handleInputChange = (event, formPart) => {
		let { name, value } = ""

		// Handle standard form inputs
		if (event.target !== undefined) {
		  name = event.target.name
		  value = event.target.value

		  // If input element is a checkbox, we cannot use "value"
	    if (event.target.type === "checkbox") { value = event.target.checked }

	  // Handle custom form inputs
	  } else {
	  	name = event.name
	  	value = event.value
	  }

	  switch(formPart) {
	  	case 'batch':
	  		setBatchEditItems({ ...batchEditItems, [name]: value })
	  		break
	  	default:
	  		setFormData({ ...formData, [name]: value })
	  }
	  
	}

	const onSubmit = event => {

		event.preventDefault()

		// Form validation
		//if (!formData.user_fullname || !formData.user_username) return

		setLoading(true)

		let dataToSubmit

		if (batchEdit) {
			
			let key = ''
			let tmp = {}
			const objects = Object.entries(batchEditItems)

			for (let i = 0; i < objects.length; i++) {
				if (objects[i][1]) {
					key = objects[i][0].replace("batch_","")
					tmp = {...tmp, [key]: formData[ key ]}
				}
			}

			dataToSubmit = data.map( item => {
				return {dataset_group_id: item.dataset_group_id, 
								dataset_group_name: item.dataset_group_name,
								dataset_group_source_name: item.dataset_group_source_name,
								datasource_id: item.datasource_id,
								dataset_group_owner_user_id: item.dataset_group_owner_user_id,
								...tmp
							}
			})
			
		} else {
			dataToSubmit = {
				...formData,
				dataset_group_description: textareaToRaw({value: formData.dataset_group_description}),
			}
		}
		
		// submit form
		submitForm(dataToSubmit)
		.then(res => { 
			setLoading(false)
			if (mode === 'add') {
				resetForm()
			} else {
				cancel()
			}
		})
		.catch(err => {
			setLoading(false)
		})	    
		    
	}

  const cancel = () => {
  	cancelForm()
  }

  const resetForm = () => {

  	setBatchEdit(false)
  	setBatchEditItems(initialBatchEditItems)
  	setFormData(prevFormData => {
			return {...initialFormState,
							datasource_id: (currentDatasource ? currentDatasource.datasource_id : null)
						}
		}) 
  }

  const datasource = (datasources.length > 0) ? datasources.find(x => x.datasource_id === formData.datasource_id) : undefined
  return (
  	<div className="form">
	    <form
			  onSubmit={onSubmit}
			>
				{ !batchEdit &&
				<div className="form-block vertical">
				      <Input type="text" 
				      	name="dataset_group_name" 
				      	label="Name"
				      	value={formData.dataset_group_name} 
				      	onChange={handleInputChange}
				      	disabled={loading}
				      />
			      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
		      <Input type="text" 
		      	name="dataset_group_source_name" 
		      	label="Source Name"
		      	value={formData.dataset_group_source_name} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		      <div className="form-light-text"> 
		      	{ datasource &&
							formatConnectionString(
								datasource.datasource_database, 
								datasource.datasource_hostname, 
								[formData.dataset_group_source_name], 
								datasource.datasource_type_code, 
								datasource.datasource_type_category
							)
		       	}
		      </div>
	      </div>
				}
				{ !batchEdit &&
				<div className="form-block vertical">
					<label>Description</label>
					<Textarea
		      	name="dataset_group_description" 
		      	value={formData.dataset_group_description} 
		      	onChange={handleInputChange}
		      	disabled={loading}
		      />
		    </div>
		    }

		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_datasource_id}
									name="batch_datasource_id"
									label="Edit Datasource"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Datasource</label>
					}
					<Select 
	          name="datasource_id"
	          value={formData.datasource_id === 'multiple' ? undefined : formData.datasource_id}
	          options={ formatSelectOptions({options: datasources, optionValue: "datasource_id", optionLabel: "datasource_name", optionIcon: "datasource_type_category", tooltip: "datasource_description"}) }
	          onChange={handleInputChange} 
	          placeholder={formData.datasource_id === 'multiple' && '< Multiple >'}
	          disabled={loading || (batchEdit && !batchEditItems.batch_datasource_id)}
	        />
		    </div>

		    <div className="form-block vertical">
					{ batchEdit 
						? <label>
								<Checkbox 
									value={batchEditItems.batch_dataset_group_owner_user_id}
									name="batch_dataset_group_owner_user_id"
									label="Edit Owner"
									onChange={ e => handleInputChange(e, 'batch')}
									disabled={loading}
								/>
							</label>
						: <label>Owner</label>
					}
					<Select 
            name="dataset_group_owner_user_id"
            value={formData.dataset_group_owner_user_id === 'multiple' ? undefined : formData.dataset_group_owner_user_id}
            options={ formatSelectOptions({options: users, optionValue: "user_id", optionLabel: "user_fullname", tooltip: "user_username"}) }
            onChange={handleInputChange}
            placeholder={formData.dataset_group_owner_user_id === 'multiple' && '< Multiple >'}
            disabled={loading || (batchEdit && !batchEditItems.batch_dataset_group_owner_user_id)} 
          />
	      </div>

	      <div className="form-block horizontal">
					<button 
						type="submit" 
						className={"button main-button" + (loading ? ' loading' : '')} 
	          disabled={loading}>
	          <span>{loading ? 'SAVING...' : ((mode === 'edit') ? 'SAVE' : 'ADD') }</span>
	        </button>
					<button 
						type="button" 
						className="button" 
						onClick={cancel}
						disabled={loading}>
						<span>CANCEL</span>
					</button>
				</div>

	    </form>
	  </div>
  )
}
