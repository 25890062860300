import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { authenticationService, userService } from '../_services'
import { Input } from '../_components'
import { useGlobalState } from '../_hooks'

export const ChangePassword = ({fullscreenError, setFullscreenError, resetFullscreenError, setMessage}, props) => {
  
  const initialFormState = {
    old_password: '', 
    new_password: '',
    confirm_new_password: ''
  }

  const [formData, setFormData] = useState(initialFormState)
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [time, setTime] = useState(5)
  const [startTimer, setStartTimer] = useState(false)

  const navigate = useNavigate()
  useEffect(() => {

    // Redirect to start page automatically when timer reached 0
    if (time === 0) { 
      goToLogin()
    }  
  }, [time]);


  useEffect(() => {

    // Start timer for auto redirect
    if (startTimer) {
      setInterval(() => {setTime(prev => prev-1)}, 1000);
    }
  }, [startTimer]);

  const handleInputChange = (event) => {
    const { value, name } = event.target

    resetFullscreenError()
  
    setFormData({ ...formData, [name]: value })
  }

  const onSubmit = async (event) => {

    event.preventDefault();
    setLoading(true)
    resetFullscreenError()
    
    // Validation
    if (formData.new_password !== formData.confirm_new_password) {
      setFullscreenError({
              isError: true,
              errorTitle: "",
              errorText: "New passwords does not match"
            })
      setLoading(false)
      return null
    }
    if (formData.new_password === formData.old_password) {
      setFullscreenError({
              isError: true,
              errorTitle: "",
              errorText: "New password cannot be the same as the old one"
            })
      setLoading(false)
      return null
    }

    const users = {
      user_username: loggedInUser.user_username,
      old_password: formData.old_password,
      new_password: formData.new_password
    }
    
    await userService.changePassword(users)
      .then(async res => { 
        setLoading(false)
        setSuccess(true)
        setStartTimer(true);
      })
      .catch(error => {
        setLoading(false)

        let errorTitle = undefined
        let errorText = undefined

        if (error.dataValidationError) {
          error.dataValidationError.forEach( err => {
            errorText = <React.Fragment>{errorText}<br/>{err.msg}</React.Fragment>
          })
        } else {
          errorTitle = error.message
          errorText = error.error
        }

        setFullscreenError({
          isError: true,
          errorTitle: errorTitle,
          errorText: errorText
        })
      });

  }

  const goToLogin = () => {
    authenticationService.logout()
    navigate('/')
  }

  const isError = ((fullscreenError.isError) ? "show" : "")
  
  const validateNewPassword = () => {
    if (formData.new_password.length > 0) {
      if (formData.new_password.length < 12) {
        return { valid: false, message: "Password must be at least 12 characters long"} 
      }

      if (formData.new_password === formData.old_password) {
        return { valid: false, message: "New password cannot be the same as the old one"} 
      }

      return { valid: true, message: "Password ok"} 
    }
  }

  return (
    <div className="ChangePassword fullscreen">
      <div className="fullscreen-content">
      
      { (loggedInUser && !loggedInUser.password_change_required) &&
      <div className="close-button" onClick={ () => navigate(-1) }>
        <div className="close-button-label">Cancel</div>
        <div className="close-button-icon"></div>
      </div>
      }

      <div className="fullscreen-logo"></div>

      { success 
        ? 
          <React.Fragment>
            <h1>Password Changed!</h1>
            <p>&nbsp;</p>
            <p>Redirecting to login page in {time} seconds...</p>
            <p>&nbsp;</p>
            <button type="button" className="button main-button" onClick={goToLogin}><span>Go to Login</span></button>
          </React.Fragment>
      : 
        <React.Fragment>
          <h1>{ (loggedInUser && loggedInUser.password_change_required) ? "Please change your password to proceed" : "Change password"}</h1>
          <p>&nbsp;</p>
          <form onSubmit={onSubmit}>
            <Input
              type="password"
              name="old_password"
              className="large"
              label="Old password"
              labelAsPlaceholder={true}
              value={formData.old_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              required={true}
            />
            <Input
              type="password"
              name="new_password"
              className="large"
              label="New password"
              labelAsPlaceholder={true}
              value={formData.new_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              required={true}
              validation={ validateNewPassword() }
            />
            <Input
              type="password"
              name="confirm_new_password"
              className="large"
              label="Confirm new password"
              labelAsPlaceholder={true}
              value={formData.confirm_new_password}
              onChange={handleInputChange}
              disabled={loading}
              autoComplete="new-password"
              validation={ formData.confirm_new_password.length > 0 && (
                (formData.confirm_new_password !== formData.new_password) 
                  ? { valid: false, message: "Passwords does not match"} 
                  : { valid: true, message: "Passwords match"} 
                )}
            />
            
            <p>&nbsp;</p>
            
            <button 
              type="submit" 
              className={"button large-button" + (loading ? ' loading' : '')}
              disabled={loading}
              ><span>{loading ? 'Saving...' : 'Save'}</span></button>
          </form>

          <div className={"error-msg " + isError }>
            <div className="error-title">{ fullscreenError.errorTitle }</div>
            <div className="error-text">{ fullscreenError.errorText }</div>
          </div>

          
        </React.Fragment>
      }

      </div>
    </div>
  )

}