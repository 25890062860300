import config from '../config';
import { authHeader, customFetch, jsonHeader, handleResponse, commonRequestOptions, subjects } from '../_helpers';

const endpointPath='business_term'

export const businessTermService = {
    getAll,
    getById,
    getByGlossaryId,
    create,
    update,
    checkDestroy,
    destroy
};

function getAll(signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/all`, requestOptions, signal).then(handleResponse);
}

function getById(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/${id}`, requestOptions, signal)
    .then(handleResponse)
    .then(res => {
        subjects.currentBusinessTerm.next(res.business_terms[0])
        subjects.currentGlossary.next({
            glossary_id: res.business_terms[0].glossary_id,
            glossary_name: res.business_terms[0].glossary_name
        })
        return res
    });
}

function getByGlossaryId(id, signal) {
    const requestOptions = { method: 'GET', headers: authHeader(), ...commonRequestOptions() };
    return customFetch(`${config.api.url}/${endpointPath}/glossary/${id}`, requestOptions, signal).then(handleResponse);
}

function create(items, signal) {
    const requestOptions = { 
    	method: 'POST', 
    	headers: {
            ...authHeader(),  
    		...jsonHeader()
    	}, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function update(items, signal) {
    const requestOptions = { 
    	method: 'PUT', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) 
    };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}

function checkDestroy(items, signal) {
    const requestOptions = { 
        method: 'POST', 
        headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
        body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}/check_delete`, requestOptions, signal).then(handleResponse);
}

function destroy(items, signal) {
    const requestOptions = { 
    	method: 'DELETE', 
    	headers: {
            ...authHeader(),  
            ...jsonHeader()
        }, 
        ...commonRequestOptions(),
    	body: JSON.stringify(items) };

    return customFetch(`${config.api.url}/${endpointPath}`, requestOptions, signal).then(handleResponse);
}