import React from 'react';
import { 
  Icon,
  SkeletonLoader } from './_components'
import { Role, subjects } from './_helpers';
import { 
  BusinessTerm,
  Dataset,
  DatasetGroup,
  Datasource,
  Field,
  FieldDescription,
  FieldDescriptions,
  FieldUnits,
  Glossaries,
  Glossary,
  Home,
  Profile,
  Settings,
  System,
  Systems,
  Task,
  Tasks,
  User,
  Users,
  UserGroup,
  UserGroups } from './views';

const currentField = ({ match }) => {
    const res = subjects.currentField.value

    if (!(res === undefined || res === null) && res.field_id.toString() === match.params.field_id) {
      return (
        <span>
        <Icon name={res.datatype_category} tooltip={ res.datatype_name } />
        <span className="breadcrumb-item-text">{res.field_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentDataset = ({ match }) => {
    const res = subjects.currentDataset.value

    if (!(res === undefined || res === null) && res.dataset_id.toString() === match.params.dataset_id) {
      return (
        <span>
        <Icon name={res.dataset_type_name} tooltip={ res.dataset_type_name } />
        <span className="breadcrumb-item-text">{res.dataset_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentDatasetGroup = ({ match }) => {
    const res = subjects.currentDatasetGroup.value

    if (!(res === undefined || res === null) && res.dataset_group_id.toString() === match.params.dataset_group_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.dataset_group_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentDatasource = ({ match }) => {
    const res = subjects.currentDatasource.value

    if (!(res === undefined || res === null) && res.datasource_id.toString() === match.params.datasource_id) {
      return (
        <span>
        <Icon name={res.datasource_type_category} tooltip={ res.datasource_type_name } />
        <span className="breadcrumb-item-text">{res.datasource_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentSystem = ({ match }) => {
    const res = subjects.currentSystem.value

    if (!(res === undefined || res === null) && res.system_id.toString() === match.params.system_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.system_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentFieldDescription = ({ match }) => {
    const res = subjects.currentFieldDescription.value

    if (!(res === undefined || res === null) && res.field_description_id.toString() === match.params.field_description_id) {
      return (
        <span>
        <Icon name={res.field_role_name} tooltip={ res.field_role_name } />
        <span className="breadcrumb-item-text">{res.field_description_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentBusinessTerm = ({ match }) => {
    const res = subjects.currentBusinessTerm.value

    if (!(res === undefined || res === null) && res.business_term_id.toString() === match.params.business_term_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.business_term_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentGlossary = ({ match }) => {
    const res = subjects.currentGlossary.value

    //if (!(res === undefined || res === null) && match.params.glossary_id === 'undefined' || res.glossary_id.toString() === match.params.glossary_id) {
    if (!(res === undefined || res === null) ){//&& res.glossary_id.toString() === match.params.glossary_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.glossary_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentTask = ({ match }) => {
    const res = subjects.currentTask.value

    if (!(res === undefined || res === null) && res.task_id.toString() === match.params.task_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.task_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentUser = ({ match }) => {
    const res = subjects.currentUser.value

    if (!(res === undefined || res === null) && res.user_id.toString() === match.params.user_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.user_fullname}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}

const currentUserGroup = ({ match }) => {

  const res = subjects.currentUserGroup.value

    if (!(res === undefined || res === null) && res.user_group_id.toString() === match.params.user_group_id) {
      return (
        <span>
        <span className="breadcrumb-item-text">{res.user_group_name}</span>
        </span>
        )
    }
    return <SkeletonLoader width="7.5vw" />
}


export const routesConfig = [
  {
    path: "/browse_field_descriptions/:field_description_id",
    breadcrumb: currentFieldDescription,
    element: FieldDescription,
  },
    {
    path: "/browse_field_descriptions",
    breadcrumb: "Browse Field Descriptions",
    element: FieldDescriptions,
  },
  {
    path: "/field_units",
    breadcrumb: "Field Units",
    element: FieldUnits,
    roles: [Role.admin]
  },
  {
    path: "/browse_business_terms",
    breadcrumb: "Browse Business Terms",
    element: Glossaries,
  },
  { 
    path: "/browse_business_terms/:glossary_id", 
    breadcrumb: currentGlossary,
    //tooltip: "Glossary: " + glossaryObservable.currentGlossaryValue.glossary_name,
    element: Glossary
  },
  { 
    path: "/browse_business_terms/:glossary_id/:business_term_id", 
    breadcrumb: currentBusinessTerm,
    element: BusinessTerm
  },
  {
    path: "/browse_datasets",
    breadcrumb: "Browse Datasets",
    element: Systems,
  },
  { 
    path: "/browse_datasets/:system_id", 
    breadcrumb: currentSystem,
    //tooltip: "System: " + systemObservable.currentSystemValue.system_name,
    element: System
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id", 
    breadcrumb: currentDatasource,
    //tooltip: "Datasource: " + datasourceObservable.currentDatasourceValue.datasource_name,
    element: Datasource
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id", 
    breadcrumb: currentDatasetGroup,
    //tooltip: "Dataset Group: " + datasetGroupObservable.currentDatasetGroupValue.dataset_group_name,
    element: DatasetGroup
  },
  { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id", 
    breadcrumb: currentDataset,
    //tooltip: "Dataset: " + datasetObservable.currentDatasetValue.dataset_name,
    element: Dataset
  },
    { 
    path: "/browse_datasets/:system_id/:datasource_id/:dataset_group_id/:dataset_id/:field_id", 
    breadcrumb: currentField,
    //tooltip: "Field: " + fieldObservable.currentFieldValue.field_name,
    element: Field
  },
  {
    path: "/profile",
    breadcrumb: "Profile",
    element: Profile
  },
    {
    path: "/tasks/:task_id",
    breadcrumb: currentTask,
    element: Task,
    roles: [Role.admin]
  },
  {
    path: "/tasks",
    breadcrumb: "Tasks",
    element: Tasks,
    roles: [Role.admin]
  },
  {
    path: "/users/:user_id",
    breadcrumb: currentUser,
    element: User
  },
  {
    path: "/users",
    breadcrumb: "Users",
    element: Users,
    roles: [Role.admin]
  },
  {
    path: "/user_groups/:user_group_id",
    breadcrumb: currentUserGroup,
    element: UserGroup,
    roles: [Role.admin]
  },
  {
    path: "/user_groups",
    breadcrumb: "User Groups",
    element: UserGroups,
    roles: [Role.admin]
  },
  {
    path: "/settings",
    breadcrumb: "Settings",
    element: Settings
  },
  {
    path: "/",
    breadcrumb: "Home",
    element: Home
  }
];
