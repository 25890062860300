export const handleError = ({err, setMessage}, callback) => {

    if (err) {
        try { 

            if (err.name && err.name === "AbortError") {
                return; // Request has been canceled, so do nothing
            }

            setMessage && setMessage(JSON.stringify(err), 'error')
            callback && callback(err)
          
        } catch (e) {
            throw e
        }

    } 
}