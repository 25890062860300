import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { businessTermService, referenceService } from '../_services';
import { Role, Asset } from '../_helpers';
import { 
  MainColumn, 
  ObjectDetails, 
  References, 
  SideDialog,  
  SkeletonLoader, 
  Tabs, 
  TextareaReadOnly, 
  textareaInitialState, 
  fetchToReferencesCount, 
  UserLink } from '../_components';
import { useSideDialog, useModalDialog, useQueryParams, useGlobalState, usePhotos } from '../_hooks';

export const BusinessTerm = ({ setMessage, setModalDialog, cancelModalDialog }) => {

  const { queryParams, setHistory } = useQueryParams()

  const [businessTerm, setBusinessTerm] = useState([]);
  const [loggedInUser, setLoggedInUser] = useGlobalState('loggedInUser');
  const [fromReferences, setFromReferences] = useState([]);
  const [toReferencesCount, setToReferencesCount] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [defaultSelectedTab, setDefaultSelectedTab] = useState({tab: queryParams.tab})

  const { business_term_id } = useParams()

  const { action, cancelSideDialog, showSideDialog } = useSideDialog()
  const { showModalDialog } = useModalDialog(setModalDialog, setMessage, cancelSideDialog)
  const { fetchPhotos } = usePhotos()

  const navigate = useNavigate()
  
  useEffect(() => {

    fetchItems()
    fetchFromReferences()

    fetchToReferencesCount({setMessage, toObjectName: Asset.BusinessTerm, toObjectId: business_term_id})
      .then(res => setToReferencesCount(res))
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
      })

  }, [business_term_id]);

  const fetchItems = async () => {
    
    businessTermService.getById(business_term_id)
      .then(res => {
        setBusinessTerm(res.business_terms[0]);
        setLoading(false);
        fetchPhotos(res.business_terms[0], 'business_term_owner_user_id', setBusinessTerm)
        
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const fetchFromReferences = async () => {
  
    referenceService.getByFromId(Asset.BusinessTerm, business_term_id)
      .then(res => {
   
        setFromReferences(res.references);
        
      })
      .catch(err => {setMessage(JSON.stringify(err), 'error')});
  }

  const editBusinessTerm = async data => {
    const businessTerms = {business_terms: (Array.isArray(data) ? data : [data])}
    
    await businessTermService.update(businessTerms)
      .then(async res => { 

        fetchItems()
        fetchFromReferences()

        clearSelectedItems()
        setMessage(res.message)
      })
      .catch(err => {
        setMessage(JSON.stringify(err), 'error')
        throw err
      });
  }

  const deleteBusinessTerm = async (data) => {    
    setModalDialog(prev => {return {...prev, loading: true}})

    await businessTermService.destroy({business_terms: data})
      .then(async res => { 

        setMessage(res.message)
        cancelModalDialog()

        // Redirect to parent page
        navigate('/browse_business_terms')

      })
      .catch(err => {
        setModalDialog(prev => {return {...prev, loading: false}})
        setMessage(JSON.stringify(err), 'error')
      });
  }

  const clearSelectedItems = () => {
    setSelectedItems([])
  }

  const onTabChange = (tab) => {
    setHistory({'tab': tab})
  }

  const data = loading ? [] : businessTerm

  const editable = loggedInUser && (loggedInUser.user_role_name === Role.admin || loggedInUser.user_role_name === Role.editor)

  const SideDialogForm = editable ? action.Component : undefined

  return (
    <div className="columns narrow-margin">
      <MainColumn>
        <ObjectDetails
          type="Business Term"
          title={data.business_term_name}
          subtitleText={ data && !loading &&
            data.glossary_name 
          }
          loading={loading}
          >

             {!loading 
              ? (!data.business_term_description) 
                ? <span className="no-result-text">No description available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.business_term_description, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }

            <h3>{ !loading ? "Example" : <SkeletonLoader width="5vw"/> }</h3>
            <div className="display-linebreak">
            { !loading 
              ? (!data.business_term_example) 
                ? <span className="no-result-text">No example available</span> 
                : <TextareaReadOnly
                    value={ textareaInitialState({value: data.business_term_example, readOnly: true, descriptions: fromReferences }) } 
                  />
              : <SkeletonLoader width="10vw" count={2} />
            }</div>
            
            <h3>{ !loading ? "Owner" : <SkeletonLoader width="5vw"/>}</h3>
            <UserLink 
              userId={data.business_term_owner_user_id}
              userName={data.business_term_owner_user_fullname}
              userPhoto={data.user_photo}
              userTitle={data.user_title}
              userDepartment={data.user_department}
              loading={loading}
            />

          </ObjectDetails>

          { !loading && editable &&
          <div className="main-toolbar">
            <button type="button" className="main-toolbar-item button main-button" onClick={ () => showSideDialog('editBusinessTerm', [data], editBusinessTerm) }><span>EDIT</span></button>
            <button type="button" className="main-toolbar-item button" onClick={ () => showModalDialog('deleteBusinessTerm', [data], deleteBusinessTerm) }><span>DELETE</span></button>
          </div>
          }

      </MainColumn>

      <div className="column">
        <Tabs 
          className="slim left"
          onTabChange={ onTabChange }
          defaultSelectedTab={defaultSelectedTab}
          disableTabsWithoutResults={ true }
        >

          <div label="References" tabId='references' resultCount={toReferencesCount && toReferencesCount.total}>
            <References 
              setMessage={setMessage}
              toObjectName={Asset.BusinessTerm}
              toObjectId={business_term_id}
              toReferencesCount={toReferencesCount}
              />
          </div>

        </Tabs>
      </div>

      <SideDialog 
        mode={action.mode}
        title={action.title}
        closeDialog={ action.cancelForm }
        > 
          { SideDialogForm &&
          <SideDialogForm {...action} />
          }
      </SideDialog>

    </div>
  ) 
}
